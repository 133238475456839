import { CdkTrapFocus } from '@angular/cdk/a11y';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { ContentElementItem } from '@domain/app/content-element.domain';
import { SubtopicOverviewItem, SubtopicSelectionResponse } from '@domain/app/topic.domain';
import { PanelStateEnum, RecommendationTypeEnum, RoutingPathMain, RoutingPathOverlay } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContactData, ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { TopicService } from '@services/topic-service/topic-service';
import { lastValueFrom } from 'rxjs';
import { SideContentElementAssignComponent } from './side-content-element-assign/side-content-element-assign.component';
import { SideContentElementConsultantComponent } from './side-content-element-consultant/side-content-element-consultant.component';
import { SideContentElementDatepickComponent } from './side-content-element-datepick/side-content-element-datepick.component';
import { SideContentElementOverviewComponent } from './side-content-element-overview/side-content-element-overview.component';

export interface InputValues {
  contentElementId: string;
  selected: boolean;
  sideOpen?: boolean;
  openPanelState?: PanelStateEnum;
  hasError?: boolean;
}

@Component({
  selector: 'side-content-element',
  standalone: true,
  imports: [
    CdkTrapFocus,
    KfThemeModule,
    MaterialModule,
    SideContentElementOverviewComponent,
    SideContentElementAssignComponent,
    SideContentElementConsultantComponent,
    SideContentElementDatepickComponent,
  ],
  templateUrl: './side-content-element.component.html',
  styleUrl: './side-content-element.component.scss',
})
export class SideContentElementComponent implements OnInit {
  @Input() inputValues: InputValues;

  public title: string;
  public currentPanelState: PanelStateEnum;
  public panelState = PanelStateEnum;
  public currentSubtopic: string = null;
  public contentElementData: ContentElementItem;
  public subtopicData: SubtopicOverviewItem;

  //formGroup handled here, so it doesn't get destroyed on panel change
  public assignmentFormGroup = new FormGroup({
    contact: new FormControl<ContactData>(null, Validators.required),
    note: new FormControl<string>(''),
    mail: new FormControl<boolean>(false),
  });

  constructor(
    private queryService: QueryService,
    private clientService: ClientService,
    private topicService: TopicService,
    private actionService: ActionService,
    private rightSidenavService: RightSidenavService,
    private contextService: ContextService,
    private contentElementService: ContentElementService
  ) {}

  ngOnInit(): void {
    this.getContentElementData().then(() => {
      if (this.contentElementData.type === RecommendationTypeEnum.subtopic) {
        for (const topic of this.allSubtopics.available) {
          this.subtopicData = topic.subtopics?.find(x => x.id === this.contentElementData.referenceId);
          if (this.subtopicData) {
            break;
          }
        }
      }

      this.setFormGroupData();
    });

    if (this.inputValues?.openPanelState) {
      this.currentPanelState = this.inputValues.openPanelState;
    } else {
      this.currentPanelState = PanelStateEnum.overview;
    }

    // activate validator for customer contact when called from checkout with error
    if (this.inputValues.hasError) {
      this.assignmentFormGroup.get('contact').markAsTouched();
    }
  }

  public onSelectionChanged(deselected?: boolean): void {
    this.getContentElementData();
    deselected ? this.handleClose() : this.updateComponents();
  }

  public onChangePanelState(target: PanelStateEnum): void {
    this.currentPanelState = target;
  }

  public onContactCreated(contact: ContactData): void {
    this.assignmentFormGroup.get('contact').setValue(contact);
  }

  public showBackButton(): boolean {
    if (this.currentPanelState !== PanelStateEnum.overview || this.inputValues.sideOpen) {
      if (this.currentPanelState === PanelStateEnum.assign && this.inputValues.openPanelState) {
        return false;
      }
      return true;
    } else return false;
  }

  public handleBack(): void {
    if (this.currentPanelState === PanelStateEnum.overview && this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else if (this.currentPanelState === PanelStateEnum.assign) {
      this.currentPanelState = PanelStateEnum.overview;
    } else if (
      this.currentPanelState === PanelStateEnum.consultant ||
      this.currentPanelState === PanelStateEnum.datepick
    ) {
      this.currentPanelState = PanelStateEnum.assign;
    }
  }

  public handleClose(contentElementAdded?: boolean): void {
    this.updateComponents();

    if (this.inputValues.sideOpen) {
      if (contentElementAdded) {
        this.doAction('recommendation-extra', 'content-element-added-to-agenda', {
          selectedSubtopicIds: this.selectedSubtopicIds,
        });
      } else {
        this.doAction('recommendation-extra', 'details-closed');
      }
    } else {
      this.rightSidenavService.close();
    }
  }

  private async getContentElementData(): Promise<void> {
    this.contentElementData = await lastValueFrom(
      this.queryService.getThemaById(this.clientService.consultationId, this.inputValues.contentElementId)
    );
  }

  private setFormGroupData(): void {
    this.contentElementService.fetchContactData().then(() => {
      const customerContact = this.contentElementData?.customerContact;
      this.assignmentFormGroup
        .get('contact')
        .setValue(this.contentElementService.contactList.find(x => x.id === customerContact?.id));
    });
    this.assignmentFormGroup.get('note').setValue(this.contentElementData?.individualNote || '');
    this.assignmentFormGroup.get('mail').setValue(this.contentElementData?.notifyViaEmail || false);
    this.contentElementService.appointmentDate.next(new Date(this.contentElementData?.appointmentDate));
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private updateComponents(): void {
    this.clientService.setSolutionBasketCount();

    if (this.contextService.recPanelState === 'open') {
      this.doAction('side-recommendation', 'get-recommendation', this.selectedSubtopicIds);
    }

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Cart) {
      this.doAction('cart-item', 'reload-cart');
    }

    if (this.contextService.currentOverlayContext === RoutingPathOverlay.Search) {
      this.doAction('search-world-column', 'refresh');
    }
  }

  get panelTitle(): string {
    switch (this.currentPanelState) {
      case PanelStateEnum.consultant:
        return 'Neuer Ansprechpartner';
      case PanelStateEnum.datepick:
        return 'Fälligkeit wählen';
      default:
        return null;
    }
  }

  get selectedSubtopicIds(): string[] {
    return this.topicService.consultationSelectedSubtopicIds(this.allSubtopics);
  }

  get allSubtopics(): SubtopicSelectionResponse {
    return this.topicService.subtopicData;
  }
}
