import { Injectable } from '@angular/core';
import { SearchOverlay } from '@domain/app/search-overlay';
import { QuestionGroupOverviewItem, SubtopicOverviewItem, TopicOverviewItem } from '@domain/app/topic.domain';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { BehaviorSubject, Observable, combineLatest, forkJoin } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import CompositionResponseItem = SearchOverlay.CompositionResponseItem;

export interface QuestionWorldData {
  topics: TopicOverviewItem[];
  isQuestionGroupInSearchResult: (questionGroup: QuestionGroupOverviewItem) => boolean;
  topicHasSearchResults: (topic: TopicOverviewItem) => boolean;
  subtopicHasSearchResults: (subtopic: SubtopicOverviewItem) => boolean;
}

export type ProductWorldItem = {
  name: string;
  type: 'task' | 'transition' | 'product';
  compositionId: number;
  id: number;
  compositionName: string;
};

@Injectable()
export class MainMenuService {
  private readonly _searchQuery = new BehaviorSubject<string | undefined>(undefined);
  private readonly _getTopicsResponse = new BehaviorSubject<TopicOverviewItem[] | undefined>(undefined);
  private readonly _getCompositionResponse = new BehaviorSubject<CompositionResponseItem[] | undefined>(undefined);

  public searchQueryValue = '';

  constructor(
    private readonly queryService: QueryService,
    private readonly clientService: ClientService
  ) {}

  fetchData(): Observable<void> {
    return forkJoin([
      this.queryService.getSearchOverlayTopics(this.clientService.consultationId),
      this.queryService.getSearchOverlayCompositions(this.clientService.consultationId),
    ]).pipe(
      tap(([topics, compositions]) => {
        this._getTopicsResponse.next(topics);
        this._getCompositionResponse.next(compositions);
      }),
      map(() => {})
    );
  }

  get questionWorldData(): Observable<QuestionWorldData> {
    return combineLatest([this._getTopicsResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => this.getTopicsResponseToQuestionWorldData(response, query))
    );
  }

  get productWorldData(): Observable<ProductWorldItem[]> {
    return combineLatest([this._getCompositionResponse, this._searchQuery]).pipe(
      filter(([response]) => response !== undefined),
      map(([response, query]) => {
        return this.getCompositionResponseToProductWorldData(response, query || 'favorite');
      })
    );
  }

  set searchQuery(value: string | undefined) {
    this._searchQuery.next(value.trim());
    this.searchQueryValue = value.trim();
  }

  private getTopicsResponseToQuestionWorldData(
    response: TopicOverviewItem[],
    query: string | undefined
  ): QuestionWorldData {
    if (!query) {
      return {
        topics: response.filter(topic => topic.selected),
        isQuestionGroupInSearchResult: () => true,
        topicHasSearchResults: () => true,
        subtopicHasSearchResults: () => true,
      };
    }

    const isQuestionGroupInSearchResult = (questionGroup: QuestionGroupOverviewItem) =>
      this.questionGroupAppliesToSearchQuery(questionGroup, query);

    const subtopicHasSearchResults = (subtopic: SubtopicOverviewItem) =>
      subtopic.questionGroups.some(isQuestionGroupInSearchResult);

    const topicHasSearchResults = (topic: TopicOverviewItem) => topic.subtopics.some(subtopicHasSearchResults);

    return {
      topics: response,
      isQuestionGroupInSearchResult,
      topicHasSearchResults,
      subtopicHasSearchResults,
    };
  }

  private questionGroupAppliesToSearchQuery(questionGroup: QuestionGroupOverviewItem, query: string): boolean {
    return (
      questionGroup.tags?.some(tag => this.tagAppliesToSearchQuery(tag, query)) ||
      this.nameAppliesToSearchQuery(questionGroup.title, query)
    );
  }

  private getCompositionResponseToProductWorldData(
    response: CompositionResponseItem[],
    query: string | undefined
  ): ProductWorldItem[] {
    const toCompositionElements = (composition: CompositionResponseItem) =>
      [
        composition.mainProduct && composition.mainProduct.variants.length === 0
          ? {
              ...composition.mainProduct,
              type: 'product',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              id: composition.mainProduct.id,
            }
          : undefined,
        ...(composition.mainProduct && composition.mainProduct.variants.length > 0
          ? composition.mainProduct.variants.map(variant => ({
              ...variant,
              type: 'product',
              tags: composition.tags,
              compositionId: composition.id,
              compositionName: composition.name,
              id: variant.id,
            }))
          : []),
      ].filter(element => !!element);

    const appliesToSearchQuery = (compositionElement): boolean => {
      if (!query) {
        return compositionElement.tags ? compositionElement.tags.indexOf('favourite') !== -1 : false;
      }

      return (
        (compositionElement.tags && compositionElement.tags.some(tag => this.tagAppliesToSearchQuery(tag, query))) ||
        this.nameAppliesToSearchQuery(compositionElement.name, query) ||
        this.nameAppliesToSearchQuery(compositionElement.compositionName, query)
      );
    };

    return response
      .map(composition => toCompositionElements(composition))
      .flatMap(compositionElements => compositionElements)
      .filter(compositionElement => appliesToSearchQuery(compositionElement))
      .map(compositionElement => ({
        name: compositionElement.name,
        type: compositionElement.type as ProductWorldItem['type'],
        id: compositionElement.id,
        compositionId: compositionElement.compositionId,
        compositionName: compositionElement.compositionName,
      }));
  }

  private nameAppliesToSearchQuery(name: string, query: string) {
    return name.toLowerCase().includes(query.toLowerCase());
  }

  private tagAppliesToSearchQuery(tag: string, query: string) {
    return tag.toLowerCase().includes(query.toLowerCase());
  }
}
