<aside
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  class="d-flex flex-column h-100"
  [class.content-element-overview-width]="currentPanelState === panelState.overview"
  [class.content-element-assign-width]="currentPanelState === panelState.assign"
>
  <header class="d-flex align-items-center p-4">
    @if (showBackButton()) {
      <button
        mat-icon-button
        class="mr-2"
        [attr.data-testcafe]="'sideContentElement-button-back'"
        aria-label="Ansicht schließen"
        kfTooltip="Ansicht schließen"
        kfTooltipPosition="before"
        kfTooltipShow="hover"
        (click)="handleBack()"
      >
        <kf-icon name="ic_chevron_links_24"></kf-icon>
      </button>
    }
    <div class="d-flex flex-column flex-grow-1">
      <h2
        class="mb-0 text-truncate"
        [kfTooltip]="subtopicData?.title.length > 50 ? subtopicData.title : null"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
      >
        {{ subtopicData?.title }}
      </h2>
      @if (panelTitle) {
        <p class="mb-0 mt-1">{{ panelTitle }}</p>
      }
    </div>

    @if (currentPanelState === panelState.overview && !this.inputValues?.sideOpen) {
      <button
        mat-icon-button
        class="kf-popup-close"
        kfTooltip="Klicken oder ESC drücken, um zu schließen"
        kfTooltipPosition="before"
        kfTooltipShow="hover"
        [attr.data-testcafe]="'sideContentElement-button-close'"
        aria-label="Panel schließen"
        (click)="handleClose()"
      >
        <kf-icon name="ic_schliessen_24"></kf-icon>
      </button>
    }
  </header>

  @switch (currentPanelState) {
    @case (panelState.overview) {
      <side-content-element-overview
        class="d-flex flex-column flex-grow-1 mt-4"
        [contentElementData]="contentElementData"
        [subtopicData]="subtopicData"
        (selectionChanged)="onSelectionChanged($event)"
        (changePanelState)="onChangePanelState($event)"
        (subtopicAddedToAgenda)="handleClose(true)"
      ></side-content-element-overview>
    }
    @case (panelState.assign) {
      <side-content-element-assign
        class="d-flex flex-column flex-grow-1 mt-4"
        [contentElementData]="contentElementData"
        [assignmentFormGroup]="assignmentFormGroup"
        (changePanelState)="onChangePanelState($event)"
        (closePanel)="handleClose()"
      ></side-content-element-assign>
    }
    @case (panelState.consultant) {
      <side-content-element-consultant
        class="d-flex flex-column flex-grow-1 mt-4"
        (changePanelState)="onChangePanelState($event)"
      ></side-content-element-consultant>
    }
    @case (panelState.datepick) {
      <side-content-element-datepick
        class="d-flex flex-column flex-grow-1 flex-wrap"
        (changePanelState)="onChangePanelState($event)"
      ></side-content-element-datepick>
    }
  }
</aside>
