import { Component, Input, OnInit } from '@angular/core';
import { DeselectContentElementItem, SelectContentElementItem } from '@domain/app/content-element.domain';
import { DeselectProductItem, SelectProductItem } from '@domain/app/product.domain';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse, RecommendationItem } from '@domain/app/recommendation.domain';
import { DeselectTaskItem, SelectTaskItem } from '@domain/app/task.domain';
import { DeselectTransitionItem, SelectTransitionItem } from '@domain/app/transition.domain';
import { RecommendationShowEnum, RecommendationTypeEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { TopicService } from '@services/topic-service/topic-service';
import { union } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'side-recommendations-details',
  templateUrl: './side-recommendations-details.component.html',
  styleUrls: ['./side-recommendations-details.component.scss'],
})
export class SideRecommendationsDetailsComponent implements OnInit {
  @Input() inputValues: {
    recommendationGroupData: QuestionGroupRecommendationResponse;
    questionGroupData: QuestionGroupResponse;
  };

  private destroySubs = new Subject<void>();

  readonly recommendationTypeEnum = RecommendationTypeEnum;
  readonly recommendationShowEnum = RecommendationShowEnum;
  readonly sortOrder = [
    this.recommendationTypeEnum.main,
    this.recommendationTypeEnum.variant,
    this.recommendationTypeEnum.task,
    this.recommendationTypeEnum.transition,
    this.recommendationTypeEnum.additional,
  ];

  public show = this.recommendationShowEnum.side;
  public details;

  constructor(
    private actionService: ActionService,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private contentElementService: ContentElementService,
    private topicService: TopicService
  ) {}

  ngOnInit(): void {
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'recommendation-extra') {
        if (action.action === 'transition-indiv') {
          this.show = this.recommendationShowEnum.transition;
        } else if (action.action === 'task-indiv') {
          this.show = this.recommendationShowEnum.task;
        } else if (action.action === 'product-details') {
          this.show = this.recommendationShowEnum.infoProduct;
          this.details = action.options;
        } else if (action.action === 'task-details') {
          this.show = this.recommendationShowEnum.infoTask;
          this.details = action.options;
        } else if (action.action === 'transition-details') {
          this.show = this.recommendationShowEnum.infoTransition;
          this.details = action.options;
        } else if (action.action === 'content-element-details') {
          this.show = this.recommendationShowEnum.infoContentElement;
          this.details = action.options;
        } else if (action.action === 'details-closed') {
          this.show = this.recommendationShowEnum.side;
          this.requestRecommendedItems(this.inputValues.questionGroupData.collectionId);
        } else if (action.action === 'content-element-added-to-agenda') {
          this.show = this.recommendationShowEnum.side;
          this.refreshRecommendations(action.options);
          this.requestRecommendedItems(this.inputValues.questionGroupData.collectionId);
        }
      }
    });

    this.recommendationGroupData.other = union(
      this.recommendationGroupData?.selected,
      this.recommendationGroupData?.recommended,
      this.recommendationGroupData?.other
    );
  }

  ngOnDestroy(): void {
    this.doAction('side-recommendation', 'close', this.selectedSubtopicIds);
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public async addSubtopicToAgenda(item: RecommendationItem) {
    await this.contentElementService.addSubtopicToAgenda(item.referenceId);
    this.doAction('side-recommendation', 'get-recommendation', this.selectedSubtopicIds);
    this.clientService.setSolutionBasketCount();
  }

  public refreshRecommendations(event: any) {
    if (event?.recommendationGroupData) {
      this.recommendationGroupData.selected = event.recommendationGroupData.selected;
      this.recommendationGroupData.recommended = event.recommendationGroupData.recommended;
      this.recommendationGroupData.other = event.recommendationGroupData.other;
    }
  }

  public onRecToggle(item: RecommendationItem) {
    if (this.recommendationGroupData.selected.includes(item)) {
      switch (item.type) {
        case RecommendationTypeEnum.task: {
          this.deselectTask(item);
          break;
        }
        case RecommendationTypeEnum.transition: {
          this.deselectTransition(item);
          break;
        }
        case RecommendationTypeEnum.subtopic: {
          this.deselectContentElement(item);
          break;
        }
        default: {
          this.deselectProduct(item);
        }
      }
      this.clientService.numSolutions -= 1;
      this.recommendationGroupData.selected = this.recommendationGroupData.selected.filter(x => x !== item);
      if (item.recommended) {
        this.recommendationGroupData.recommended.push(item);
      }
    } else {
      switch (item.type) {
        case RecommendationTypeEnum.task: {
          this.selectTask(item);
          break;
        }
        case RecommendationTypeEnum.transition: {
          this.selectTransition(item);
          break;
        }
        case RecommendationTypeEnum.subtopic: {
          this.selectContentElement(item);
          break;
        }
        default: {
          this.selectProduct(item);
        }
      }

      this.clientService.numSolutions += 1;
      this.recommendationGroupData.selected.push(item);
      this.recommendationGroupData.recommended = this.recommendationGroupData.recommended.filter(x => x !== item);
    }
    this.doAction('side-recommendation', 'get-recommendation');
    //this action leads to doubled get-request, because side-recommendation exists twice here
  }

  public recContains(item: RecommendationItem) {
    return this.recommendationGroupData.selected.includes(item);
  }

  public showRecommendationDetails(item: RecommendationItem, selected: boolean, target?: string) {
    if (item.type.includes(this.recommendationTypeEnum.product)) {
      this.details = { compositionId: item.compositionId, productId: item.id, sideOpen: true };
      this.show = this.recommendationShowEnum.infoProduct;
    } else if (item.type === this.recommendationTypeEnum.task) {
      this.details = {
        compositionId: item.compositionId,
        compositionTitle: item.name,
        taskId: item.id,
        target: target,
        selected: selected,
        subtopicId: this.subtopicId,
        sideOpen: true,
      };
      this.show = this.recommendationShowEnum.infoTask;
    } else if (item.type === this.recommendationTypeEnum.transition) {
      this.details = {
        compositionId: item.compositionId,
        compositionTitle: item.name,
        transitionId: item.id,
        target: target,
        selected: selected,
        subtopicId: this.subtopicId,
        sideOpen: true,
      };
      this.show = this.recommendationShowEnum.infoTransition;
    } else if (item.type === this.recommendationTypeEnum.subtopic) {
      this.details = {
        contentElementId: item.id,
        selected: selected,
        sideOpen: true,
      };
      this.show = this.recommendationShowEnum.infoContentElement;
    }
  }

  public recByComposition(composition: string) {
    return union(
      this.recommendationGroupData.selected,
      this.recommendationGroupData.recommended,
      this.recommendationGroupData.other
    )
      .filter(x => x.compositionName === composition && !this.selectedSubtopicIds.includes(x?.referenceId))
      .sort((a, b) => {
        return this.sortOrder.indexOf(a.type) - this.sortOrder.indexOf(b.type) || a.name.localeCompare(b.name);
      });
  }

  public recDataSelectedLength(composition: string) {
    return this.recommendationGroupData.selected.filter(x => x.compositionName === composition).length;
  }

  private selectProduct(item: RecommendationItem): void {
    const sendObj = {} as SelectProductItem;
    sendObj.compositionId = item.compositionId;
    sendObj.productId = item.id;
    sendObj.subtopicId = this.subtopicId;
    sendObj.quantity = 1;

    this.queryService.putSelectProduct(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectProduct(item: RecommendationItem): void {
    const sendObj = {} as DeselectProductItem;
    sendObj.compositionId = item.compositionId;
    sendObj.productId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectProduct(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private selectTask(item: RecommendationItem): void {
    const sendObj = {} as SelectTaskItem;
    sendObj.compositionId = item.compositionId;
    sendObj.taskId = item.id;
    sendObj.subtopicId = this.subtopicId;

    this.queryService.putSelectTask(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectTask(item: RecommendationItem): void {
    const sendObj = {} as DeselectTaskItem;
    sendObj.compositionId = item.compositionId;
    sendObj.taskId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectTask(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private selectTransition(item: RecommendationItem): void {
    const sendObj = {} as SelectTransitionItem;
    sendObj.compositionId = item.compositionId;
    sendObj.transitionId = item.id;
    sendObj.subtopicId = this.subtopicId;

    this.queryService.putSelectTransition(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectTransition(item: RecommendationItem): void {
    const sendObj = {} as DeselectTransitionItem;
    sendObj.compositionId = item.compositionId;
    sendObj.transitionId = item.id;
    sendObj.target = item.recommended ? 'RECOMMENDED' : 'OTHER';

    this.queryService.putDeselectTransition(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private selectContentElement(item: RecommendationItem): void {
    const sendObj: SelectContentElementItem = { themaId: item.id };

    this.queryService.putSelectThema(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private deselectContentElement(item: RecommendationItem): void {
    const sendObj: DeselectContentElementItem = {
      themaId: item.id,
      target: item.recommended ? 'RECOMMENDED' : 'OTHER',
    };

    this.queryService.putDeselectThema(this.clientService.consultationId, sendObj).subscribe(x => {});
  }

  private requestRecommendedItems(questionGroupId): void {
    this.queryService.getRecommendedProducts(this.clientService.consultationId, questionGroupId).subscribe(recData => {
      this.inputValues.recommendationGroupData = recData;

      // eslint-disable-next-line guard-for-in
      for (const key in this.recommendationGroupData) {
        for (const recItem of this.recommendationGroupData[key]) {
          recItem.origin = key;
        }
      }
    });
  }

  get recDataOther() {
    return this.recommendationGroupData.other || [];
  }

  get recDataProducts() {
    return union(
      this.recommendationGroupData.selected,
      this.recommendationGroupData.recommended,
      this.recommendationGroupData.other
    )
      .filter(x => x.type.includes('PRODUCT'))
      .sort((a, b) => a.parentElementName.localeCompare(b.parentElementName));
  }

  get recDataTasks() {
    return union(
      this.recommendationGroupData.selected,
      this.recommendationGroupData.recommended,
      this.recommendationGroupData.other
    )
      .filter(x => x.type === 'TASK')
      .sort((a, b) => a.parentElementName.localeCompare(b.parentElementName));
  }

  get recDataTransitions() {
    return union(
      this.recommendationGroupData.selected,
      this.recommendationGroupData.recommended,
      this.recommendationGroupData.other
    )
      .filter(x => x.type === 'TRANSITION')
      .sort((a, b) => a.parentElementName.localeCompare(b.parentElementName));
  }
  get recCompositions(): any[] {
    return [
      ...new Set(
        union(
          this.recommendationGroupData?.selected,
          this.recommendationGroupData?.recommended,
          this.recommendationGroupData?.other
        )
          .filter(x => !this.selectedSubtopicIds.includes(x?.referenceId))
          .map(x => x.compositionName)
          .sort((a, b) => {
            return a.localeCompare(b);
          })
      ),
    ];
  }

  get subtopicId() {
    return this.questionGroupData?.subtopicId;
  }

  get collectionId() {
    return this.questionGroupData?.collectionId;
  }

  get recommendationGroupData() {
    return this.inputValues?.recommendationGroupData;
  }

  get questionGroupData() {
    return this.inputValues?.questionGroupData;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get allSubtopics() {
    return this.topicService.subtopicData;
  }

  get selectedSubtopicIds() {
    return this.topicService.consultationSelectedSubtopicIds(this.allSubtopics);
  }
}
