<div class="container">
  <div class="mx-auto my-5 col-6">
    <kf-tile [interactive]="false">
      @if (error503Page) {
        <!-- 503 -->
        <kf-message-box
          type="page"
          state="error"
          title="Es ist ein Fehler aufgetreten."
          description="Es ist ein Netzwerkfehler aufgetreten. Der BankingGuide wird aktuell gewartet."
          iconName="ic_warnschild_96"
        >
        </kf-message-box>
        <div class="p-3 d-flex flex-column align-items-center justify-content-center">
          @if (isVp) {
            <button mat-stroked-button color="primary" (click)="onBackToAppointment()">
              <span>{{ appointmentLabel }}</span>
              <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
            </button>
          } @else {
            <button mat-stroked-button color="primary" (click)="onSelect()">
              <span>Zur Kundenliste</span>
              <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
            </button>
          }
        </div>
      } @else {
        <!-- !503 -->
        <kf-message-box
          type="page"
          state="error"
          title="Die aufgerufene Seite konnte nicht geladen werden."
          [description]="errorReason"
          iconName="ic_warnschild_96"
        >
        </kf-message-box>
        <div class="p-3 d-flex flex-column align-items-center justify-content-center">
          @if (isVp) {
            @if (hasAppointmentButton) {
              <button class="kf-btn-flexible" mat-flat-button color="primary" (click)="onBackToAppointment()">
                <span>{{ appointmentLabel }}</span>
                <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
              </button>
            }
          } @else {
            <button
              class="kf-btn-flexible"
              [attr.data-testcafe]="'footer-button-next'"
              mat-stroked-button
              color="primary"
              (click)="onSelect()"
            >
              <span>Zur Beratungsübersicht</span>
              <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
            </button>
          }
        </div>
      }
    </kf-tile>
  </div>
</div>
