import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { ConfigSettingsItem } from '@domain/app/configuration.domain';
import { GetCustomerImportDataRequest } from '@domain/hub/consultation.domain';
import { CustomerRequestItem } from '@domain/hub/customer.domain';
import { CustomerImportRequestTypeEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { ImportService } from '@services/import-service/import.service';
import { QueryService } from '@services/query-service/query.service';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-overlay-customer-import',
  templateUrl: './overlay-customer-import.component.html',
  styleUrls: ['./overlay-customer-import.component.scss'],
})
export class OverlayCustomerImportComponent implements OnInit, OnDestroy {
  private _actionSub: Subscription;
  private _settingsSub: Subscription;
  private _importSubscription: Subscription;

  // Does it need to be unsubscribed?
  private _debounceSubTextNumber: Subscription;
  private newInputNumber = new EventEmitter<boolean>();

  public isNewUser = false;
  public isFromClientList = false;
  public settings: ConfigSettingsItem[] = [];
  public existingClientId: number;
  public importIsRunning: boolean;
  public triggerCheck;
  public form;
  duplicateNumber = false;

  private customerNumberList;

  constructor(
    private actionService: ActionService,
    public clientService: ClientService,
    public queryService: QueryService,
    private snackBarService: SnackBarTemplatesService,
    private router: Router,
    private importService: ImportService,
    private readonly formValidationService: FormValidationService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;
      if (stateObj?.customerNumber) {
        this.existingClientId = stateObj.customerNumber;
      }
      if (stateObj?.isNewUser) {
        this.isNewUser = stateObj.isNewUser;
      }
      if (stateObj?.isFromClientList) {
        this.isFromClientList = stateObj.isFromClientList;
      }
    }
  }

  ngOnInit(): void {
    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'customer-import') {
      }
    });

    this.form = new FormGroup({
      clientId: new FormControl<number | string | null>(this.existingClientId ?? '', Validators.required),
      bankCode: new FormControl<string | null>('', Validators.required),
    });

    this._importSubscription = this.importService.importIsOngoing.subscribe(state => {
      this.importIsRunning = state;
    });

    this.queryService.getAllCustomers().subscribe(data => {
      this.customerNumberList = data.map(number => number.customerNumber);
    });

    this._debounceSubTextNumber = this.newInputNumber
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe(number => {
        this.duplicateNumber = number;
      });
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  ngOnDestroy(): void {
    this._actionSub?.unsubscribe();
    this._settingsSub?.unsubscribe();
    this._importSubscription?.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  onCancel() {
    if (this.isFromClientList === true) {
      this.doAction('overlay-main', 'close');
    } else {
      this.doAction('overlay-main', 'customer-new');
    }
    this.clientService.customerId = 0;
  }

  onStartImport() {
    this.triggerCheck = true;
    if (!this.form.valid) {
      return;
    }

    const customerData = this.getNewCustomerObj();

    if (!this.isNewUser) {
      console.log(`%c [bgzv-frontend-main] - user exists`, 'color: #0066cc');
      this.sendImportRequest(this.clientService.customerId, this.clientService.customerNumber);
    } else {
      this.queryService.postCustomer(customerData).subscribe(newCustomer => {
        console.log(`%c [bgzv-frontend-main] - new customer created`, 'color: #0066cc');
        this.sendImportRequest(newCustomer.id, newCustomer.customerNumber);
      });
    }
  }

  sendImportRequest(customerId, customerNumber) {
    const clientId = this.form.get('clientId').value;
    const sendObj = this.getCustomerImportObj();

    this.snackBarService.openSnackBar({
      type: SnackbarType.NEUTRAL,
      message: 'Import gestartet. Dies kann einige Minuten andauern.',
    });

    this.clientService.customerId = customerId;
    this.clientService.customerNumber = customerNumber;

    this.importService.resetImportError();
    this.importService.startImport();

    if (customerId) {
      this.doAction('overlay-main', 'customer-new', { edit: true, import: true });

      this.queryService.postCustomerImportData(clientId, sendObj).subscribe(
        response => {
          this.importService.finishImport();

          this.snackBarService.openSnackBar({ type: SnackbarType.SUCCESS, message: 'Import erfolgreich.' });

          this.importService.requestRefresh();
        },
        error => {
          console.log(`%c [bgzv-frontend-main] - ${error}`, 'color: #ff3300');

          this.importService.finishImport();
          this.importService.setImportError();

          this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: 'Import fehlgeschlagen.' });

          if (this.isNewUser || !this.existingClientId) {
            this.queryService.deleteCustomerById(customerId).subscribe(deleted => {
              this.clientService.customerId = 0;
              this.doAction('overlay-main', 'close');
            });
          } else {
            this.clientService.customerId = 0;
            this.doAction('overlay-main', 'close');
          }
        }
      );
    } else {
      this.snackBarService.openSnackBar({ type: SnackbarType.ERROR, message: 'Nutzer konnte nicht erstellt werden.' });

      this.importService.finishImport();
    }
  }

  getCustomerImportObj(): GetCustomerImportDataRequest {
    return {
      importRequestType: CustomerImportRequestTypeEnum.default,
      zvrUpdateDataset: true,
      bankCode: this.form.get('bankCode').value,
    };
  }

  getNewCustomerObj(): CustomerRequestItem {
    return {
      corporationName: this.form.get('clientId').value,
      customerNumber: this.form.get('clientId').value,
    };
  }

  numberIsFound(event) {
    if (this.customerNumberList.find(customerNumber => customerNumber === event)) {
      this.newInputNumber.emit(true);
    } else {
      this.newInputNumber.emit(false);
    }
  }
}
