<marginals-header></marginals-header>

<div class="boxed-content w-header d-flex flex-column flex-scroll flex-grow-1">
  <div class="container">
    <div class="row mb-4 mt-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <ng-template *ngTemplateOutlet="header"></ng-template>
        </kf-tile>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <!-- ------ CLIENT CONSULTATION LIST ------ -->
          <div class="d-flex align-items-center flex-column flex-grow-1 flex-scroll w-100 mb-3">
            <ng-container *ngIf="consultationDates; else loading">
              <article class="d-flex w-100 justify-content-start align-items-center mb-4">
                <h2 class="mb-0 mr-3">Filter:</h2>
                <mat-chip-listbox kfChipsSet multiple="false" aria-label="Filter">
                  <mat-chip-option
                    kfChips
                    (click)="filterActive()"
                    [attr.data-testcafe]="'screenClientConsultations-button-onlyActive'"
                  >
                    Nur Aktive
                  </mat-chip-option>
                </mat-chip-listbox>
              </article>

              <ul class="w-100 list list-unstyled" *ngIf="displayedConsultationDates">
                <ng-container *ngFor="let consultationDate of displayedConsultationDates; index as index">
                  <ng-template
                    *ngTemplateOutlet="consultationDateContent; context: { $implicit: consultationDate, index: index }"
                  ></ng-template>
                </ng-container>
              </ul>
            </ng-container>
          </div>
        </kf-tile>
        <div [class.side-footer-margin]="useMargin"></div>
      </div>
    </div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #header>
  <div *ngIf="customerData" class="w-100 d-flex flex-column header-container">
    <div class="w-100 d-flex align-items-center">
      <div class="flex-grow-1 d-flex align-items-center">
        <img
          *ngIf="customerData"
          class="customer-logo"
          [src]="customerData.logo"
          [alt]="'Logo von: ' + customerData.corporationName"
        />
        <h2 class="text-truncate ml-3">{{ customerData.corporationName }}</h2>
      </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-end mt-4">
      <div class="button-row">
        <div class="button-row-content">
          <button
            *ngIf="environment.platform === 'aws'"
            [attr.data-testcafe]="'screenClientConsultations-div-edit'"
            mat-stroked-button
            color="primary"
            (click)="onEditCustomer()"
          >
            <span>Bearbeiten</span>
          </button>

          <button
            [attr.data-testcafe]="'screenClientConsultation-button-newConsultation'"
            mat-flat-button
            color="primary"
            (click)="selectNewConsultation()"
          >
            <span>Neue Beratung</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- CONSULTATIONS CONTENT -->
<ng-template #consultationDateContent let-consultationData let-index="index">
  <li class="d-flex align-items-center w-100 list-item-container list-item-separator">
    <div
      class="d-flex flex-column justify-content-center flex-grow-1 mr-3 cursor-pointer"
      [attr.data-testcafe]="getTestcafeId(consultationData.id, consultationData.name)"
      (click)="
        !isConsultationCompleted(consultationData)
          ? selectConsultationOption(consultationData)
          : getConsultationSummary(consultationData)
      "
    >
      <strong class="d-block font-weight-bold">
        {{ consultationData.name }}
      </strong>
      <span
        [debugId]="consultationData.id"
        class="d-block text-truncate"
        [innerHTML]="'Beratung vom ' + handleDateFormat(consultationData) | safeHtml"
      ></span>
      <span class="d-block"> Instanz: {{ instances.get(consultationData.instanceId) }} </span>
    </div>
    <div class="button-row">
      <div class="button-row-content">
        <button
          *ngIf="consultationData.status === consultationStatus.mainConsultation"
          id="{{ index }}"
          mat-stroked-button
          color="primary"
          (click)="selectConsultationOption(consultationData)"
        >
          <span>Beratung</span>
        </button>

        <button
          *ngIf="consultationData.status !== consultationStatus.mainConsultation"
          id="{{ index }}"
          mat-stroked-button
          color="primary"
          (click)="
            !isConsultationCompleted(consultationData)
              ? selectConsultationOption(consultationData)
              : getConsultationSummary(consultationData)
          "
        >
          <span>{{ isConsultationCompleted(consultationData) ? 'Abgeschlossen' : 'Vorbefragung' }}</span>
        </button>

        <div #moreButton *ngIf="!isOnVp">
          <kf-compact-button
            id="more-{{ index }}"
            [attr.data-testcafe]="getTestcafeButton(consultationData.id, consultationData.name)"
            type="secondary"
            iconName="ic_menue_mehr_vertikal_24"
            label="Kontextmenü öffnen"
            [matMenuTriggerFor]="menu"
          ></kf-compact-button>

          <mat-menu #menu="matMenu">
            <!-- open prelim -->
            <button
              *ngIf="consultationData?.status === consultationStatus.preparationSent"
              [attr.data-testcafe]="'dialogContext-button-startPrelim'"
              (click)="startPrelim(consultationData)"
              mat-menu-item
            >
              Vorbefragung starten
            </button>

            <!-- edit -->
            <button
              [attr.data-testcafe]="'dialogContext-button-startPrelim'"
              *ngIf="consultationData?.status === consultationStatus.preparationSent"
              (click)="edit(consultationData)"
              mat-menu-item
            >
              Bearbeiten
            </button>

            <!-- result/ result summary -->
            <ng-container
              *ngIf="
                consultationData?.status === consultationStatus.archived ||
                consultationData?.status === consultationStatus.checkoutDone
              "
            >
              <button
                [attr.data-testcafe]="'dialogContext-button-consultationResult'"
                (click)="getConsultationSummary(consultationData)"
                mat-menu-item
              >
                Beratungsergebnis
              </button>
            </ng-container>

            <!-- archive -->
            <button
              *ngIf="
                consultationData?.status === consultationStatus.mainConsultation ||
                consultationData?.status === consultationStatus.preparationSent ||
                consultationData?.status === consultationStatus.preparationDone ||
                consultationData?.status === consultationStatus.checkoutDone
              "
              [attr.data-testcafe]="'dialogContext-button-archive'"
              mat-menu-item
              (click)="confirmArchiving(consultationData)"
            >
              Archivieren
            </button>

            <!-- delete -->
            <button
              *ngIf="
                consultationData?.status === consultationStatus.created ||
                consultationData?.status === consultationStatus.inPreparation
              "
              [attr.data-testcafe]="'dialogContext-button-delete'"
              mat-menu-item
              (click)="confirmDeletion(consultationData)"
            >
              Löschen
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </li>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
