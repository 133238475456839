<div class="px-4 d-flex flex-column flex-grow-1">
  <form [formGroup]="assignmentFormGroup" class="d-flex flex-column">
    @if (contactList.length === 0) {
      <button
        mat-stroked-button
        type="button"
        color="primary"
        class="mb-4 d-flex align-self-start"
        [attr.data-testcafe]="'sideContentElement-button-addConsultant'"
        (click)="changePanelState.emit(panelStateEnum.consultant)"
      >
        <kf-icon name="ic_pluszeichen_24"></kf-icon>
        <span>Ansprechpartner</span>
      </button>
    } @else {
      <mat-form-field>
        <mat-label>Ansprechpartner</mat-label>
        <mat-select #select kfSelectMdc formControlName="contact" placeholder="Ansprechpartner auswählen">
          @for (option of contactList; track option) {
            <mat-option [value]="option">{{ option.label }}</mat-option>
          }
        </mat-select>
        @if (assignmentFormGroup.get('contact').invalid) {
          <mat-error>
            <kf-input-error message="Name des Ansprechpartners wird benötigt."></kf-input-error>
          </mat-error>
        }
      </mat-form-field>
      <button
        mat-stroked-button
        type="button"
        color="primary"
        class="mb-4 d-flex align-self-end"
        [attr.data-testcafe]="'sideContentElement-button-addConsultant'"
        (click)="changePanelState.emit(panelStateEnum.consultant)"
      >
        <kf-icon name="ic_pluszeichen_24"></kf-icon>
        <span>Ansprechpartner</span>
      </button>
    }

    <mat-form-field class="mb-3">
      <mat-label>Notiz</mat-label>
      <div class="kf-field-char-counter" aria-hidden="true">{{ assignmentFormGroup.value.note?.length || 0 }}/4000</div>
      <textarea
        #textarea
        matInput
        formControlName="note"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"
      ></textarea>
    </mat-form-field>

    <div class="d-flex">
      <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
      <p class="mb-2 font-weight-bold">Fälligkeitsdatum</p>
    </div>
    <div class="mb-4 d-flex flex-column">
      @if (appointmentDate) {
        <div class="d-flex flex-row justify-content-between">
          <div class="tag-info tag-neutral my-auto">
            <div class="tag-info-text">
              {{ getDateString() }}
            </div>
          </div>
          <div>
            <button
              mat-icon-button
              [attr.data-testcafe]="'sideContentElement-button-close'"
              aria-label="Fälligkeitsdatum löschen"
              kfTooltip="Fälligkeitsdatum löschen"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              (click)="deleteAppointmentDate()"
            >
              <kf-icon name="ic_loeschen_24"></kf-icon>
            </button>
            <button
              mat-icon-button
              type="button"
              class="ml-3"
              [attr.data-testcafe]="'sideContentElement-button-dateUpdate'"
              aria-label="Fälligkeitsdatum ändern"
              kfTooltip="Fälligkeitsdatum ändern"
              kfTooltipPosition="after"
              kfTooltipShow="hover"
              (click)="changePanelState.emit(panelStateEnum.datepick)"
            >
              <kf-icon name="ic_edit_24"></kf-icon>
            </button>
          </div>
        </div>
        @if (invalidDate) {
          <mat-error class="mt-1">
            <kf-input-error message="Datum muss in der Zukunft liegen"></kf-input-error>
          </mat-error>
        }
      } @else {
        <button
          mat-stroked-button
          type="button"
          color="primary"
          class="d-flex align-self-end"
          [attr.data-testcafe]="'sideContentElement-button-appointment'"
          (click)="changePanelState.emit(panelStateEnum.datepick)"
        >
          <kf-icon name="ic_pluszeichen_24"></kf-icon>
          <span>Fälligkeit festlegen</span>
        </button>
      }
    </div>

    <mat-checkbox kfFocus formControlName="mail">
      <span>Hinweis per E-Mail versenden</span>
    </mat-checkbox>
  </form>
</div>

<mat-divider></mat-divider>

<footer class="px-4 py-3 d-flex justify-content-end" [class.side-footer-margin]="useMargin">
  <button
    mat-stroked-button
    color="primary"
    class="ml-3"
    [attr.data-testcafe]="'sideContentElement-button-abortAppointment'"
    (click)="closePanel.emit()"
  >
    Abbrechen
  </button>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    class="ml-3"
    [disabled]="!assignmentFormGroup.valid"
    (click)="assignContentElement()"
  >
    Speichern
  </button>
</footer>
