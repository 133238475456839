import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AnswerNumberInputComponent } from '@components/answers/answer-number-input/answer-number-input.component';
import { CompositionRecommendationItem } from '@domain/app/composition.domain';
import { DeselectProductItem, SelectProductItem } from '@domain/app/product.domain';
import { DeselectTaskItem, SelectTaskItem } from '@domain/app/task.domain';
import { DeselectTransitionItem, SelectTransitionItem } from '@domain/app/transition.domain';
import { PaymentInterval, RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface ProductExtraInput {
  compositionData: CompositionRecommendationItem;
}

@Component({
  selector: 'side-product-extras',
  templateUrl: './side-product-extras.component.html',
  styleUrls: ['./side-product-extras.component.scss'],
})
export class SideProductExtrasComponent implements OnInit, OnDestroy {
  @Input() inputValues: ProductExtraInput;

  private destroySubs = new Subject<void>();

  public compositionData: CompositionRecommendationItem;
  public productNum: AnswerNumberInputComponent;

  public viewMode: 'list' | 'details' = 'list';
  public detailsInputValues: any;
  public currentDetailType: 'product' | 'transition' | 'task';

  public loading = false;

  readonly costType = PaymentInterval;

  constructor(
    private clientService: ClientService,
    private contextService: ContextService,
    private actionService: ActionService,
    private queryService: QueryService,
    private rightSidenavService: RightSidenavService,
    private chg: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {}

  ngOnChanges(): void {
    this.compositionData = this.inputValues.compositionData;
  }

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
    this.viewMode = 'list';
  }

  ngAfterViewChecked(): void {
    // TODO: Check if this is correct? Seems like overkill
    this.chg.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public handleClose() {
    this.rightSidenavService.close();
    this.viewMode = 'list';
  }

  public showElementDetails(elementId, type, selected) {
    const mp = this.compositionData.mainProduct;
    const detailsInputValues = {
      compositionId: this.inputValues.compositionData.id,
      keepNavOpen: true,
      useBackToList: true,
      selected: selected,
      mainData: mp,
      compositionTitle: this.compositionData.name,
    } as any;

    if (type === 'product') {
      detailsInputValues.productId = elementId;
      detailsInputValues.hideButton = mp.id === elementId && mp.variants.length > 0;
    } else if (type === 'transition') {
      detailsInputValues.transitionId = elementId;
    } else if (type === 'task') {
      detailsInputValues.taskId = elementId;
    }

    this.detailsInputValues = { ...detailsInputValues };
    this.currentDetailType = type;
    this.viewMode = 'details';
  }

  public setProductQuantity(productId, quantity: number) {
    if (this.loading) return;
    if (quantity !== 0) {
      const sendObj = {} as SelectProductItem;
      sendObj.compositionId = this.inputValues.compositionData.id;
      sendObj.productId = productId;
      sendObj.quantity = quantity;
      this.queryService.putSelectProduct(this.clientService.consultationId, sendObj).subscribe(data => {
        this.doAction('cart-item', 'reload-cart', { productExtras: true });
      });
    } else {
      const deselectObj = {} as DeselectProductItem;
      deselectObj.compositionId = this.inputValues.compositionData.id;
      deselectObj.productId = productId;
      deselectObj.target = 'RECOMMENDED';
      this.queryService.putDeselectProduct(this.clientService.consultationId, deselectObj).subscribe(data => {
        this.doAction('cart-item', 'reload-cart', { productExtras: true });
      });
    }
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public setProductSelectedState(
    productId,
    checkbox: MatCheckboxChange,
    type: 'product' | 'task' | 'transition' = 'product'
  ) {
    const compId = this.inputValues.compositionData.id;
    const consId = this.clientService.consultationId;
    if (type === 'product') {
      if (checkbox.checked) {
        const sendObj = {
          compositionId: compId,
          productId: productId,
          quantity: 1,
        } as SelectProductItem;

        this.queryService.putSelectProduct(consId, sendObj).subscribe(data => {
          reload();
        });
      } else {
        const sendObj = {
          compositionId: compId,
          productId: productId,
          target: 'RECOMMENDED',
        } as DeselectProductItem;
        this.queryService.putDeselectProduct(consId, sendObj).subscribe(data => {
          reload();
        });
      }
    } else if (type === 'task') {
      if (checkbox.checked) {
        const sendObj = {
          compositionId: compId,
          taskId: productId,
        } as SelectTaskItem;
        this.queryService.putSelectTask(consId, sendObj).subscribe(data => {
          reload();
        });
      } else {
        const sendObj = {
          compositionId: compId,
          taskId: productId,
          target: 'RECOMMENDED',
        } as DeselectTaskItem;
        this.queryService.putDeselectTask(consId, sendObj).subscribe(data => {
          reload();
        });
      }
    } else if (type === 'transition') {
      if (checkbox.checked) {
        const sendObj = {
          compositionId: compId,
          transitionId: productId,
        } as SelectTransitionItem;
        this.queryService.putSelectTransition(consId, sendObj).subscribe(data => {
          reload();
        });
      } else {
        const sendObj = {
          compositionId: compId,
          transitionId: productId,
          target: 'RECOMMENDED',
        } as DeselectTransitionItem;
        this.queryService.putDeselectTransition(consId, sendObj).subscribe(data => {
          reload();
        });
      }
    }

    const reload = () => {
      this.notifyMainComponents();
      this.doAction('cart-item', 'reload-cart', { productExtras: true });
    };
  }

  private notifyMainComponents() {
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }
    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
