<div class="d-flex flex-column flex-scroll w-100 h-100 align-items-center kf-bg">
  <div class="d-flex justify-content-center align-items-center w-100 h-100">
    <ng-container>
      <div class="flex-column w-25">
        <h2 class="my-3">Kunden importieren</h2>
        <div style="margin-bottom: 2rem">Starten Sie den Import von Kundendaten.</div>
        <form [formGroup]="form" class="mb-5">
          <mat-form-field class="notranslate">
            <mat-label>Kunden-Nr.</mat-label>
            <input
              ngDefaultControl
              formControlName="clientId"
              matInput
              type="text"
              [disabled]="importIsRunning"
              (change)="numberIsFound($event)"
            />
            <mat-error>
              <kf-input-error
                *ngIf="form.controls.clientId.invalid"
                [message]="getFieldErrorMessage(form.controls.clientId, 'clientId')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>

          @if (duplicateNumber) {
            <mat-error><kf-input-error message="Diese Kunden-Nr. ist bereits vergeben"></kf-input-error></mat-error>
          }

          <mat-form-field class="notranslate">
            <mat-label>Bankleitzahl</mat-label>
            <input ngDefaultControl formControlName="bankCode" matInput type="text" [disabled]="importIsRunning" />
            <mat-error>
              <kf-input-error
                *ngIf="form.controls.bankCode.invalid"
                [message]="getFieldErrorMessage(form.controls.bankCode, 'bankCode')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </form>
        <div [style.width]="'70%'">
          <button
            class="kf-btn-flexible"
            mat-flat-button
            color="primary"
            (click)="onStartImport()"
            [disabled]="!form.valid || importIsRunning"
          >
            <span>Importieren & prüfen</span>
            <kf-icon name="ic_pfeil_rechts_24"></kf-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<button class="kf-popup-close" mat-icon-button aria-label="Abbrechen" (click)="onCancel()">
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>
