import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariantCalculatorProductDetails } from '@services/product-variant-calculator/product-variant-calculator';

@Component({
  selector: 'product-variant-card',
  templateUrl: './product-variant-card.component.html',
  styleUrls: ['./product-variant-card.component.scss'],
})
export class ProductVariantCardComponent {
  @Input() product: VariantCalculatorProductDetails;
  @Input() calculatedPrice: number;
  @Input() recommended: boolean;
  @Input() inCart: boolean;
  @Output() toggleInCart = new EventEmitter<void>();

  public getTestcafeId(name = '') {
    if (!this.inCart) {
      return `productVariantCard-button-putInCart-${name?.replace(/ /g, '')}`;
    } else {
      return `productVariantCard-button-inCart-${name?.replace(/ /g, '')}`;
    }
  }
}
