<h2 class="mb-3 ml-1 mr-2 flex-grow-1">Lösungen</h2>

<ng-template *ngTemplateOutlet="recommendationSelected"></ng-template>
<ng-template *ngTemplateOutlet="recommendationRecommended"></ng-template>

<!-- ------ Individual Tasks and Transitions ------ -->
<div class="my-4 justify-content-center button-row">
  <div class="button-row-content">
    <button
      [attr.data-testcafe]="'sideConsultation-button-individualTasks'"
      mat-stroked-button
      color="primary"
      (click)="openIndividualTasks()"
    >
      <span>Maßnahme</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>

    <button
      [attr.data-testcafe]="'sideConsultation-button-individualTransition'"
      mat-stroked-button
      color="primary"
      (click)="openIndividualTransitions()"
    >
      <span>Expertengespräch</span>
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
    </button>
  </div>
</div>
<!--  Compare Accounts  -->
@if (compareAccountsButtonText() !== undefined) {
  <div class="further-solutions-button">
    <button
      [attr.data-testcafe]="'screenConsultation-button-compareAccounts'"
      mat-stroked-button
      color="primary"
      (click)="openCompareAccounts()"
    >
      <span>{{ compareAccountsButtonText() }}</span>
      <kf-icon name="ic_pfeile_links_rechts_24"></kf-icon>
    </button>
  </div>
}

<div class="further-solutions-button">
  @if (!sideOpen) {
    <button mat-stroked-button color="primary" (click)="openExtra()">
      <span>Weitere Lösungen</span>
    </button>
  }
</div>

<!-- RECOMMENDATION SELECTED -->
<ng-template #recommendationSelected>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Ausgewählt</h3>
  @if (isSelectedEmpty) {
    <div class="w-100 mb-2 empty-container">Keine ausgewählten Lösungen</div>
  } @else {
    @for (recSelected of recDataSelected; track recSelected) {
      <div class="recommendation-container" tabindex="0">
        <div class="d-flex flex-column w-100">
          <div class="composition-title">{{ recSelected.compositionName }}</div>

          <div class="rec-button">
            <mat-checkbox
              [checked]="true"
              tabindex="-1"
              color="grey"
              (click)="onRecDeselected(recSelected); $event.preventDefault()"
            >
              @switch (recSelected.type) {
                @case (recommendationTypeEnum.task) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_person_mit_kreis_24"
                    aria-hidden="true"
                    kfTooltip="Maßnahme"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.transition) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon ml-2"
                    name="ic_personen_paar_24"
                    aria-hidden="true"
                    kfTooltip="Expertengespräch"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.subtopic) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_sprechblase_fragezeichen_ausrufezeichen_24"
                    aria-hidden="true"
                    kfTooltip="Unterthema"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @default {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_allgemeine_karte_24"
                    aria-hidden="true"
                    kfTooltip="Produkt"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
              }
              <span class="label-text">{{ recSelected.name }}</span>
            </mat-checkbox>

            <div class="rec-badge">
              @if (recSelected.recommended) {
                <div class="tag-info tag-neutral">
                  <div class="tag-info-text">Empfohlen</div>
                </div>
              }
            </div>
          </div>

          @if (recSelected.type === recommendationTypeEnum.subtopic) {
            <button
              mat-stroked-button
              color="primary"
              class="mx-3 add2agenda-button"
              aria-label="Zur aktuellen Beratung hinzufügen"
              (click)="addSubtopicToAgenda(recSelected)"
            >
              <span>Zur aktuellen Beratung hinzufügen</span>
            </button>
          }
        </div>
        <button
          class="info-button"
          iconName="ic_infozeichen_mit_kreis_24"
          (click)="showRecommendationDetails(recSelected, true)"
          aria-label="Details anzeigen"
          [kfTooltip]="'Details zu: ' + recSelected.name"
          kfTooltipPosition="after"
          kfTooltipShow="hover"
          mat-icon-button
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
    }
  }
</ng-template>

<!-- RECOMMENDATION RECOMMENDED -->
<ng-template #recommendationRecommended>
  <h3 class="mat-body-1 font-weight-bold pl-2 mb-2 pt-2 ml-1" style="min-height: 1.5rem">Empfohlen</h3>
  @if (isRecommendedEmpty) {
    <div class="w-100 empty-container">Keine empfohlenen Lösungen</div>
  } @else {
    @for (rec of recDataRecommended; track rec) {
      <div class="recommendation-container" tabindex="0">
        <div class="d-flex flex-column w-100">
          <div class="composition-title">{{ rec.compositionName }}</div>

          <div class="rec-button">
            <mat-checkbox
              [checked]="false"
              tabindex="-1"
              color="grey"
              (click)="onRecSelected(rec); $event.preventDefault()"
            >
              @switch (rec.type) {
                @case (recommendationTypeEnum.task) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_person_mit_kreis_24"
                    aria-hidden="true"
                    kfTooltip="Maßnahme"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.transition) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_personen_paar_24"
                    aria-hidden="true"
                    kfTooltip="Expertengespräch"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @case (recommendationTypeEnum.subtopic) {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_sprechblase_fragezeichen_ausrufezeichen_24"
                    aria-hidden="true"
                    kfTooltip="Unterthema"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
                @default {
                  <kf-icon
                    class="flex-shrink-0 subtopic-icon mr-2"
                    name="ic_allgemeine_karte_24"
                    aria-hidden="true"
                    kfTooltip="Produkt"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover"
                  ></kf-icon>
                }
              }
              <span class="label-text">{{ rec.name }}</span>
            </mat-checkbox>
          </div>

          @if (rec.type === recommendationTypeEnum.subtopic) {
            <button
              mat-stroked-button
              color="primary"
              class="mx-3 add2agenda-button"
              aria-label="Zur aktuellen Beratung hinzufügen"
              (click)="addSubtopicToAgenda(rec)"
            >
              <span>Zur aktuellen Beratung hinzufügen</span>
            </button>
          }
        </div>
        <button
          class="info-button"
          (click)="showRecommendationDetails(rec, false)"
          aria-label="Details anzeigen"
          [kfTooltip]="'Details zu: ' + rec.name"
          kfTooltipPosition="after"
          kfTooltipShow="hover"
          mat-icon-button
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
    }
  }
</ng-template>

@if (sideOpen) {
  <button
    [attr.data-testcafe]="'sideRecommendation-button-back'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="handleClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
}
