<section class="result-transitions border mt-4">
  @if (data?.length) {
    <!-- outer panel -->
    <mat-expansion-panel
      class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      collapsedHeight="*"
      expandedHeight="*"
      [hideToggle]="false"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [class.is--closed]="!panelOpenState"
      #transitionsPanel
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="text-nowrap" [attr.data-testcafe]="'screenResult-panel-transitionHeader'"
          ><strong class="font-weight-bold">Expertengespräche</strong></mat-panel-title
        >
      </mat-expansion-panel-header>

      @for (transition of data; track transition.name; let index = $index) {
        <div>
          <mat-expansion-panel
            class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
            [expanded]="activeTransitionIndex === index"
          >
            <mat-expansion-panel-header (click)="closeDetailPanel(index)">
              <div class="d-flex flex-column w-100">
                <mat-panel-title class="row justify-content-between mt-2">
                  <div class="col-sm-8 order-2 order-sm-1 mb-2">
                    <strong class="font-weight-bold">{{ transition.name }}</strong>
                  </div>
                  <!-- email sent -->
                  @if (transition.mailSent) {
                    <div class="col-sm-4 d-flex justify-content-sm-end order-1 order-sm-2">
                      <div class="tag-info tag-positive mr-3 mb-2">
                        <div class="tag-info-text">E-Mail versendet</div>
                      </div>
                    </div>
                  }
                </mat-panel-title>

                <div class="align-items-center mt-2">
                  <!-- expert contact -->
                  @if (transition.expertContact) {
                    <div
                      class="d-flex align-items-start"
                      [attr.data-testcafe]="'screenResult-div-transition-expertContact'"
                    >
                      <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                      <span class="pl-2">{{ getName(transition.expertContact) }}</span>
                    </div>
                  }
                </div>
              </div>
            </mat-expansion-panel-header>

            <!-- panel content -->

            <!-- appointment -->
            @if (transition.appointments) {
              @if (transition.appointments.length) {
                <p class="w-100 font-weight-bold mb-0">
                  {{ transition.appointments.length === 1 ? 'Terminvorschlag' : 'Terminvorschläge' }}
                </p>
              }
              @for (appointment of transition.appointments; track appointment.appointmentStart) {
                <div
                  class="d-flex w-100 mt-2 align-items-start"
                  [attr.data-testcafe]="'screenResult-div-transitionAppointment'"
                >
                  <kf-icon name="ic_kalender_termin_24" class="flex-shrink-0"></kf-icon>
                  <span class="pl-2">
                    {{ appointment.appointmentStart | date: 'dd. MMMM YYYY, HH:mm' }} -
                    {{ appointment.appointmentEnd | date: 'HH:mm' }} Uhr
                  </span>
                </div>
              }
            }

            @if (transition.expertContact || transition.customerContact) {
              <!-- expert contact/ customer contact -->
              <div class="row">
                @if (transition.expertContact) {
                  <div class="col-12 col-sm-6 mt-3">
                    <p class="w-100 font-weight-bold mb-0">Experte</p>
                    <div class="w-100 d-flex align-items-start mt-2">
                      <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                      <ng-template *ngTemplateOutlet="person; context: { person: transition.expertContact }">
                      </ng-template>
                    </div>
                  </div>
                }

                <!-- customer contact -->
                @if (transition.customerContact) {
                  <div class="col-12 col-sm-6 mt-3">
                    <p class="w-100 font-weight-bold mb-0">Ansprechpartner</p>
                    <div class="w-100 d-flex align-items-start mt-2">
                      <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                      <ng-template *ngTemplateOutlet="person; context: { person: transition.customerContact }">
                      </ng-template>
                    </div>
                  </div>
                }
              </div>
            }

            @if (transition.individualNote) {
              <!-- individual notes -->
              <div class="row mt-3">
                <div class="col" [attr.data-testcafe]="'screenResult-div-transitionNotes'">
                  <p class="w-100 font-weight-bold mb-0">Notizen zum Expertengespräch</p>
                  <p>{{ transition.individualNote }}</p>
                </div>
              </div>
            }
            @if (transition.dataFieldGroups?.length) {
              <!-- datafield groups -->
              <div class="row">
                <div class="col">
                  <p class="w-100 font-weight-bold mb-0">Datengrundlage</p>
                </div>
              </div>
              @for (dataFieldGroup of transition.dataFieldGroups; track dataFieldGroup.name) {
                <div class="row">
                  <div class="col">
                    <p class="w-100 font-weight-bold mb-0">{{ dataFieldGroup.name }}</p>
                    @for (dataField of dataFieldGroup.dataFields; track dataField.name) {
                      <dl class="mb-1 row">
                        <dt class="col-6 font-weight-normal dataField-term">{{ dataField.name }}</dt>
                        <dd class="col-6 mb-0 dataField-value">{{ dataField.value || '-' }}</dd>
                      </dl>
                    }
                  </div>
                </div>
              }
            }

            @if (getDocuments(transition)) {
              <!-- attached documents -->
              <div class="mt-3 col-12 col-md-6">
                <p class="font-weight-bold mb-2">Dokumente zum Expertengespräch</p>
                <ul class="list-unstyled">
                  @for (document of getDocuments(transition); track document.name) {
                    <li>
                      <kf-document-tile
                        [titletext]="document.name"
                        [documentType]="document.type"
                        [disabled]="loading"
                        (handleClick)="openDocument(document.url)"
                        [attr.data-testcafe]="'screenResult-button-transitionDocuments-' + getTestcafeId(document.name)"
                        size="small"
                      >
                        <kf-icon [name]="documentTypeAsIcon(document.type, 48)"></kf-icon>
                      </kf-document-tile>
                    </li>
                  }
                </ul>
              </div>
            }

            <!-- / panel content -->
          </mat-expansion-panel>
        </div>
      }
    </mat-expansion-panel>
    <!-- / outer panel -->
    @if (!panelOpenState) {
      <ul class="w-100 list-inline mb-0 pr-4 py-2 pl-4">
        @for (transition of data; track transition.name; let index = $index) {
          <li class="list-inline-item">
            <button mat-button color="primary" (click)="openTransition(index)">
              {{ transition.name }}
            </button>
          </li>
        }
      </ul>
    }
  } @else {
    <div class="pl-4 pr-3 pt-3">
      <strong>Expertengespräche</strong>
      <p class="pl-3">Für diese Beratung wurden keine Expertengespräche terminiert.</p>
    </div>
  }
</section>

<!-- person template for customer and expert-->
<ng-template #person let-person="person">
  <div class="pl-2 d-flex flex-column">
    <span class="mb-1">{{ getName(person) }}</span>
    @if (person.email) {
      <span>
        E-Mail: <a [href]="'mailto:' + person.email">{{ person.email }}</a>
      </span>
    }
    <span>{{ person.department ? 'Abteilung: ' + person.department : '' }}</span>
    <span>{{ person.position ? 'Position: ' + person.position : '' }}</span>
  </div>
</ng-template>
