import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';
import { StorageService } from '@services/storage-service/storage.service';

@Component({
  selector: 'app-screen-error',
  templateUrl: './screen-error.component.html',
  styleUrls: ['./screen-error.component.scss'],
})
export class ScreenErrorComponent implements OnInit {
  public isVp = environment.platform === 'vp';
  public errorReason: string;
  public error503Page: boolean = false;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private clientService: ClientService,
    private contextService: ContextService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      const stateObj = this.router.getCurrentNavigation()?.extras?.state;
      // do stuff...
      if (stateObj.reason) {
        if (stateObj.reason === 'invalidUrl') {
          this.errorReason = 'Es wurde eine ungültige URL angegeben.';
        } else if (stateObj.reason === 'noHubId') {
          this.storageService.remove('bgzvHubId');
          this.errorReason = 'Es ist keine gültige Beratung aktiv.';
        } else if (stateObj.reason === 'invalidHubId') {
          this.storageService.remove('bgzvHubId');
          this.errorReason = 'Es wurde keine gültige Beratung gewählt.';
        } else if (stateObj.reason === 'error') {
          this.errorReason = 'Es ist ein allgemeiner Fehler aufgetreten.';
        } else if (stateObj.reason === 'invalidInstance') {
          this.errorReason = 'Es wurde keine gültige Instanz gewählt.';
        } else if (stateObj.reason === 'doneConsultation') {
          this.errorReason = 'Die Beratung wurde bereits archiviert.';
        } else if (stateObj.reason === 'checkoutNotFinished') {
          this.errorReason = 'Die Beratung wurde noch nicht beendet.';
        } else if (stateObj.reason === 'missingDataForAdhocConsultation') {
          this.errorReason = 'Es wurden nicht alle benötigten Daten übergeben.';
        } else if (stateObj.reason === 'error503') {
          this.error503Page = true;
        }
      }
    }
  }

  public ngOnInit(): void {
    this.contextService.toggleFocusMode('normal');
    this.footerService.hideContents();
    this.panelService.clearBgzvContent();
  }

  public onSelect(): void {
    this.router.navigate([RoutingPathMain.Welcome]);
  }

  public onBackToAppointment(): void {
    const kbmPath = `/kbm-agenda-appointments-overview/#`;
    const slug = this.appointmentUUID ? `gespraechvorbereitung/${this.clientService.appointmentUUID}` : `/`;
    const host = `${window.location.protocol}//${window.location.host}`;
    const path = `${kbmPath}/${slug}`;
    window.location.href = `${host}/${path}`;
  }

  get hasAppointmentButton(): boolean {
    return !this.contextService.isConsulationModuleContext;
  }

  get appointmentUUID(): string {
    return this.clientService.appointmentUUID || '';
  }

  get appointmentLabel(): string {
    return this.appointmentUUID ? 'Zum Termin' : 'Zur Terminübersicht';
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
