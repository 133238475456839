// --------------------------------------------- //
// --------------- COLOR FUNCTIONS ------------- //
// --------------------------------------------- //

/**
 * Find a color from the enums
 */
function colorFinder(name: string) {
  const colorValue = window.getComputedStyle(document.querySelector('html')).getPropertyValue(name);
  return colorValue || name;
}

/**
 * Color Object
 */
export const color = { find: colorFinder };
