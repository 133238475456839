import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { SubtopicOverviewItem } from '@domain/app/topic.domain';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { TopicService } from '@services/topic-service/topic-service';
import {
  baseConfig,
  buttonAgenda,
  buttonFinishPrep,
  summaryButtonBack,
  summaryButtonContinue,
} from '@utils/footer-config';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import 'moment/locale/de';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'screen-summary',
  templateUrl: './screen-summary.component.html',
  styleUrls: ['./screen-summary.component.scss'],
})
export class ScreenSummaryComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();
  private footerConfig: FooterConfig = baseConfig;
  private isLoading = false;

  public allRecommendedChecked: boolean = true;
  public subtopicSummaryId = '-1';
  public nextQuestionGroupId = '-1';
  public prevQuestionGroupId = '-1';
  public subtopicLastSummary = false;
  public isLastConsultantTopic = false;
  public hasTopicsForPrelim = false;

  public prepDividerType: 'consultant' | 'customer' | 'none' = 'none';
  public consultationType: 'main' | 'prep' | 'done' = 'main';

  public currentSubtopicData: SubtopicOverviewItem;
  public nextSubtopicData: SubtopicOverviewItem;

  public initialTopicSet = false;
  public cartScrolled = false;

  readonly moment = moment;

  constructor(
    private router: Router,
    private topicService: TopicService,
    private actionService: ActionService,
    private clientService: ClientService,
    private contextService: ContextService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService,
    private chg: ChangeDetectorRef,
    private queryService: QueryService,
    private noteService: NoteService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;
      if (stateObj.prevQuestionGroupId) {
        this.prevQuestionGroupId = stateObj.prevQuestionGroupId;
      }
      if (stateObj.nextQuestionGroupId) {
        this.nextQuestionGroupId = stateObj.nextQuestionGroupId;
        this.nextSubtopicData = this.topicService.selectedSubTopicDataByQuestionId(this.nextQuestionGroupId);
      }
      if (stateObj.subtopicId) {
        this.subtopicSummaryId = stateObj.subtopicId;
        this.currentSubtopicData = this.topicService.selectedSubTopicDataById(this.subtopicSummaryId);
      }
      if (stateObj.allDone) {
        this.subtopicLastSummary = stateObj.allDone;
      }
      if (stateObj.prepDividerType) {
        this.prepDividerType = stateObj.prepDividerType;
      }
      if (stateObj.isLastConsultantQuestionGroup) {
        this.isLastConsultantTopic =
          stateObj.isLastConsultantQuestionGroup &&
          this.clientService.consultationStatus !== ConsultationStatusEnum.mainConsultation;
      }
    }
  }

  public ngOnInit(): void {
    this.footerButtonsFactory();

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'overlay-cart') {
        // we borrow this from the shopping cart overlay
        if (action.action === 'show-all-recommended') {
          this.allRecommendedChecked = action.options.allRecommendedChecked;
        }
      }

      if (action && action.target === 'topic-summary') {
        if (action.action === 'update-next-subtopic') {
          this.updateNextSubtopic(action.options);
        }
      }

      this.chg.detectChanges();
    });
    this.consultationType = this.clientService.consultationStatusType;

    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (!!context && this.contextService.overlayWasClosed) {
        this.footerButtonsFactory();
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.isLoading = loading;
      this.footerButtonsFactory();
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });
  }

  public ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.complete();
  }

  /// ------------------------------------------------ ///
  /// --------------- FOOTER HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.isLoading) {
        return;
      }

      // right-hand proceed buttons
      if (event.id === 'summary-continue') {
        this.subtopicLastSummary ? this.onFinalizaton() : this.nextSubtopic();
      } else if (event.id === 'summary-back') {
        this.backToSubtopic();
      } else if (event.id === 'finish-prep-mode') {
        this.contextService.toggleContextMode();
      }
    }
  }

  public footerButtonsFactory(): void {
    if (this.contextService.currentOverlayContext !== null || !this.contextService.currentContext.includes('summary')) {
      return;
    }

    if (
      !!this.footerConfig?.drawing ||
      this.footerConfig?.right.isHidden ||
      (!!this.footerConfig?.leftCollapsable && !this.footerConfig?.leftCollapsable.isHidden)
    ) {
      this.footerConfig = baseConfig;
      this.footerService.fixCollapsibleSectionDisplay();
    }

    const currentConfig = cloneDeep(this.footerConfig);

    summaryButtonContinue.inputs.config.label = this.continueButtonText;
    summaryButtonContinue.inputs.config.disabled = this.isLoading;
    summaryButtonBack.inputs.config.disabled = this.isLoading;

    const rightButtonElements = [summaryButtonBack, summaryButtonContinue];
    if (this.consultationType === 'prep') {
      rightButtonElements.unshift(buttonFinishPrep);
    }
    currentConfig.right.elements = rightButtonElements;
    currentConfig.left.elements = this.footerService.isManagedFooter ? [] : [buttonAgenda];

    this.footerService.submitFooterConfig(currentConfig);
  }

  public nextSubtopic(): void {
    // TODO: Dynamic subtopicId - should come from backend
    this.router.navigate(
      [
        this.isLastConsultantTopic || this.prepDividerType !== 'none'
          ? RoutingPathPrep.Consultation
          : RoutingPathMain.Consultation,
      ],
      {
        state: { questionGroupId: this.nextQuestionGroupId },
      }
    );
  }

  public onFinalizaton(): void {
    if (this.consultationType !== 'prep') {
      this.router.navigate([RoutingPathMain.Checkout]);
    } else {
      this.onBackToOverviewClick();
    }
  }

  public backToSubtopic(): void {
    const target =
      this.isLastConsultantTopic || this.prepDividerType !== 'none'
        ? RoutingPathPrep.Consultation
        : RoutingPathMain.Consultation;
    this.router.navigate([target], {
      state: { questionGroupId: this.prevQuestionGroupId },
    });
  }

  //look into topics and subtopics if there's an entry that has availableForPreliminary
  public checkTopicsforPrelim() {
    const hasTopicForPrelim = this.topicService.selectedTopicsData?.find(topic => topic.availableForPreliminary);
    const hasSubTopicForPrelim = this.topicService.selectedTopicsData?.map(topic => {
      topic.subtopics?.find(subtopic => subtopic.availableForPreliminary);
    });

    if (!!hasTopicForPrelim || !!hasSubTopicForPrelim) {
      this.hasTopicsForPrelim === true;
    }
  }

  public onBackToOverviewClick(): void {
    this.router.navigate([`${RoutingPathPrep.Agenda}/${this.clientService.bankHubId}`]).then(() => {
      this.hasTopicsForPrelim && this.doAction('main', 'toggle');
    });
  }

  public createIndivTask() {
    this.doAction('cart-item', 'indiv-task-selected');
  }

  public createIndivTransition() {
    this.doAction('cart-item', 'indiv-transition-selected');
  }

  public selectAllRecommended(event: Event) {
    event.preventDefault();
    this.doAction('cart-item', 'all-recommended-selected', true);
  }

  private async updateNextSubtopic(previousSubtopicId: string): Promise<void> {
    const selectedSubtopicIds = this.topicService.consultationSelectedSubtopicIds();
    const index = selectedSubtopicIds.findIndex(x => x === previousSubtopicId);
    this.subtopicLastSummary = index === selectedSubtopicIds.length - 1;

    if (!this.subtopicLastSummary) {
      const nextSubtopicId = selectedSubtopicIds[index + 1];
      const selectedSubtopicData = this.topicService.subtopicData.selected;

      this.nextSubtopicData = selectedSubtopicData.find(x => x.id === nextSubtopicId);
      this.nextQuestionGroupId = this.nextSubtopicData?.questionGroups[0]?.id;

      this.topicService.updateTopicsData();
    }

    this.footerButtonsFactory();
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get nextTopicText(): string {
    if (this.prepDividerType === 'consultant') {
      return 'Kunden-Vorbefragung';
    } else if (this.prepDividerType === 'customer') {
      return 'Berater-Vorbereitung';
    }

    return 'Nächstes Thema';
  }

  get lastConsultantTopicText(): string {
    if (this.prepDividerType === 'consultant') {
      return 'Die Berater-Vorbereitung ist abgeschlossen!';
    } else if (this.prepDividerType === 'customer') {
      return 'Die Vorbereitung der Kunden-Vorbefragung ist abgeschlossen!';
    }

    return 'Die Vorbereitung der Beratung ist abgeschlossen!';
  }

  get lastTopic(): string {
    return this.currentSubtopicData?.title ? this.currentSubtopicData?.title : 'Vorheriges Thema';
  }

  get continueButtonText(): string {
    return this.subtopicLastSummary
      ? this.consultationType === 'prep'
        ? 'Vorbefragung beenden'
        : 'Bestellprozess starten'
      : this.nextSubtopicData?.title || 'Default';
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
