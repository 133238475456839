@if (productData?.length === 0 || !productData) {
  <section class="border result-products mt-4">
    <div class="pl-4 pr-3 pt-3">
      <strong class="text-nowrap">Produkte</strong>
      <p class="pl-3">Für diese Beratung wurden keine Produkte gewählt.</p>
    </div>
  </section>
} @else {
  <div class="mt-4">
    <div class="border position-relative result-products" [class.can-receive-hover]="!panelOpenState">
      <mat-expansion-panel
        #productsPanel
        class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
        [hideToggle]="false"
        (opened)="panelOpenState = true"
        (closed)="panelOpenState = false"
        [class.is--closed]="!panelOpenState"
      >
        @if (!panelOpenState) {
          <mat-expansion-panel-header>
            <div class="justify-content-between w-100 d-flex">
              <mat-panel-title class="text-nowrap" [attr.data-testcafe]="'screenResult-panel-products'"
                ><strong class="font-weight-bold">Produkte</strong></mat-panel-title
              >
              <ng-template *ngTemplateOutlet="sums; context: { data: getSums() }"></ng-template>
            </div>
          </mat-expansion-panel-header>
        } @else {
          <mat-expansion-panel-header>
            <mat-panel-title class="text-nowrap"><strong class="font-weight-bold">Produkte</strong></mat-panel-title>
            <div class="d-flex w-100 justify-content-end mr-3">
              <!--this is needed as a spacer-->
              <!-- conditions to show the status of contracts, tasks or agree causes -->
              <div class="d-flex mr-4 contract-status">
                <p class="font-weight-bold sr-only text-uppercase" id="contract-status-label">
                  Vertragsstatus, Status Anlassvorlage oder Maßnahme
                </p>
              </div>
              <div
                class="d-flex font-weight-bold justify-content-between product-pricing-legend"
                [ngClass]="!getSums().length ? 'sr-only' : null"
              >
                <div class="interval">Abrechnung</div>
                <div class="price text-right">Einzelpreis</div>
                <div class="amount text-right">Menge</div>
                <div class="price text-right">Nettopreis</div>
                <div class="sum-price text-right">Bruttopreis</div>
              </div>
            </div>
          </mat-expansion-panel-header>
        }

        @for (product of productData; track product; let i = $index) {
          <!-- sub product -->
          @if (product.fixCost || product.runningCost || product.dataFieldGroups.length) {
            <mat-expansion-panel
              [hideToggle]="false"
              [expanded]="activeProductIndex === i"
              class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
            >
              <mat-expansion-panel-header class="d-flex _border-top pt-3">
                <div class="d-flex justify-content-between w-100 align-items-center">
                  <ng-template
                    *ngTemplateOutlet="
                      productPricing;
                      context: { data: [product], options: { tableClass: 'product-pricing-information' } }
                    "
                  ></ng-template>
                </div>
              </mat-expansion-panel-header>

              <div>
                @if (product.dataFieldGroups) {
                  @for (dataFieldGroup of product.dataFieldGroups; track dataFieldGroup) {
                    <h5 class="w-100 font-weight-bold mb-2">{{ dataFieldGroup.name }}</h5>
                    <dl class="w-100 mb-4 pb-3">
                      @for (dataField of dataFieldGroup.dataFields; track dataField) {
                        <dt class="font-weight-normal w-25 dataField-term">
                          {{ dataField.name }}
                        </dt>
                        @if (dataField.value) {
                          <dd class="pl-2 mb-0 w-75 dataField-value">
                            {{ formatDataFieldValue(dataField.value) }}
                          </dd>
                        } @else {
                          <dd class="pl-2 mb-0 w-75 dataField-value">Keine Angabe</dd>
                        }
                      }
                    </dl>
                  }
                }
                <h5 class="w-100 font-weight-bold mt-4 mb-2">Dokumente zum Produkt</h5>
                @for (document of handleProductDocuments(product.documents); track document) {
                  <kf-document-tile
                    [titletext]="document.name"
                    [documentType]="document.type"
                    [disabled]="loading"
                    (handleClick)="openDocument(document.url)"
                    [attr.data-testcafe]="'screenResult-button-productDocument-' + getTestcafeId(document.name)"
                    size="small"
                  >
                    <kf-icon [name]="documentTypeAsIcon(document.type, 48)"></kf-icon>
                  </kf-document-tile>
                } @empty {
                  <p>Keine Dokumente vorhanden.</p>
                }
              </div>
            </mat-expansion-panel>
            <!-- / sub product -->
          } @else {
            <!-- main product -->
            <span class="d-block w-100 pt-2 mb-0" [class.mt-4]="i !== 0">{{ product.name }}</span>
            <!-- / main product -->
          }
        }

        @if (getSums().length > 0) {
          <ng-template *ngTemplateOutlet="totals; context: { data: getSums() }"></ng-template>
        }
      </mat-expansion-panel>
      @if (!panelOpenState) {
        <ul class="w-100 list-inline mb-0 pr-4 py-2 pl-4 position-relative">
          @for (product of selectedProduct; track product.id; let last = $last; let i = $index) {
            <li class="list-inline-item">
              <button mat-button color="primary" (click)="openProduct(i)">
                {{ product.name }}
              </button>
            </li>
          }
        </ul>
      }
    </div>
  </div>
}

<ng-template #sums let-data="data">
  @if (data?.length > 0) {
    <table>
      <thead>
        <tr>
          <th class="pr-4" scope="col">Zahlungsintervall</th>
          <th class="pr-4 text-right" scope="col">Nettopreis</th>
          <th class="font-weight-bold text-right" scope="col">Bruttopreis</th>
        </tr>
      </thead>
      <tbody>
        @for (entry of data; track entry) {
          <tr>
            <td class="pr-4">{{ entry.title | payInterval }}</td>
            <td class="pr-4 text-right" markNegativePrice>
              {{ formatPrice(entry.net, 1) }}
            </td>
            <td class="font-weight-bold text-right" markNegativePrice>
              {{ formatPrice(entry.gross, 1) }}
            </td>
          </tr>
        }
      </tbody>
    </table>
  }
</ng-template>

<ng-template #productPricing let-data="data" let-options="options">
  @if (data?.length > 0) {
    @for (product of data; track product) {
      <strong
        class="flex-shrink-0 pl-3 variant-product"
        [attr.datatestcafe]="'screenResult-panel-product-' + getTestcafeId(product.name)"
        >{{ product.name }}</strong
      >
      <div class="d-flex w-100 justify-content-end">
        <ng-template *ngTemplateOutlet="contractStatus; context: { data: product }"></ng-template>

        <table class="font-weight-bold" [ngClass]="options?.tableClass">
          <thead class="sr-only">
            <tr>
              <th scope="col" class="interval">Abrechnung</th>
              <th scope="col" class="text-right price">Einzelpreis</th>
              <th scope="col" class="text-right amount">Menge</th>
              <th scope="col" class="font-weight-bold text-right price">Nettopreis</th>
              <th scope="col" class="font-weight-bold text-right sum-price">Bruttopreis</th>
            </tr>
          </thead>

          @if (product.fixCost) {
            <tbody>
              <tr>
                <td class="interval">
                  {{ product.fixCost.paymentInterval | payInterval }}
                </td>
                <td class="text-right price">{{ formatPrice(product.fixCost.grossPrice, 1) }}</td>
                <td class="text-right amount">{{ product.selectedQuantity }}</td>
                <td class="text-right price" markNegativePrice>
                  {{ formatPrice(product.fixCost.netPrice, product.selectedQuantity) }}
                </td>
                <td class="font-weight-bold text-right sum-price" markNegativePrice>
                  {{ formatPrice(product.fixCost.grossPrice, product.selectedQuantity) }}
                </td>
              </tr>
            </tbody>
          }

          @if (product.runningCost) {
            <tbody>
              <tr>
                <td class="interval">
                  {{ product.runningCost.paymentInterval | payInterval }}
                </td>
                <td class="text-right price">{{ formatPrice(product.runningCost.grossPrice, 1) }}</td>
                <td class="text-right amount">{{ product.selectedQuantity }}</td>
                <td class="text-right price" markNegativePrice>
                  {{ formatPrice(product.runningCost.netPrice, product.selectedQuantity) }}
                </td>
                <td class="font-weight-bold text-right sum-price" markNegativePrice>
                  {{ formatPrice(product.runningCost.grossPrice, product.selectedQuantity) }}
                </td>
              </tr>
            </tbody>
          }

          @if (!product.fixCost && !product.runningCost) {
            <tbody class="sr-only">
              <tr>
                <td class="interval">keine Angaben</td>
                <td class="text-right price">keine Angaben</td>
                <td class="text-right amount">keine Angaben</td>
                <td class="text-right price">keine Angaben</td>
                <td class="font-weight-bold text-right sum-price">keine Angaben</td>
              </tr>
            </tbody>
          }
        </table>
      </div>
    }
  }
</ng-template>

<ng-template #totals let-data="data">
  <div class="product-pricing-footer d-flex w-100 justify-content-between align-items-center kf-bg-grey-200 border-top">
    <div class="flex-column">
      <strong>Gesamtsumme</strong>
      <p>
        Entspricht der Bruttopreis dem Nettopreis, so handelt es <br />
        sich um umsatzsteuerbefreite Bankdienstleistungen.
      </p>
    </div>
    <div class="flex-column">
      <ng-template *ngTemplateOutlet="sums; context: { data }"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #contractStatus let-product="data">
  <div class="mr-4 contract-status">
    <!-- product has neither a contract nor agree cause or task assigned -->
    @if (!product.contractDocumentId && !hasAgreeOrTask(product)) {
      <div
        class="d-flex align-items-center contract-status-item"
        [attr.datatestcafe]="'screenResult-div-notHasAgreeOrTaskOrContracts'"
      >
        <span class="sr-only">Keine Angaben</span>
      </div>
    }

    <!-- TODO: contract(s) being created -->
    @if (getContractPending(product)) {
      <div class="d-flex align-items-center contract-status-item">
        <div class="d-flex align-items-center">
          <mat-spinner [diameter]="24" [color]="'primary'" class="mr-1"></mat-spinner>
          <p class="font-weight-bold text-nowrap m-0">Verträge werden erstellt</p>
        </div>
      </div>
    }

    <!-- contract(s) downloadable -->
    @if (getContractPDFViewable(product)) {
      <button
        [attr.datatestcafe]="'screenResult-button-contractPDF'"
        [disabled]="getContractError(product) || getContractPending(product)"
        mat-stroked-button
        color="primary"
        (click)="openContractPDF(product, customerData.id)"
      >
        <span>Verträge anzeigen</span>
      </button>
    }

    <!-- product has no contract but an agree cause or task assigned -->
    @if (!product.contractDocumentId && hasAgreeOrTask(product)) {
      <div
        class="d-flex align-items-center contract-status-item"
        [attr.datatestcafe]="'screenResult-div-hasAgreeOrTask'"
      >
        <div class="tag-info tag-positive ml-3">
          <div class="tag-info-text">
            {{ product.agreeCauseCreated ? 'Anlassvorlage erstellt' : 'Maßnahme erstellt' }}
          </div>
        </div>
      </div>
    }
  </div>
</ng-template>
