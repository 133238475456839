import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  QuestionGroupOverviewItem,
  QuestionOverviewItem,
  SubtopicOverviewItem,
  TopicOverviewItem,
} from '@domain/app/topic.domain';
import { TopicStatusEnum } from '@enums';
import { ContextService } from '@services/context-service/context.service';
import { color } from '@utils/helpers/color';

export interface ItemMenuOptions {
  mainMarginBottom?: string;
  mainMarginLeft?: string;
  headerHeight?: string;
  groupIndent?: string;
  groupCursor?: string;
  singleTopic?: boolean;
  startExpanded?: boolean;
  isDisabled?: boolean;
  isGreyed?: boolean;
  enableSubtopicJump?: boolean;
  boldSubtopic?: boolean;
  surpressExpansion?: boolean;
}

@Component({
  selector: 'item-menu-structure',
  templateUrl: './item-menu-structure.component.html',
  styleUrls: ['./item-menu-structure.component.scss'],
})
export class ItemMenuStructureComponent {
  constructor(private contextService: ContextService) {}
  // TODO: Allow for no options set
  @Input() private _options: ItemMenuOptions;
  @Input()
  set options(value: ItemMenuOptions) {
    this._options = { ...this.defaultOptions, ...value };
  }

  @Input() isDisabled = false;
  @ViewChildren('box') checkboxes;
  @ViewChildren('boxgroup') groupCheckboxes;

  get options(): ItemMenuOptions {
    return this._options;
  }

  readonly color = color;
  readonly status = TopicStatusEnum;
  readonly defaultOptions: ItemMenuOptions = {
    mainMarginBottom: '1.5rem',
    mainMarginLeft: '1.5rem',
    headerHeight: '4.75rem',
    groupIndent: '3.75rem',
    groupCursor: 'default',
    singleTopic: false,
    startExpanded: false,
    isDisabled: false,
    enableSubtopicJump: false,
    surpressExpansion: false,
  };

  public box: MatCheckbox;

  @Input() data: TopicOverviewItem;
  @Input() index = 0;
  @Input() useIndex = false;
  @Input() displayType: 'progress' | 'none' | 'check' | 'select' | 'select-prep' = 'none';
  @Input() onlySelected = false;
  @Input() surpressExpansion = false;
  @Output() subItemSelected = new EventEmitter<[boolean, SubtopicOverviewItem, string[]]>();
  @Output() groupItemSelected = new EventEmitter<[boolean, QuestionGroupOverviewItem]>();
  @Output() questionItemSelected = new EventEmitter<
    [boolean, QuestionGroupOverviewItem, { id: string; checked: boolean }]
  >();

  @Output() jumpToGroup = new EventEmitter<SubtopicOverviewItem>();

  @Input() isQuestionGroupShown: (qg: QuestionGroupOverviewItem) => boolean = () => true;
  @Input() isSubtopicExpandable: (st: SubtopicOverviewItem) => boolean = () => true;

  public handleInnerItemSelect(checked: boolean, item: SubtopicOverviewItem) {
    const attribute = this.displayType === 'select-prep' ? 'showInPreliminary' : 'selected';

    const questionGroupsToChange = item.questionGroups
      .map(x => {
        if (checked && !x.showInPreliminary) {
          return x.id;
        } else if (!checked && x.showInPreliminary) {
          return x.id;
        }
        return '-1';
      })
      .filter(y => y);

    item[attribute] = checked;
    for (const qg of item.questionGroups) {
      qg[attribute] = checked;
      if (qg?.questions) {
        for (const q of qg.questions) {
          q[attribute] = checked;
        }
      }
    }
    this.subItemSelected.emit([checked, item, questionGroupsToChange]);
  }

  public handleQuestionGroupSelect(checked: boolean, item: QuestionGroupOverviewItem) {
    const attribute = this.displayType === 'select-prep' ? 'showInPreliminary' : 'selected';

    item[attribute] = checked;

    for (const q of item.questions) {
      q[attribute] = checked;
    }
    const selectedElementExists = this.data.subtopics
      .find(x => x.questionGroups.find(y => y === item))
      .questionGroups.find(x => x[attribute] === true);

    if (checked || (!checked && !selectedElementExists)) {
      this.data.subtopics.find(x => x.questionGroups.find(y => y === item))[attribute] = checked;
    }

    this.groupItemSelected.emit([checked, item]);
  }

  public handleQuestionSelect(checked: boolean, item: QuestionOverviewItem) {
    const attribute = this.displayType === 'select-prep' ? 'showInPreliminary' : 'selected';

    item[attribute] = checked;
    const selectedElementExistInQuestionGroup = this.data.subtopics
      .find(x => x.questionGroups.find(y => y.questions.find(z => z === item)))
      .questionGroups.find(x => x.questions.find(y => y[attribute] === true));

    const group = {
      id: this.data.subtopics.find(x => x.questionGroups.find(y => y.questions.find(z => z === item))).questionGroups[0]
        .id,
      checked: this.data.subtopics
        .find(x => x.questionGroups.find(y => y.questions.find(z => z === item)))
        .questionGroups.flatMap(v => v.questions.map(w => w.showInPreliminary))
        .includes(true),
    };

    if (checked || (!checked && !selectedElementExistInQuestionGroup)) {
      this.data.subtopics
        .find(x => x.questionGroups.find(y => y.questions.find(z => z === item)))
        .questionGroups.find(x => x.questions.find(x => x === item))[attribute] = checked;
    }

    if (checked || (!checked && !selectedElementExistInQuestionGroup)) {
      this.data.subtopics.find(x => x.questionGroups.find(y => y.questions.find(z => z === item)))[attribute] = checked;
    }
    this.questionItemSelected.emit([checked, item, group]);
  }

  public isIndeterminate(data): boolean {
    const len = data[0].questions?.length;
    const selectedLen = data[0].questions?.filter(item => item.showInPreliminary === true).length;

    return selectedLen > 0 && len !== selectedLen;
  }

  public determineExpanded(innerItem: SubtopicOverviewItem): boolean {
    if (this.displayType === 'select-prep') {
      return true;
    } else if (this.displayType === 'progress') {
      if (innerItem.questionGroups) {
        if (innerItem.questionGroups.find(x => this.isQuestionGroupShown(x))) {
          return true;
        } else {
          return false;
        }
      } else if (this.isQuestionGroupShown(innerItem)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public setColor(innerItem): string {
    let returnColor = '';
    const shown = color.find('--color-grey-900');
    const greyed = color.find('--color-grey-500');

    if (this.displayType === 'select-prep') {
      returnColor = shown;
    } else if (this.displayType === 'progress') {
      if (innerItem.questionGroups) {
        if (innerItem.questionGroups.find(x => this.isQuestionGroupShown(x))) {
          returnColor = shown;
        } else {
          returnColor = greyed;
        }
      } else if (this.isQuestionGroupShown(innerItem)) {
        returnColor = shown;
      } else {
        returnColor = greyed;
      }
    } else {
      returnColor = shown;
    }
    return returnColor;
  }

  public jumpToQuestionGroup(innerItem: SubtopicOverviewItem) {
    this.jumpToGroup.emit(innerItem);
  }

  public setCheckBox(id: number, innerItem: SubtopicOverviewItem | QuestionGroupOverviewItem, fromQuestion = false) {
    const currentCheckBox = this.checkboxes._results.find(x => x['id'] === `${innerItem.id}-${id.toString()}`);
    currentCheckBox._checked = !currentCheckBox._checked;
    if (fromQuestion) {
      this.handleQuestionGroupSelect(currentCheckBox._checked, innerItem as QuestionGroupOverviewItem);
    } else {
      this.handleInnerItemSelect(currentCheckBox._checked, innerItem as SubtopicOverviewItem);
    }
  }

  public setGroupCheckBox(id: string, groupItem: QuestionGroupOverviewItem) {
    const currentCheckBox = this.groupCheckboxes._results.find(x => x['id'] === id);
    currentCheckBox._checked = !currentCheckBox._checked;
    groupItem.questions
      ? this.handleQuestionGroupSelect(currentCheckBox._checked, groupItem)
      : this.handleQuestionSelect(currentCheckBox._checked, groupItem);
  }

  public hasPrelimSelectedChildren(
    element: SubtopicOverviewItem | QuestionOverviewItem,
    target: 'questions' | 'questionGroups'
  ): boolean {
    const prelimChild = element[target].find(x => x.showInPreliminary);
    return prelimChild !== undefined;
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  get mode() {
    return this.contextService.currentMode;
  }
}
