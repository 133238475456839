import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss'],
})
export class GlobalLoaderComponent implements OnInit, OnDestroy {
  public showLoader: boolean = false;

  @Input() message: string;

  constructor() {}

  ngOnInit(): void {
    this.show();
  }

  private show(): void {
    const mainEl = document.querySelector('#bgzv-frontend-main') as HTMLElement;
    mainEl.classList.remove('d-flex');
  }

  private hide(): void {
    const mainEl = document.querySelector('#bgzv-frontend-main') as HTMLElement;
    mainEl.classList.add('d-flex');
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
