<div class="px-4 content-element-datepick-content">
  <mat-calendar
    [selected]="appointmentDate"
    [minDate]="minimumDate"
    class="border"
    color="primary"
    (selectedChange)="onDateSelect($event)"
  ></mat-calendar>
</div>

<mat-divider></mat-divider>

<footer class="px-4 py-3 d-flex" [class.side-footer-margin]="useMargin">
  <button
    mat-stroked-button
    color="primary"
    class="ml-3"
    [attr.data-testcafe]="'sideContentElement-button-abortAppointment'"
    (click)="changePanelState.emit(panelStateEnum.assign)"
  >
    Abbrechen
  </button>
  <button
    mat-flat-button
    color="primary"
    class="ml-3"
    [attr.data-testcafe]="'sideContentElement-button-suggestAppointment'"
    [disabled]="!appointmentDate"
    (click)="confirmDate()"
  >
    Fälligkeit festlegen
  </button>
</footer>
