<section class="composition-grid mt-2 pt-4">
  <img [src]="assetSource" alt="" />
  <div>
    <div class="d-flex align-items-center mb-2">
      <h2
        class="vr-text-large font-weight-bold mr-2 mb-0"
        kfTooltipShow="hover"
        kfTooltipPosition="below"
        [debugId]="contentElement.id"
        [innerHTML]="truncateName | safeHtml"
        [kfTooltip]="contentElement.name.length > 50 ? contentElement.name : null"
      ></h2>
    </div>
    <div class="composition-topic-border">
      <div class="d-flex align-items-center" style="column-gap: 1rem">
        <div class="d-flex align-items-center flex-grow-1">
          <mat-checkbox
            #taskCheckbox
            color="grey"
            [checked]="contentElement.selected || !this.contentElement.id"
            [disabled]="loading"
            [disableRipple]="true"
            [attr.data-testcafe]="'itemCompositionContentElement-checkbox-check-' + testcafeId"
            (change)="selectContentElement($event)"
          >
            <span [innerHTML]="truncateSubtopicName || 'NO TITLE' | safeHtml"></span>
          </mat-checkbox>

          @if (contentElement?.recommended) {
            <div class="tag-info tag-neutral mb-1 ml-3">
              <span class="tag-info-text">Empfohlen</span>
            </div>
          }
        </div>

        <button
          [class.hidden]="!contentElement.selected"
          mat-stroked-button
          color="primary"
          [disabled]="loading"
          [attr.data-testcafe]="'itemCompositionContentElement-button-assign-' + testcafeId"
          (click)="openAssignContentElementPanel()"
        >
          <span>{{ contentElement?.customerContact ? 'Zuweisung ändern' : 'Zuweisen' }}</span>
        </button>
        <button
          mat-icon-button
          class="ml-3"
          style="margin: 0 !important"
          aria-label="Details anzeigen"
          kfTooltipShow="hover"
          kfTooltipPosition="below"
          [kfTooltip]="'Details zu: ' + contentElement.name"
          [attr.data-testcafe]="'itemComposition-button-details'"
          (click)="openInfo()"
        >
          <kf-icon name="ic_infozeichen_mit_kreis_24"></kf-icon>
        </button>
      </div>
      @if (contentElement?.customerContact) {
        <div class="d-flex align-items-center mat-small mt-3">
          <div class="d-flex align-items-center">
            <kf-icon name="ic_person_mit_kreis_24" class="mr-2"></kf-icon>
            <span>{{ contactName }}</span>
          </div>
          @if (contentElement?.appointmentDate) {
            <div class="mx-2">•</div>
            <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
            <span>{{ appointmentDate }}</span>
          }
        </div>
      }
    </div>
  </div>
</section>
