import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MediaItem } from '@domain/app/media.domain';
import {
  AdditionalProductRecommendationItem,
  DeselectProductItem,
  MainProductRecommendationItem,
  ProductRecommendationItem,
  SelectProductItem,
} from '@domain/app/product.domain';
import { BenefitTypeEnum, DataFieldStatusEnum, MediaTypeEnum, PaymentInterval, RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { color } from '@utils/helpers/color';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'item-composition-main',
  templateUrl: './item-composition-main.component.html',
  styleUrls: ['./item-composition-main.component.scss', '../item-cart/item-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCompositionMainComponent implements OnInit, OnDestroy {
  @Input() mainData: MainProductRecommendationItem;
  @Input() benefitData: BenefitTypeEnum[];
  @Input() compositionId: string;
  @Input() compositionMedia: MediaItem;
  @Input() onlyRecommendedVariants = true;
  @Output() showDetails = new EventEmitter<any>();
  @Output() quantitySet = new EventEmitter<[any, number]>();
  @Output() showExtraProducts = new EventEmitter<string>();
  @Output() showDataFields = new EventEmitter<string>();

  private destroySubs = new Subject<void>();

  public costTypeEnum = PaymentInterval;
  public loading = false;
  public quantityMap: Map<string, number> = new Map<string, number>();

  readonly costType = PaymentInterval;
  readonly color = color;

  constructor(
    private cp: CurrencyPipe,
    private clientService: ClientService,
    private contextService: ContextService,
    private queryService: QueryService,
    private actionService: ActionService,
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'item-product') {
      }
    });
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public selectProduct(id: number, checkbox: MatCheckbox): void {
    checkbox.checked = !checkbox.checked;
    this.setProductSelectedState(id, { checked: checkbox.checked });
  }

  public setProductSelectedState(productId, checkbox: MatCheckboxChange | { checked: boolean }) {
    if (checkbox.checked) {
      const sendObj = {
        compositionId: this.compositionId,
        productId: productId,
        quantity: 1,
      };
      this.queryService.putSelectProduct(this.clientService.consultationId, sendObj).subscribe(data => {
        this.notifyMainComponents();
        this.doAction('cart-item', 'reload-cart');
      });
    } else {
      const sendObj = {
        compositionId: this.compositionId,
        productId: productId,
        target: 'RECOMMENDED',
      } as DeselectProductItem;
      this.queryService.putDeselectProduct(this.clientService.consultationId, sendObj).subscribe(data => {
        this.notifyMainComponents();
        this.doAction('cart-item', 'reload-cart');
      });
      this.doAction('overlay-cart', 'show-all-recommended', {
        allRecommendedChecked: false,
      });
    }
  }

  public showProductDetails(productId: string) {
    this.showDetails.emit(productId);
  }

  public showExtraProductDetails() {
    this.showExtraProducts.emit(this.compositionId);
  }

  public onDataFieldsClicked(productId: string) {
    this.showDataFields.emit(productId);
  }

  public async showDocument(document: MediaItem, event: Event): Promise<void> {
    event.preventDefault();
    this.documentService.showDocument(document);
  }

  public setProductQuantity(productId: string, quantity: any) {
    if (quantity === 0) {
      this.setProductSelectedState(productId, { checked: false });
      return;
    }

    this.quantityMap.set(productId, quantity);

    const sendObj = {
      compositionId: this.compositionId,
      productId: productId,
      quantity: quantity,
    } as SelectProductItem;

    this.queryService.putSelectProduct(this.clientService.consultationId, sendObj).subscribe(data => {
      this.notifyMainComponents();
      this.doAction('cart-item', 'reload-cart');
    });
    this.quantitySet.emit([productId, quantity]);
  }

  public isCustomOffer(paymentInterval: PaymentInterval): boolean {
    return paymentInterval === this.costTypeEnum.customOffer;
  }

  public isRecommended(data: ProductRecommendationItem): boolean {
    return data?.recommended || false;
  }

  public hasVisibleAdditionals(data: AdditionalProductRecommendationItem[]): boolean {
    return data.some(x => this.isVisibleAdditional(x));
  }

  public isVisibleAdditional(data: AdditionalProductRecommendationItem): boolean {
    return data?.selected || data?.recommended || false;
  }

  public hasDocuments(mediaDocuments: MediaItem[]): boolean {
    return mediaDocuments && mediaDocuments.filter(x => x.type !== MediaTypeEnum.image).length > 0;
  }

  public onlyDocuments(mediaDocuments: MediaItem[]): MediaItem[] {
    return mediaDocuments.filter(x => x.type !== MediaTypeEnum.image);
  }

  public hasDataField(dataFieldStatus: DataFieldStatusEnum): boolean {
    return !dataFieldStatus ? false : dataFieldStatus !== DataFieldStatusEnum.none;
  }

  public getPrice(data: ProductRecommendationItem): string {
    if (!this.quantityMap.has(data.id)) {
      const value = data.selected
        ? data.selectedQuantity === 0
          ? data.quantityDefaultValue
          : data.selectedQuantity
        : 0;
      this.quantityMap.set(data.id, value);
    }

    if (!data.selected) {
      return '';
    }

    if (!data.runningCost && !!data.fixCost) {
      if (data.fixCost.paymentInterval === PaymentInterval.customOffer) {
        return 'Individuelles Angebot';
      }
      const value = this.quantityMap.get(data.id) * data.fixCost.price;
      const currency = this.cp.transform(value, 'EUR', 'symbol', '1.2-2', 'de');
      return `${currency} einm.`;
    } else if (!!data.runningCost) {
      const value = this.quantityMap.get(data.id) * data.runningCost.price;
      const currency = this.cp.transform(value, 'EUR', 'symbol', '1.2-2', 'de');
      const frequency =
        data.runningCost.paymentInterval === PaymentInterval.annually
          ? 'j.'
          : data.runningCost.paymentInterval === PaymentInterval.monthly
            ? 'mtl.'
            : '';
      return `${currency} ${frequency}`;
    }
    return '';
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  get hasVariants(): boolean {
    return this.mainData.variants.length > 0;
  }

  get hasAdditionals(): boolean {
    return this.mainData.additionals.length > 0;
  }

  get description() {
    return this.mainData?.description?.replace(/(<([^>]+)>)/gi, '').trim() || '';
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private notifyMainComponents() {
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }
    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
