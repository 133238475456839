<marginals-header></marginals-header>

<div class="boxed-content w-header-w-footer d-flex flex-column flex-scroll flex-grow-1">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <kf-headline
            [headline]="'Thema abgeschlossen'"
            [showBackButton]="false"
            [overline]="lastTopic"
            (onBackButtonClick)="backToSubtopic()"
          ></kf-headline>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <div class="d-flex">
            @if (consultationType !== 'prep') {
              <ng-template *ngTemplateOutlet="functions"></ng-template>
            }
          </div>
          <div class="d-flex w-100">
            <div class="w-100 d-flex flex-column pt-3">
              <item-cart
                *ngIf="!isLastConsultantTopic && prepDividerType === 'none'; else lastConsultantTopic"
                class="d-flex flex-column flex-scroll"
                [cartType]="'summary'"
                [subtopicId]="subtopicSummaryId"
                (scrolled)="cartScrolled = $event"
              ></item-cart>
            </div>
          </div>
        </kf-tile>
      </div>
    </div>
  </div>
</div>

<ng-template #lastConsultantTopic>
  <h2 class="mr-3 mb-4">
    {{ lastConsultantTopicText }}
  </h2>
  <bgzv-image
    class="finish-preparation-icon mb-4"
    [alt]="'illustration'"
    [src]="assetPath + '/images/illu_zahnrad_duotone.svg'"
    aria-hidden="true"
  ></bgzv-image>
  <div class="d-flex justify-content-end">
    <button class="mt-4" mat-flat-button color="primary" (click)="onBackToOverviewClick()">
      <span>Zurück zur Themenauswahl</span>
    </button>
  </div>
</ng-template>

<ng-template #functions>
  <div class="w-100 d-flex justify-content-end">
    <button
      [attr.data-testcafe]="'summary-button-individualTasks'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTask()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Maßnahme</span>
    </button>

    <button
      class="ml-3"
      [attr.data-testcafe]="'summary-button-individualTransitions'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTransition()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Expertengespräch</span>
    </button>

    <button
      mat-stroked-button
      class="ml-3"
      [attr.data-testcafe]="'summary-button-allRecommendations'"
      (click)="selectAllRecommended($event)"
      *ngIf="!allRecommendedChecked"
    >
      <kf-icon name="ic_haken_24"></kf-icon>
      <span>Alle Empfehlungen auswählen</span>
    </button>
  </div>
</ng-template>
