import { Injectable } from '@angular/core';
import {
  ItemStatus,
  PanelAction,
  PanelConfig,
  SectionItem,
  Tool,
  ToolSection,
} from '@de.fiduciagad.kbm/shared-panel-lib';
import {
  SubtopicOverviewItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { RoutingPathMain, TopicStatusEnum } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { TopicService } from '@services/topic-service/topic-service';
import { baseToolConfig, emptyToolConfig } from '@utils/panel-config';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedPanelService {
  public panelConfigChanged = new BehaviorSubject<PanelConfig>(null);
  public panelToolConfigChanged = new BehaviorSubject<Tool>(null);
  public panelOpen = new BehaviorSubject<boolean>(false);
  public panelActionDispatched = new Subject<any>();
  public isManagedPanel = false;

  private _currentSubtopicId: string = '-1';

  constructor(
    private clientService: ClientService,
    private topicService: TopicService,
    private configService: ConfigService,
    private queryService: QueryService,
    private contextService: ContextService,
    private actionService: ActionService
  ) {}

  public submitPanelToolConfig(toolConfig: Tool): void {
    const currentToolConfig = JSON.stringify(this.panelToolConfigChanged.getValue());
    const newToolConfig = JSON.stringify(toolConfig);

    if (currentToolConfig !== newToolConfig) {
      this.panelToolConfigChanged.next(JSON.parse(JSON.stringify(toolConfig)));
    }
  }

  public handlePanelAction(action: PanelAction): void {
    this.panelActionDispatched.next(action);
  }

  public async updatePanelConfig(consultationId: string = null, subtopicId: string = null): Promise<void> {
    const currentConsultationId = consultationId || this.clientService.consultationId;
    const subtopics = await lastValueFrom(this.queryService.getSubtopicDataByConsultationId(currentConsultationId));
    this.topicService.subtopicData = subtopics;

    this.updatePanelConfigBySubtopicId(subtopicId);

    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.actionService.setAction({ target: 'topic-summary', action: 'update-next-subtopic', options: subtopicId });
    }

    this._currentSubtopicId = subtopicId;
  }

  public updatePanelConfigBySubtopicId(subtopicId: string = null): void {
    const panelToolConfig = this.panelConfigFromSubtopicResponse(this.topicService.subtopicData, subtopicId);
    this.submitPanelToolConfig(panelToolConfig);
  }

  public clearBgzvContent(): void {
    const bgzvEmptyPanelConfig = cloneDeep(emptyToolConfig);
    bgzvEmptyPanelConfig.id = `bgzv-tool-${this.clientService.consultationId}`;
    bgzvEmptyPanelConfig.title = this.clientService.instanceName || 'Beratungsthemen';
    this.panelToolConfigChanged.next(bgzvEmptyPanelConfig);
  }

  public panelConfigFromTopicResponse(topicResponse: TopicSelectionResponse, currentSubtopicId: string = '-1'): Tool {
    const selectedSubtopics = topicResponse.selected.flatMap(x => x.subtopics).filter(x => x.selected);
    return this.panelConfigFromSubtopicData(selectedSubtopics, currentSubtopicId);
  }

  public panelConfigFromTopicOverviewItems(
    topicOverviewItems: TopicOverviewItem[],
    currentSubtopicId: string = '-1'
  ): Tool {
    const selectedSubtopics = topicOverviewItems.filter(x => x.selected);
    return this.panelConfigFromSubtopicData(selectedSubtopics, currentSubtopicId);
  }

  public panelConfigFromSubtopicResponse(
    subtopicResponse: SubtopicSelectionResponse,
    currentSubtopicId: string = '-1'
  ): Tool {
    const selectedSubtopics = subtopicResponse.selected.filter(x => x.selected);
    return this.panelConfigFromSubtopicData(selectedSubtopics, currentSubtopicId);
  }

  private panelConfigFromSubtopicData(subtopicData: SubtopicOverviewItem[], currentSubtopicId: string): Tool {
    subtopicData = subtopicData.sort((a, b) => a.orderNumber - b.orderNumber);
    const statusMap: Record<string, ItemStatus> = {
      [TopicStatusEnum.done]: ItemStatus.DONE,
      [TopicStatusEnum.inProgress]: ItemStatus.IN_PROGRESS,
      [TopicStatusEnum.open]: ItemStatus.OPEN,
    };

    const sectionItemFactory = (subtopics: SubtopicOverviewItem[]): SectionItem[] => {
      return subtopics.map(subtopic => {
        return {
          id: subtopic.id,
          title: subtopic.title,
          name: subtopic.title,
          status: statusMap[subtopic.status],
          isOpened: subtopic.id === currentSubtopicId,
          owner: 'bgzv',
          clickActionId: `bgzv-subtopic-select`,
        };
      });
    };

    const bgzvPanelConfig: Tool = cloneDeep(baseToolConfig);
    bgzvPanelConfig.id = `bgzv-tool-${this.clientService.consultationId}`;
    bgzvPanelConfig.title = this.clientService.instanceName || 'Beratungsthemen';
    bgzvPanelConfig.showEditButton = this.startingPointMainConsultation ? false : true;

    // SECTION 'Beratungsthemen'
    const consultationTopicsToolSection: ToolSection = {
      id: `bgzv-consultation-topics-${this.clientService.consultationId}`,
      owner: 'bgzv',
      title: 'Beratungsthemen',
      showSendButton: false,
      sendActionId: '',
      items: sectionItemFactory(subtopicData.filter(x => !x.consultantSubtopic && !x.availableForPreliminary)),
    };
    if (consultationTopicsToolSection.items.length > 0) {
      bgzvPanelConfig.toolSections.push(consultationTopicsToolSection);
    }

    // SECTION 'Vorbereitung Berater'
    const consultantTopicsToolSection: ToolSection = {
      id: `bgzv-consultant-topics-${this.clientService.consultationId}`,
      owner: 'bgzv',
      title: 'Vorbereitung Berater',
      showSendButton: false,
      sendActionId: '',
      items: sectionItemFactory(subtopicData.filter(x => x.consultantSubtopic)),
    };
    if (consultantTopicsToolSection.items.length > 0) {
      bgzvPanelConfig.toolSections.push(consultantTopicsToolSection);
    }

    // SECTION 'Vorbereitung Kunde'
    const preliminaryTopicsToolSection: ToolSection = {
      id: `bgzv-preliminary-topics-${this.clientService.consultationId}`,
      owner: 'bgzv',
      title: 'Vorbereitung Kunde',
      showSendButton: true,
      sendActionId: 'bgzv-preliminary-send',
      items: sectionItemFactory(subtopicData.filter(x => x.availableForPreliminary)),
    };
    if (preliminaryTopicsToolSection.items.length > 0) {
      bgzvPanelConfig.toolSections.push(preliminaryTopicsToolSection);
    }

    return bgzvPanelConfig;
  }

  get currentSubtopicId(): string {
    return this._currentSubtopicId;
  }

  get startingPointMainConsultation(): boolean {
    return this.configService.startingPointMainConsultation && this.contextService.currentMode === 'main';
  }
}
