<div style="width: calc(100vw - 400px)">
  <!-- HEADER -->
  <div class="pl-4 py-4 border-bottom" style="min-height: 8rem">
    <h2 class="mb-2">
      Notizen & Dokumente ({{
        numberOfElements === numberOfSelectedElements
          ? numberOfElements
          : numberOfSelectedElements + ' / ' + numberOfElements
      }})
    </h2>
    <div>
      {{
        numberOfElements > 0
          ? 'Ausgewählte Notizen und Dokumente werden dem Bestelldokument angehängt.'
          : 'Für diese Beratung wurden keine Notizen oder Dokumente erstellt.'
      }}
    </div>
  </div>

  <div class="d-flex pl-4" style="min-height: calc(100vh - 8rem); max-height: calc(100vh - 8rem)">
    <side-notes-menu
      class="d-flex w-25"
      [class.border-right]="numberOfElements > 0"
      [class.side-footer-margin]="useMargin"
      (numberOfElements)="getNumberOfElements($event)"
      (numberOfSelectedElements)="getNumberOfSelectedElements($event)"
      (note)="onOpenNote($event)"
      (document)="onOpenDocument($event)"
      cdkFocusInitial
    ></side-notes-menu>

    <!-- NOTE / DOCUMENT VIEWER -->
    <div class="d-flex flex-column flex-scroll mt-3 px-3 w-75" [class.side-footer-margin]="useMargin">
      @if (currentNote) {
        <h2 class="d-flex flex-column mb-2 pl-2 text-break">
          {{ displayName }}
        </h2>
      }
      @if (currentNote && type === noteTypeEnum.drawing) {
        <div class="d-flex flex-column pl-2" [innerHTML]="drawing"></div>
      }
      @if (currentNote && type === noteTypeEnum.text) {
        <div
          class="d-flex flex-column pl-2"
          style="hyphens: auto; overflow-wrap: break-word; white-space: pre-line"
          [innerHTML]="text"
        ></div>
      }
      @if (currentDocument) {
        <div class="d-flex flex-column pl-2 app-height">
          <documents-viewer #documentViewRef [inCheckout]="true"></documents-viewer>
        </div>
      }
    </div>
  </div>

  <button
    [attr.data-testcafe]="'sideNotes-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
    (click)="handleClose()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</div>
