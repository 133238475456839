<div
  class="d-flex align-items-center justify-content-between subtopic-deselect-container pl-3"
  cdkDragLockAxis="y"
  cdkDrag
  tabindex="0"
>
  @if (!loading) {
    <mat-checkbox
      [attr.data-testcafe]="'deselect-button-' + getTestcafeId(subtopicItem.title) + '-' + subtopicItem.id"
      [checked]="true"
      tabindex="-1"
      [color]="'grey'"
      (click)="onSubtopicDeselected()"
      class="mb-0 force-center-checkbox d-flex"
    >
      @if (getMediaUrl(subtopicItem.icon?.id)) {
        <bgzv-image
          class="flex-shrink-0 subtopic-icon mx-2 align-self-center"
          [alt]=""
          [src]="getMediaUrl(subtopicItem.icon?.id)"
          aria-hidden="true"
        ></bgzv-image>
      }
      <span class="flex-grow-1">{{ truncateText(subtopicItem.title, 135) }}</span>
    </mat-checkbox>
  } @else {
    <mat-spinner [diameter]="24"></mat-spinner>
  }

  @if (!getMediaUrl(subtopicItem.icon?.id)) {
    <div class="subtopic-icon mx-2" aria-hidden="true"></div>
  }

  <div class="move-handler" cdkDragHandle tabindex="-1">
    <kf-icon class="drag-icon-margin" name="ic_bewegen_24"></kf-icon>
  </div>
</div>
