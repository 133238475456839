<div class="d-flex flex-column side-container">
  <div class="detail-description" [innerHTML]="contentElementData?.description"></div>

  @if (contentElementData?.selected) {
    <div class="tag-info tag-positive mb-3">
      <div class="tag-info-text">Im Lösungskorb</div>
    </div>
  }
  @if (contentElementData?.selected && contentElementData?.customerContact) {
    <div class="tag-info tag-neutral mb-3">
      <div class="tag-info-text">
        Verantwortlich: {{ contentElementData?.customerContact.formOfAddress }}
        {{ contentElementData?.customerContact.firstName }}
        {{ contentElementData?.customerContact.lastName }}
      </div>
    </div>
  }
</div>

<mat-divider></mat-divider>

<footer class="px-4 py-3 d-flex justify-content-end" [class.side-footer-margin]="useMargin">
  @if (contentElementData?.type === recommendationTypeEnum.subtopic) {
    <button mat-stroked-button class="ml-3" color="primary" (click)="addSubtopicToAgenda()">
      Zur aktuellen Beratung hinzufügen
    </button>
  }
  @if (contentElementData?.selected) {
    <button
      mat-stroked-button
      class="ml-3"
      color="primary"
      [attr.data-testcafe]="'sideContentElement-button-assigned'"
      (click)="toggleContextMode()"
    >
      <kf-icon name="ic_loeschen_24"></kf-icon>
      <span>Entfernen</span>
    </button>

    @if (contentElementData?.customerContact) {
      <button
        mat-flat-button
        class="ml-3"
        color="primary"
        [attr.data-testcafe]="'sideContentElement-button-assigned'"
        (click)="changePanelState.emit(panelStateEnum.assign)"
      >
        <kf-icon name="ic_personen_paar_gepunktet_24"></kf-icon>
        <span>Zuweisung ändern</span>
      </button>
    } @else {
      <button
        mat-flat-button
        class="ml-3"
        color="primary"
        [attr.data-testcafe]="'sideContentElement-button-assignContentElement'"
        (click)="changePanelState.emit(panelStateEnum.assign)"
      >
        <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
        <span>Zuweisen</span>
      </button>
    }
  } @else {
    <button
      mat-flat-button
      class="ml-3"
      color="primary"
      [attr.data-testcafe]="'sideContentElement-button-addToCart'"
      (click)="selectContentElement()"
    >
      <kf-icon name="ic_einkaufswagen_24"></kf-icon>
      <span>Hinzufügen</span>
    </button>
  }
</footer>
