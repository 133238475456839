import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { CompositionChildResponse } from '@domain/app/composition.domain';
import { MediaItem } from '@domain/app/media.domain';
import { MainProductRecommendationItem } from '@domain/app/product.domain';
import { DeselectTaskItem, SelectTaskItem, TaskRecommendationItem } from '@domain/app/task.domain';
import { RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { formatContact } from '@utils/helpers/formatContact';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'item-composition-task',
  templateUrl: './item-composition-task.component.html',
  styleUrls: ['./item-composition-task.component.scss', '../item-cart/item-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCompositionTaskComponent implements OnInit, OnDestroy {
  @Input() mainData: MainProductRecommendationItem;
  @Input() taskData: TaskRecommendationItem;
  @Input() compositionId?: string;
  @Input() compositionMedia: MediaItem;
  @Input() subtopicId?: string;
  @Input() showMainDetails?: boolean = false;
  @Input() isIndividualItem?: boolean = false;
  @Input() showIndividualGroupHeading?: boolean = false;
  @Output() showDetails = new EventEmitter<any>();
  @Output() showTask = new EventEmitter<any>();
  @Output() taskAssigned = new EventEmitter<boolean>();
  @Output() taskUpdated = new EventEmitter<boolean>();

  private destroySubs = new Subject<void>();

  public loading = false;

  public formatContact = formatContact;

  readonly moment = moment;
  readonly isProd = environment.production;

  constructor(
    private clientService: ClientService,
    private contextService: ContextService,
    private queryService: QueryService,
    private actionService: ActionService,
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
    this.taskData.description = this.taskData?.description?.replace(/(<([^>]+)>)/gi, '').trim() || '';
    this.chg.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public showProductDetails(productId): void {
    this.showDetails.emit(productId);
  }

  public onBadgeClicked(type: string, id: string, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (type === 'product') {
      this.showProductDetails(id);
    }
  }

  public openTaskAssignment(taskId: string, skipSelection: boolean, selected: boolean): void {
    const sendObj: CompositionChildResponse = {
      elementId: taskId,
      skipSelection: skipSelection,
      selected: selected,
      mainData: this.mainData,
    };
    this.showTask.emit(sendObj);
  }

  public selectTaskText(taskData: TaskRecommendationItem, box: MatCheckbox): void {
    box.checked = !box.checked;
    this.selectTask({ checked: box.checked }, taskData);
  }

  public selectTask(checkbox: MatCheckboxChange | { checked: boolean }, taskData: TaskRecommendationItem): void {
    if (!this.compositionId) {
      checkbox.checked = true;
      return;
    }
    if (checkbox.checked) {
      const sendObj: SelectTaskItem = {
        compositionId: this.compositionId,
        taskId: taskData.id,
        subtopicId: this.subtopicId || null,
      };
      if (this.isIndividualTask) {
        this.queryService.putSelectIndividualTask(this.clientService.consultationId, sendObj.taskId).subscribe(data => {
          this.notifyMainComponents();
          this.taskAssigned.emit(true);
        });
      } else {
        this.queryService.putSelectTask(this.clientService.consultationId, sendObj).subscribe(data => {
          this.notifyMainComponents();
          this.taskAssigned.emit(true);
        });
      }
    } else {
      const sendObj: DeselectTaskItem = {
        compositionId: this.compositionId,
        taskId: taskData.id,
        target: 'RECOMMENDED',
      };

      if (this.isIndividualTask) {
        this.queryService
          .putDeselectIndividualTask(this.clientService.consultationId, sendObj.taskId)
          .subscribe(data => {
            this.notifyMainComponents();
            this.taskAssigned.emit(false);
          });
      } else {
        this.queryService.putDeselectTask(this.clientService.consultationId, sendObj).subscribe(data => {
          this.notifyMainComponents();
          this.taskAssigned.emit(false);
        });

        this.doAction('overlay-cart', 'show-all-recommended', {
          allRecommendedChecked: false,
        });
      }
    }
  }

  public isRecommended(data: TaskRecommendationItem): boolean {
    return data?.recommended === true || false;
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  get isIndividualTask(): boolean {
    return this.compositionId === '-1';
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private notifyMainComponents() {
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }
    if (this.contextService.currentMainContext.includes(RoutingPathMain.TopicSummary)) {
      this.doAction('cart-item', 'reload-summary');
    }
    if (this.isIndividualTask) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
