@if (!subtopicData || subtopicData?.length === 0) {
  <div class="border result-subtopics">
    <div class="pl-4 pr-3 pt-3">
      <strong>Worüber haben wir gesprochen?</strong>
      <p class="pl-3">Für diese Beratung wurden keine Themen gewählt.</p>
    </div>
  </div>
} @else {
  <div class="border position-relative result-subtopics" [class.can-receive-hover]="!panelOpenState">
    <mat-expansion-panel
      class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      collapsedHeight="*"
      expandedHeight="*"
      [hideToggle]="false"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      [class.is--closed]="!panelOpenState"
      #subtopicsPanel
    >
      <mat-expansion-panel-header>
        <mat-panel-title><strong class="font-weight-bold">Worüber haben wir gesprochen?</strong></mat-panel-title>
      </mat-expansion-panel-header>

      <div class="d-flex justify-content-center">
        <div class="h-100 subtopics">
          <mat-radio-group class="kf-toggle-l kf-toggle-vertical">
            @for (subtopic of subtopicData; track subtopic) {
              <mat-radio-button
                class="kf-toggle-l-i"
                (click)="openQuestions(subtopic)"
                [class.kf-toggle-l-i-selected]="questionGroups === subtopic.questionGroups"
              >
                <div class="mat-body-2 font-weight-bold">{{ subtopic.name }}</div>
              </mat-radio-button>
            }
          </mat-radio-group>
        </div>
        <div class="question-groups pl-4">
          @if (questionGroups) {
            @for (questionGroup of questionGroups; track questionGroup; let isFirst = $first; let i = $index) {
              <mat-expansion-panel
                [expanded]="isFirst"
                (opened)="questionGroupPanelOpen[i] = true"
                (closed)="questionGroupPanelOpen[i] = false"
                class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title
                    [attr.data-testcafe]="'screenResult-panel-questionGroup-' + getTestcafeId(questionGroup.name)"
                    ><strong class="font-weight-bold">{{ questionGroup.name }}</strong></mat-panel-title
                  >
                </mat-expansion-panel-header>
                <ol class="pt-2 mr-3 questions">
                  @for (question of questionGroup.questions; track question) {
                    @if (question.givenAnswers.length > 0) {
                      <li>
                        <article class="question-answers">
                          <div
                            class="question"
                            [attr.data-testcafe]="'screenResult-div-question-' + getTestcafeId(question.displayName)"
                          >
                            {{ formatDisplayedValue(question.displayName) }}
                          </div>
                          <section class="answers">
                            @for (givenAnswer of question.givenAnswers; track givenAnswer) {
                              <div class="answer">
                                <div class="d-flex align-items-center">
                                  <kf-icon name="ic_haken_24" class="answer-given mr-2"></kf-icon>
                                  <span
                                    class="pl-2"
                                    [attr.data-testcafe]="
                                      'screenResult-div-givenAnswer-' + getTestcafeId(givenAnswer.value)
                                    "
                                    [innerHTML]="formatDisplayedValue(givenAnswer.value) | safeHtml"
                                  ></span>
                                  @if (!givenAnswer.value || givenAnswer.value.length < 1) {
                                    <span
                                      [attr.data-testcafe]="
                                        'screenResult-div-givenAnswer-' + getTestcafeId(givenAnswer.value)
                                      "
                                      >Keine Angabe</span
                                    >
                                  }
                                </div>

                                @if (givenAnswer.note) {
                                  ><span
                                    class="w-100 mat-small"
                                    [attr.data-testcafe]="
                                      'screenResult-div-givenAnswerNote-' + getTestcafeId(givenAnswer.note)
                                    "
                                    >{{ givenAnswer.note }}</span
                                  >
                                }
                              </div>
                            }
                          </section>
                        </article>
                      </li>
                    }
                  }
                </ol>
              </mat-expansion-panel>
            }
          }
        </div>
      </div>
    </mat-expansion-panel>
    @if (!panelOpenState) {
      <ul class="w-100 list-inline mb-0 pr-4 py-2 pl-4 position-relative">
        @for (subtopic of subtopicData; track subtopic; let last = $last) {
          <li class="list-inline-item">
            <div class="d-flex line-height-1">
              <button mat-button color="primary" (click)="openQuestions(subtopic); $event.preventDefault()">
                <span>{{ subtopic.name }}</span>
              </button>
            </div>
          </li>
        }
      </ul>
    }
  </div>
}
