<div
  class="d-flex align-items-center kf-bg w-100 flex-grow header-height px-4"
  [class.bottom-divider]="showBottomDivider"
  [attr.data-html2canvas-ignore]="true"
>
  <!-- ------ CLIENT INFO on AWS ------ -->
  <button
    [attr.data-testcafe]="'screenClientConsultations-div-backToCustomers'"
    *ngIf="showClientListButton"
    mat-button
    color="primary"
    (click)="onClientListClick()"
  >
    <kf-icon name="ic_pfeil_links_24"></kf-icon>
    <span>Zurück zu meinen Kunden</span>
  </button>

  <button
    *ngIf="showWelcomeButton"
    [attr.data-testcafe]="'screenClientList-button-backToWelcome'"
    mat-button
    color="primary"
    (click)="onClientConsultationsClick()"
  >
    <kf-icon name="ic_pfeil_links_24"></kf-icon>
    <span>Zurück zur Beratungsübersicht</span>
  </button>

  <!-- ------ CLIENT INFO on AWS ------ -->
  <button
    class="mr-1"
    [attr.data-testcafe]="'header-button-home'"
    *ngIf="showHomeButton"
    mat-button
    color="primary"
    (click)="onHomeClick()"
  >
    <kf-icon name="ic_personen_paar_gepunktet_24"></kf-icon>
    <span>Kundenauswahl</span>
  </button>

  <button
    class="mr-1"
    [attr.data-testcafe]="'header-button-overview'"
    *ngIf="showAgendaButton"
    mat-button
    color="primary"
    (click)="onAgendaClick()"
  >
    <kf-icon
      [name]="quickstartMode || startingPointMainConsultation ? 'ic_personen_paar_gepunktet_24' : 'ic_haus_24'"
    ></kf-icon>
    <span>{{ quickstartMode || startingPointMainConsultation ? 'Kundenauswahl' : 'Beratungsthemen' }}</span>
  </button>

  @if (loading) {
    <mat-spinner [diameter]="24" class="mx-4"></mat-spinner>
  }

  <div class="d-flex flex-grow-1"></div>

  @if (showMenuButtons) {
    @if (showChat) {
      <button
        [attr.data-testcafe]="'header-button-chat'"
        class="mr-3"
        (click)="onOpenChat()"
        aria-label="Nachrichten"
        kfTooltip="Nachrichten öffnen"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
        mat-icon-button
      >
        <kf-icon name="ic_sprechblase_24"></kf-icon>
      </button>
    }

    @if (showBasketButton) {
      <button
        [attr.data-testcafe]="'header-button-search'"
        class="mr-3"
        iconName="ic_suchen_24"
        (click)="onOpenSearch()"
        aria-label="Suche"
        kfTooltip="Suche öffnen"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
        mat-icon-button
      >
        <kf-icon name="ic_suchen_24"></kf-icon>
      </button>
    }

    <!-- BASKET, NOTES, and PROFILE BUTTONS -->
    @if (!indicatorVisible) {
      <button
        class="mr-3"
        iconName="ic_papier_mit_stift_24"
        (click)="onOpenNotes()"
        aria-label="Notizen & Dokumente"
        kfTooltip="Notizen und Dokumente öffnen"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
        mat-icon-button
      >
        <kf-icon name="ic_papier_mit_stift_24"></kf-icon>
      </button>
    } @else {
      <button
        class="mr-3"
        (click)="onOpenNotes()"
        matBadge="&#8288;"
        matBadgePosition="above after"
        matBadgeColor="primary"
        matBadgeOverlap="true"
        matBadgeSize="small"
        aria-label="Notizen & Dokumente"
        kfTooltip="Notizen und Dokumente öffnen"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
        mat-icon-button
      >
        <kf-icon name="ic_papier_mit_stift_24"></kf-icon>
      </button>
    }

    <button
      [attr.data-testcafe]="'header-button-profile'"
      class="mr-3"
      (click)="onOpenProfile()"
      aria-label="Steckbrief"
      kfTooltip="Steckbrief öffnen"
      kfTooltipPosition="below"
      kfTooltipShow="hover"
      [kfTooltipHideOnInside]="true"
      [kfTooltipHideOnOutside]="true"
      mat-icon-button
    >
      <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
    </button>

    @if (showBasketButton && contextMode === 'main') {
      <button
        [attr.data-testcafe]="'header-button-cart'"
        class="mr-3"
        iconName="ic_einkaufswagen_24"
        (click)="onOpenCart()"
        [matBadge]="cartNumber"
        matBadgePosition="above after"
        matBadgeColor="primary"
        matBadgeOverlap="true"
        matBadgeSize="small"
        aria-label="Lösungskorb"
        kfTooltip="Lösungskorb öffnen"
        kfTooltipPosition="below"
        kfTooltipShow="hover"
        [kfTooltipHideOnInside]="true"
        [kfTooltipHideOnOutside]="true"
        mat-icon-button
      >
        <kf-icon name="ic_einkaufswagen_24"></kf-icon>
      </button>
    }
  }

  @if (showStatusBadge) {
    <!-- Status: CONSULTATION OR PREPARATION -->
    <div class="tag-info tag-neutral ml-3" [class.mr-3]="showStickyButton">
      <div class="tag-info-text">{{ status }}</div>
    </div>
  }

  @if (platform === 'aws' && showLogoutButton) {
    <button [attr.data-testcafe]="'header-button-logout'" mat-button color="primary" (click)="onLogout()">
      <kf-icon name="ic_abmelden_24"></kf-icon>
      <span>Ausloggen</span>
    </button>
  }

  @if (showStickyButton) {
    <item-sticky-button class="pl-3 border-left"></item-sticky-button>
  }
</div>
