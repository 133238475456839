import { NgModule } from '@angular/core';
import { KfThemeChipsModule } from '@de.fiduciagad.kundenportal/kf-theme/chips';
import { KFThemeCommonModule } from '@de.fiduciagad.kundenportal/kf-theme/common';
import { KfCompactButtonModule } from '@de.fiduciagad.kundenportal/kf-theme/compact-button';
import { KFDocumentTileModule } from '@de.fiduciagad.kundenportal/kf-theme/document-tile';
import { KfThemeHeadlineModule } from '@de.fiduciagad.kundenportal/kf-theme/headline';
import { KFIconModule } from '@de.fiduciagad.kundenportal/kf-theme/icon';
import { KFImgModule } from '@de.fiduciagad.kundenportal/kf-theme/image';
import { KFInputErrorModule } from '@de.fiduciagad.kundenportal/kf-theme/input-error';
import { KfMessageBoxComponent } from '@de.fiduciagad.kundenportal/kf-theme/message-box';
import { KfThemeNotificationModule } from '@de.fiduciagad.kundenportal/kf-theme/notification';
import { KFSelectModule } from '@de.fiduciagad.kundenportal/kf-theme/select';
import { KfTileModule } from '@de.fiduciagad.kundenportal/kf-theme/tile';
import { KFTooltipModule } from '@de.fiduciagad.kundenportal/kf-theme/tooltip';
@NgModule({
  imports: [KfMessageBoxComponent],
  exports: [
    KfCompactButtonModule,
    KFDocumentTileModule,
    KFIconModule,
    KFImgModule,
    KFInputErrorModule,
    KfThemeChipsModule,
    KFThemeCommonModule,
    KfThemeHeadlineModule,
    KfThemeNotificationModule,
    KfTileModule,
    KFSelectModule,
    KFTooltipModule,
    KFInputErrorModule,
    KfMessageBoxComponent,
  ],
})
export class KfThemeModule {}
