import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataFieldFormValueChange } from '@components/item-datafield-form/item-datafield-form.component';
import {
  CheckoutDataFieldCompositionItem,
  CheckoutDataFieldGroupItem,
  CheckoutDataFieldUpdateRequest,
} from '@domain/app/checkout.domain';
import { PutNewTaskDataFieldGroupRequest } from '@domain/app/datafield.domain';
import { DataFieldElementTypeEnum, DataFieldTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'side-checkout',
  templateUrl: './side-checkout.component.html',
  styleUrls: ['./side-checkout.component.scss'],
})
export class SideCheckoutComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() inputValues: any;
  @Output() closed = new EventEmitter(null);

  private destroySubs = new Subject<void>();
  private scrolledToInvalidField = false;

  public dataFieldData: CheckoutDataFieldCompositionItem;
  public requestInProgress = false;

  readonly dataFieldType = DataFieldTypeEnum;
  readonly dataFieldElementType = DataFieldElementTypeEnum;

  constructor(
    private clientService: ClientService,
    private queryService: QueryService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService
  ) {}

  ngOnInit(): void {
    this.queryDataFieldData();

    this.rightSidenavService.instance._animationStarted.pipe(takeUntil(this.destroySubs)).subscribe(x => {
      if (x.fromState === 'open' && x.toState === 'void') {
        this.closed.emit();
        this.rightSidenavService.emitClosed();
      }
    });
  }

  ngAfterViewChecked(): void {
    const invalidField = document.querySelector('kf-input-error');
    if (invalidField && !this.scrolledToInvalidField) {
      this.scrolledToInvalidField = true;
      setTimeout(() => {
        invalidField.scrollIntoView({
          behavior: 'smooth',
        });
      }, 500);
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onClose(): void {
    this.rightSidenavService.close(true);
  }

  public onDataSaved(): void {
    this.onClose();
  }

  public handleDataFormValueChange(event: DataFieldFormValueChange): void {
    // send this to the backend, and request new data;
    const sendObj = {
      dataFieldValueId: event.item.dataFieldValueId,
      value: event.changedValue || '',
    } as CheckoutDataFieldUpdateRequest;
    this.requestInProgress = true;

    this.queryService
      .putCheckoutDataFieldData(this.clientService.consultationId, sendObj, event.item.elementType)
      .subscribe(x => {
        if (x.hiddenChanged) {
          this.queryDataFieldData();
        } else {
          this.requestInProgress = false;
        }
      });
  }

  public addDataField(groupItem: CheckoutDataFieldGroupItem): void {
    const sendObj: PutNewTaskDataFieldGroupRequest = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.elementId,
      dataFieldGroupId: groupItem.dataFieldGroupId,
    };
    this.requestInProgress = true;

    this.queryService.putAddNewTaskDataFieldGroup(this.clientService.consultationId, sendObj).subscribe(x => {
      this.queryDataFieldData();
    });
  }

  public removeDataField(groupItem: CheckoutDataFieldGroupItem): void {
    const sendObj: PutNewTaskDataFieldGroupRequest = {
      compositionId: this.inputValues.compositionId,
      taskId: this.inputValues.elementId,
      dataFieldGroupId: groupItem.dataFieldGroupId,
    };
    this.requestInProgress = true;

    this.queryService.putRemoveTaskDataFieldGroup(this.clientService.consultationId, sendObj).subscribe(x => {
      this.queryDataFieldData();
    });
  }

  public getHeader(): string {
    if (this.inputValues.type === DataFieldElementTypeEnum.general) {
      return `<h2>${this.dataFieldData?.elementName}</h2>`;
    } else {
      return `<h2>${this.dataFieldData?.compositionName}</h2>
      <p>${this.dataFieldData?.elementName}</p>`;
    }
  }

  private queryDataFieldData(): void {
    this.queryService
      .getCheckoutDataFieldData(
        this.clientService.consultationId,
        this.inputValues.type,
        this.inputValues.compositionId,
        this.inputValues.elementId
      )
      .pipe(finalize(() => (this.requestInProgress = false)))
      .subscribe(data => {
        this.dataFieldData = data;
      });
    this.dataFieldData?.dataFieldGroups
      .filter(x => !x.general)
      .sort((a, b) => (a.ordinal < b.ordinal ? -1 : a.ordinal > b.ordinal ? 1 : 0));
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
