<div class="d-flex flex-column menu-product-world-column">
  @if (data) {
    <h3 class="mat-body-1 font-weight-bold mt-5 mb-4">
      {{ searchQuery ? title : 'Favoriten ' + title }}
    </h3>
    @for (element of data; track element) {
      <button class="mb-2 questionBorder border-shadow kf-color-primary kf-bg" (click)="handleClick(element)">
        <div class="recommendation-container mb-3">
          {{ getName(element) }}
        </div>
      </button>
    } @empty {
      <p class="mt-3">Keine passende Lösung gefunden.</p>
    }
  }
</div>
