import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { ActionService } from '@services/action-service/action.service';
import { ContextService } from '@services/context-service/context.service';
import { SearchService } from '@services/search-service/search.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { baseConfig, buttonAgenda } from '@utils/footer-config';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'overlay-search-menu',
  templateUrl: './overlay-search-menu.component.html',
  styleUrls: ['./overlay-search-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlaySearchMenuComponent implements OnInit {
  searchInput!: ElementRef<HTMLInputElement>;

  @ViewChild('searchInput') set inputElRef(elRef: ElementRef<HTMLInputElement>) {
    if (elRef) {
      this.searchInput = elRef;
    }
  }

  private _dataFetched = false;
  private _searchQuery = '';

  constructor(
    private readonly searchService: SearchService,
    private readonly changeDetector: ChangeDetectorRef,
    private actionService: ActionService,
    private contextService: ContextService,
    private footerService: SharedFooterService
  ) {}

  ngOnInit(): void {
    this.searchService.fetchData().subscribe(() => {
      this._dataFetched = true;
      this.changeDetector.detectChanges();
    });

    this.footerButtonsFactory();
  }

  public onClose() {
    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then();
  }

  private footerButtonsFactory() {
    const currentConfig = cloneDeep(baseConfig);

    if (!this.footerService.isManagedFooter) {
      currentConfig.left.elements = [buttonAgenda];
    }

    this.footerService.submitFooterConfig(currentConfig);
  }

  get dataFetched(): boolean {
    return this._dataFetched;
  }

  get displayMenuContent(): boolean {
    return (
      this.contextService.currentMainContext !== RoutingPathMain.ClientList &&
      this.contextService.currentMainContext !== RoutingPathMain.ClientConsultations
    );
  }

  get isInPrep(): boolean {
    return this.contextService.currentMode === 'prep';
  }

  set searchQuery(value: string) {
    this.searchService.searchQuery = value;
    this._searchQuery = value;
  }

  get searchQuery(): string {
    return this._searchQuery;
  }

  get awsPlatform(): boolean {
    return environment.platform === 'aws';
  }
}
