import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Profile } from '@domain/app/profile';
import {
  ExpandedTopic,
  SubtopicOverviewItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { ContextService } from '@services/context-service/context.service';
import { SearchService } from '@services/search-service/search.service';
import { TopicService } from '@services/topic-service/topic-service';
import { color } from '@utils/helpers/color';
import { uniqBy } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface SearchInterface {
  matching: TopicOverviewItem[];
  remainder: TopicOverviewItem[];
}

@Component({
  selector: 'section-topics',
  templateUrl: './section-topics.component.html',
  styleUrls: ['./section-topics.component.scss', '../screen-topic-select.component.scss'],
})
export class SectionTopicsComponent implements OnDestroy, OnChanges {
  @Input() data: TopicSelectionResponse | SubtopicSelectionResponse = { available: [], selected: [] };
  @Input() profileData: Profile.GetProfileResponse;
  @Input() updateInProgress: boolean;
  @Input() headline: string;

  @Output() topicSelected = new EventEmitter<TopicOverviewItem>();
  @Output() subtopicSelected = new EventEmitter<any>();
  @Output() subtopicDeselected = new EventEmitter<SubtopicOverviewItem>();
  @Output() subtopicsReordered = new EventEmitter<SubtopicOverviewItem[]>();
  @Output() topicDeselect = new EventEmitter<TopicOverviewItem>();
  @Input() expandedTopics: ExpandedTopic[];
  @Output() expandTopic = new EventEmitter<ExpandedTopic>();
  @Input() maximumBubbles: number;
  @Input() isPrelim: boolean;

  private destroySubs = new Subject<void>();
  private _searchQuery = '';
  private _step: number;

  public searchedData: SearchInterface = {
    matching: [],
    remainder: [],
  };

  readonly color = color;

  constructor(
    private searchService: SearchService,
    private contextService: ContextService,
    private topicService: TopicService
  ) {}

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.handleSearchData();
    }
  }

  public onTopicSelected(topic: TopicOverviewItem): void {
    this.topicSelected.emit(topic);
  }

  public onTopicDeselect(topic: TopicOverviewItem): void {
    this.topicDeselect.emit(topic);
  }

  public onSubtopicSelected(topic: TopicOverviewItem, subtopic: SubtopicOverviewItem): void {
    subtopic.icon = topic.icon;
    this.subtopicSelected.emit(subtopic);
  }

  public onSubtopicDeselected(subtopic: SubtopicOverviewItem): void {
    this.subtopicDeselected.emit(subtopic);
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.selected, event.previousIndex, event.currentIndex);
    this.subtopicsReordered.emit(this.data.selected);
  }

  public getIsExpandedTopic(topicId: string): boolean {
    const expObj = this.expandedTopics?.find(obj => obj.hasOwnProperty(topicId));
    return expObj ? expObj[topicId] : true;
  }

  public onTopicExpanded(topic: ExpandedTopic): void {
    this.expandTopic.emit(topic);
  }

  set searchQuery(value: string) {
    this._searchQuery = value;
    this.searchService.searchQuery = value;
  }

  get searchQuery() {
    return this._searchQuery;
  }

  get showDivider(): boolean {
    return this.searchedData.matching.length > 0 && this.searchedData.remainder.length > 0;
  }

  get selectedTopicsAmount(): number {
    return this.data?.selected?.length;
  }

  @Input()
  set step(step: number) {
    this._step = step + 1;
  }

  get step(): number {
    return this._step;
  }

  get stepsBubbles(): number[] {
    return Array.from(
      { length: Math.max(1, this.step > this.maximumBubbles + 1 ? this.maximumBubbles + 1 : this.step) },
      (_, i) => i + 1
    );
  }

  get emptyStepBubbles(): number[] {
    return Array.from({ length: this.maximumBubbles + 1 - this.step }, (_, i) => i + 1);
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  private handleSearchData(): void {
    if (!this.data) {
      return;
    }
    this.searchService.questionWorldData.pipe(takeUntil(this.destroySubs)).subscribe(questionData => {
      if (this.searchQuery === '') {
        const topics = this.topicService.subtopicData.available;
        this.searchedData.matching = topics;
        this.searchedData.remainder = [];
        return;
      }

      const s = questionData.topics.flatMap(x => x.subtopics)?.filter(x => questionData.subtopicHasSearchResults(x));
      const t = questionData.topics.filter(x =>
        x.title.toLowerCase().includes(this.searchService.searchQueryValue.toLowerCase())
      );
      this.searchedData.matching = this.getMatchingData(t, s);
      this.searchedData.remainder = this.getRemainderData(t, s);
    });
  }

  private getMatchingData(t: TopicOverviewItem[], s: SubtopicOverviewItem[]): TopicOverviewItem[] {
    const topicData = this.data.available.filter(x => t.findIndex(y => y.id === x.id) >= 0);

    const subtopicData = this.data.available
      .map(topic => {
        const subtopics = topic.subtopics.filter(x => s.findIndex(y => y.id === x.id) >= 0);
        let selectedSubtopics = subtopics;

        if (this.isPrelim) {
          selectedSubtopics = subtopics.filter(st => st.availableForPreliminary).filter(st => !st.consultantSubtopic);
        }

        if (!this.isPrelim && this.step === 2) {
          selectedSubtopics = subtopics.filter(st => st.consultantSubtopic);
        }
        return {
          ...topic,
          subtopics: selectedSubtopics,
        };
      })
      .filter(x => x.subtopics.length > 0);

    return uniqBy([...topicData, ...subtopicData], item => {
      return item.id;
    }).sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1));
  }

  private getRemainderData(t: TopicOverviewItem[], s: SubtopicOverviewItem[]): TopicOverviewItem[] {
    return this.data.available
      .map(topic => {
        const subtopics = topic.subtopics.filter(x => s.findIndex(y => y.id === x.id) === -1);
        let selectedSubtopics = subtopics;

        if (this.isPrelim) {
          selectedSubtopics = subtopics.filter(st => st.availableForPreliminary).filter(st => !st.consultantSubtopic);
        }

        if (!this.isPrelim && this.step === 2) {
          selectedSubtopics = subtopics.filter(st => st.consultantSubtopic);
        }
        return {
          ...topic,
          subtopics: selectedSubtopics,
        };
      })
      .filter(x => x.subtopics.length > 0 && t.findIndex(y => y.id === x.id) === -1);
  }
}
