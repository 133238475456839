import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { TopicOverviewItem, TopicSelectionResponse } from '@domain/app/topic.domain';
import { GetHubConsultationResponseItem } from '@domain/hub/consultation.domain';
import { CustomerResponseItem } from '@domain/hub/customer.domain';
import {
  ConsultationStatusEnum,
  NoteContextEnum,
  NoteTypeEnum,
  RoutingPathMain,
  RoutingPathOverlay,
  RoutingPathPrep,
  TopicPositionEnum,
  TopicStatusEnum,
} from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer-service/shared-footer.service';
import { SharedPanelService } from '@services/shared-panel-service/shared-panel.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { TopicService } from '@services/topic-service/topic-service';
import {
  baseConfig,
  buttonAgenda,
  buttonContinueMain,
  buttonContinuePrep,
  buttonDrawing,
  buttonDrawingActive,
  buttonFinishPrep,
  buttonNotes,
  buttonNotesActive,
  buttonStartMain,
  buttonStartPrep,
} from '@utils/footer-config';
import cloneDeep from 'lodash-es/cloneDeep';
import { Observable, Subject, forkJoin, of, timer } from 'rxjs';
import { catchError, mergeMap, take, takeUntil } from 'rxjs/operators';

interface ImageSizeObj {
  originalWidth: number;
  originalHeight: number;
  width: number;
  height: number;
  top?: number;
  left: number;
  right: number;
  percent: number;
}

interface TopicPosition {
  top: number;
  left: number;
  position?: TopicPositionEnum;
}

interface SubIconInfo {
  name: string;
  status: TopicStatusEnum;
}

@Component({
  selector: 'screen-agenda',
  templateUrl: './screen-agenda.component.html',
  styleUrls: ['./screen-agenda.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenAgendaComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('image', { static: false }) public image: ElementRef;

  private destroySubs = new Subject<void>();
  private topicLogoLayoutWidth: number = 256;
  private topicLayoutWidth: number = 256;
  private topicLayoutHeight: number = 86;
  private scrolledToOrigin: boolean = false;

  public openDrawOverlay: boolean = false;

  public originalImageLayoutWidth: number = 1042;
  public originalImageLayoutHeight: number = 768;

  public imgSize: ImageSizeObj;
  public imgLoaded: boolean = false;
  public dpr = window.devicePixelRatio;

  public topicSelectImagePath = null;
  public titleText: string;
  public customerLogo = `${this.assetPath}/images/icon-factory.svg`;
  public customerName = 'Unternehmensname';
  public customerNumber = '';
  public bankVrLogo = `${this.assetPath}/images/logo-vr.svg`;

  public positionTopicMap: Map<string, TopicOverviewItem> = new Map<TopicPositionEnum, TopicOverviewItem>();
  public positionMap: Map<TopicPositionEnum, TopicPosition> = new Map<TopicPositionEnum, TopicPosition>();
  public subIconsTopicMap: Map<string, SubIconInfo[]> = new Map<string, SubIconInfo[]>();

  public directEntry = false;
  public topicNoteId = '-1';
  public topicNoteType = null;

  public loading = true;
  public tile: boolean = null; // wrap the start image in a kf-tile

  public footerConfig: FooterConfig = baseConfig;
  public footerStartButtonDisabled: boolean = false;

  readonly topicPosEnum = TopicPositionEnum;
  readonly topicStatusEnum = TopicStatusEnum;
  readonly defaultTopicImage = 'topic-overview-bg.webp';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private chg: ChangeDetectorRef,
    private rightSidenavService: RightSidenavService,
    private contextService: ContextService,
    private actionService: ActionService,
    private clientService: ClientService,
    private configService: ConfigService,
    private loadingService: LoadingService,
    private queryService: QueryService,
    private topicService: TopicService,
    private noteService: NoteService,
    private documentService: DocumentService,
    private footerService: SharedFooterService,
    private panelService: SharedPanelService,
    private mediaService: MediaService,
    public sanitizer: DomSanitizer
  ) {
    this.route.paramMap.subscribe(x => {
      const id = x.get('id');

      if (this.clientService.bankHubId !== '' || this.clientService.bankHubId !== undefined) {
        this.clientService.bankHubId = id;
        this.directEntry = true;
      }
    });

    if (this.router?.getCurrentNavigation()?.extras?.state) {
      for (const key in this.router.getCurrentNavigation().extras.state) {
        if (key === 'openDrawOverlay') {
          this.openDrawOverlay = true;
        }
      }
    }
  }

  ngOnInit(): void {
    this.footerRightButtonsFactory();
    this.footerLeftButtonsFactory();

    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (!!context && this.contextService.overlayWasClosed) {
        this.footerRightButtonsFactory();
        this.footerLeftButtonsFactory();
      }
    });

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'topic-select') {
        if (action.action === 'consult') {
          const route =
            this.contextService.currentMode === 'prep' ? RoutingPathPrep.Consultation : RoutingPathMain.Consultation;
          this.router.navigate([route], {
            state: { questionGroupId: '-1' },
          });
        }
        if (action.action === 'jump') {
          const route =
            this.contextService.currentMode === 'prep' ? RoutingPathPrep.Consultation : RoutingPathMain.Consultation;
          this.router.navigate([route], {
            state: { questionGroupId: this.contextService.lastQuestionGroupId.getValue() },
          });
        }
      } else if (action && action.target === 'shared-footer-agenda') {
        if (action.action === 'start-button') {
          this.footerStartButtonDisabled = action.options.disabled;
          this.footerRightButtonsFactory();
        }
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
      this.footerRightButtonsFactory();
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });

    this.noteService.currentNotes.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      if (notesData && this.contextService.currentUrl.includes('agenda')) {
        this.footerLeftButtonsFactory();
      }
    });

    this.noteService.noteUpdated.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      if (notesData && this.contextService.currentUrl.includes('agenda')) {
        this.footerLeftButtonsFactory();
      }
    });

    this.noteService.noteDeleted.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      this.noteService.getAllNotes();
      this.noteService.presetNoteType = null;
      if (notesData && this.contextService.currentUrl.includes('agenda')) {
        this.footerLeftButtonsFactory();
      }
    });

    this.queryService
      .getConsultationById(this.clientService.bankHubId)
      .pipe(
        mergeMap(data => {
          return this.handleConsultationDataResponse(data);
        }),
        catchError(err => {
          // on error, redirect back to client list
          this.router.navigate([`${RoutingPathMain.Error}`], { state: { reason: 'invalidHubId' } });
          return forkJoin([of(null), of(null)]);
        })
      )
      .subscribe(async ([customerData, topicData]: [CustomerResponseItem, TopicSelectionResponse]) => {
        this.handleCustomerAndTopicData(customerData, topicData);

        if (this.quickstartMode || this.startingPointMainConsultation) {
          if (topicData.selected.length !== 0) {
            const route = RoutingPathMain.Consultation;
            this.router.navigate([route], {
              state: { questionGroupId: '-1' },
            });
          }
        }
      });

    this.noteService.pageReference = NoteContextEnum.topicSelection;

    // tile or no tile?
    const settingsValueTile = this.configService.getSettingsValue('topicSelectionImageTile');
    if (settingsValueTile) {
      this.tile = settingsValueTile === '1' ? true : false;
    } else {
      this.tile = true;
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.calcTopicImageSize();

    // scroll to top on vp
    if (environment.platform === 'vp') {
      !this.scrolledToOrigin && window.scroll(0, 0);
      this.scrolledToOrigin = true;
    }
  }

  /// ------------------------------------------------ ///
  /// --------------- FOOTER HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.loading) {
        return;
      }

      if (event.id === 'start-drawing') {
        this.noteService.presetNoteType = NoteTypeEnum.drawing;
        this.doAction('overlay-main', RoutingPathOverlay.Draw);
      } else if (event.id === 'start-notes') {
        this.noteService.presetNoteType = NoteTypeEnum.text;
        this.doAction('overlay-main', RoutingPathOverlay.Draw);
      } else if (event.id === 'agenda-start-consult') {
        // this.router.navigate([`${RoutingPathMain.TopicSelection}`]);
        this.doAction('topic-select', 'consult');
      } else if (event.id === 'agenda-continue-consult') {
        // this.router.navigate([`${RoutingPathMain.TopicSelection}`]);
        this.doAction('topic-select', 'jump', { questionGroupId: this.contextService.lastQuestionGroupId.getValue() });
      } else if (event.id === 'finish-prep-mode') {
        this.contextService.toggleContextMode();
      }
    }
  }

  private footerRightButtonsFactory(): void {
    if (this.contextService.currentOverlayContext !== null || !this.contextService.currentContext.includes('agenda')) {
      return;
    }

    const startConsultationButtonlabel = (): string => {
      const setting = this.configService.getSettingsValue('buttonLabelStartConsultation');
      const defaultSetting = this.configService.getSettingsDefaultValue('buttonLabelStartConsultation');
      return setting ?? (defaultSetting ? defaultSetting : 'Thema starten');
    };

    const continueConsultationButtonlabel = (): string => {
      const setting = this.configService.getSettingsValue('buttonLabelContinueConsultation');
      const defaultSetting = this.configService.getSettingsValue('buttonLabelContinueConsultation');
      return setting ?? (defaultSetting ? defaultSetting : 'Thema fortsetzen');
    };

    const startPreparationButtonlabel = (): string => {
      const setting = this.configService.getSettingsValue('buttonLabelStartPreparation');
      const defaultSetting = this.configService.getSettingsValue('buttonLabelStartPreparation');
      return setting ?? (defaultSetting ? defaultSetting : 'Vorbereitung starten');
    };

    const continuePreparationButtonlabel = (): string => {
      const setting = this.configService.getSettingsValue('buttonLabelContinuePreparation');
      const defaultSetting = this.configService.getSettingsValue('buttonLabelContinuePreparation');
      return setting ?? (defaultSetting ? defaultSetting : 'Vorbereitung fortsetzen');
    };

    if (
      !!this.footerConfig?.drawing ||
      this.footerConfig?.right.isHidden ||
      (!!this.footerConfig?.leftCollapsable && !this.footerConfig?.leftCollapsable.isHidden)
    ) {
      this.footerConfig = baseConfig;
    }

    if (!!this.footerConfig?.leftCollapsable) {
      this.footerService.fixCollapsibleSectionDisplay();
    }

    const currentConfig = cloneDeep(this.footerConfig || baseConfig);
    const rightButtonElements = [];
    const lastQuestionGroupId = this.contextService.lastQuestionGroupId.getValue();

    if (this.contextService.currentMode === 'main') {
      if (lastQuestionGroupId === '-1') {
        buttonStartMain.inputs.config.disabled = this.footerStartButtonDisabled || this.loading;
        buttonStartMain.inputs.config.label = startConsultationButtonlabel();
        rightButtonElements.push(buttonStartMain);
      } else {
        buttonContinueMain.inputs.config.disabled = this.footerStartButtonDisabled || this.loading;
        buttonContinueMain.inputs.config.label = continueConsultationButtonlabel();
        rightButtonElements.push(buttonContinueMain);
      }
    } else if (this.contextService.currentMode === 'prep') {
      if (
        ((this.clientService.consultationStatus === ConsultationStatusEnum.created ||
          this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation) &&
          !this.clientService.prepDataSent) ||
        this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
      ) {
        rightButtonElements.push(buttonFinishPrep);
      }

      if (lastQuestionGroupId === '-1') {
        buttonStartPrep.inputs.config.disabled = this.footerStartButtonDisabled || this.loading;
        buttonStartPrep.inputs.config.label = startPreparationButtonlabel();
        rightButtonElements.push(buttonStartPrep);
      } else {
        buttonContinuePrep.inputs.config.disabled = this.footerStartButtonDisabled || this.loading;
        buttonContinuePrep.inputs.config.label = continuePreparationButtonlabel();
        rightButtonElements.push(buttonContinuePrep);
      }
    }

    currentConfig.right.elements = rightButtonElements;
    this.footerService.submitFooterConfig(currentConfig);
  }

  private footerLeftButtonsFactory(): void {
    if (this.contextService.currentOverlayContext !== null || !this.contextService.currentContext.includes('agenda')) {
      return;
    }

    let currentDrawButton = buttonDrawing;
    let currentNoteButton = buttonNotes;
    const currentNotes = this.noteService.currentNotes.getValue();
    const currentConfig = cloneDeep(this.footerConfig || baseConfig);
    currentConfig.left.isHidden = false;
    currentConfig.left.elements = [currentDrawButton, currentNoteButton];

    const topic = NoteContextEnum.topicSelection;
    const filtered = currentNotes?.notesByPageReference.filter(x => x.pageReference === topic) || null;

    filtered?.forEach(note => {
      if (note.noteType === NoteTypeEnum.drawing) {
        currentDrawButton = buttonDrawingActive;
      } else if (note.noteType === NoteTypeEnum.text) {
        currentNoteButton = buttonNotesActive;
      }
    });

    if (this.footerService.isManagedFooter) {
      currentConfig.left.elements = [currentDrawButton, currentNoteButton];
    } else {
      currentConfig.left.elements = [buttonAgenda, currentDrawButton, currentNoteButton];
    }
    this.footerService.submitFooterConfig(currentConfig);
  }

  /// ------------------------------------------------ ///
  /// ---------------- IMAGE HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public onResize(event) {
    this.calcTopicImageSize(10, 30);
    this.dpr = window.devicePixelRatio;
  }

  public onImageLoaded(event) {
    this.calcTopicImageSize(10);
    this.imgLoaded = true;
  }

  public openTopicSelectMenu(topicId) {
    this.topicService.agendaTopicClicked = topicId;
    if (this.rightSidenavService.instance.opened) {
      this.rightSidenavService.forcedClose = true;
      this.rightSidenavService.close();
    }

    this.doAction('overlay-main', 'agenda-edit');
  }

  public getTopicPosTop(pos: TopicPositionEnum): number {
    if (!this.positionMap.has(pos)) {
      return 0;
    }

    let topMax: number;
    if (pos === TopicPositionEnum.logo) {
      topMax = this.imgSize?.height - document.getElementById('logoContainer')?.offsetHeight;
    } else if (pos === TopicPositionEnum.bank) {
      topMax = this.imgSize?.height - document.getElementById('bankContainer')?.offsetHeight;
    } else {
      topMax = this.imgSize?.height - this.getTopicHeight(pos);
    }

    return topMax * this.positionMap.get(pos).top;
  }

  public getTopicPosLeft(pos: TopicPositionEnum): number {
    if (!this.positionMap.has(pos)) {
      return 0;
    }

    let leftMax: number;
    if (pos === TopicPositionEnum.logo) {
      leftMax = this.imgSize?.width - document.getElementById('logoContainer')?.offsetWidth;
    } else if (pos === TopicPositionEnum.bank) {
      leftMax = this.imgSize?.width - document.getElementById('bankContainer')?.offsetWidth;
    } else {
      leftMax = this.imgSize?.width - this.getTopicWidth(pos);
    }
    return leftMax * this.positionMap.get(pos).left;
  }

  public getTopicWidth(pos: TopicPositionEnum): number {
    if (!this.positionMap.has(pos)) {
      return 0;
    }
    const layoutWidth = pos === TopicPositionEnum.logo ? this.topicLogoLayoutWidth : this.topicLayoutWidth;

    if (this.imgSize?.originalWidth > this.originalImageLayoutWidth) {
      if (
        layoutWidth * this.imgSize?.percent * (this.imgSize?.originalWidth / this.originalImageLayoutWidth) >
        layoutWidth
      ) {
        return layoutWidth;
      } else {
        return layoutWidth * this.imgSize?.percent * (this.imgSize?.originalWidth / this.originalImageLayoutWidth);
      }
    }

    return layoutWidth * this.imgSize?.percent;
  }

  public getTopicHeight(pos: TopicPositionEnum): number {
    if (!this.positionMap.has(pos)) {
      return 0;
    }

    const layoutHeight = this.topicLayoutHeight;

    if (this.imgSize?.originalHeight > this.originalImageLayoutHeight) {
      return layoutHeight * this.imgSize?.percent * (this.imgSize?.originalHeight / this.originalImageLayoutHeight);
    }

    return layoutHeight * this.imgSize?.percent;
  }

  public computeLogoHeight(pos: TopicPositionEnum): number {
    let height: number;

    if (pos === TopicPositionEnum.logo) {
      height = this.imgSize?.height / 8;
    } else if (pos === TopicPositionEnum.bank) {
      height = this.imgSize?.width / 20;
    }

    return height;
  }

  public computeLogoMaxWidth(pos: TopicPositionEnum): number {
    return this.computeLogoHeight(pos) * 2;
  }

  public compareTopicPos(data: string, pos: TopicPositionEnum) {
    return data !== pos;
  }

  private handleTestStatus(): void {
    if (!this.clientService.fetchedTestStatus && this.clientService.consultationId) {
      this.queryService.getIsTestConsultation(this.clientService.consultationId).subscribe(x => {
        this.clientService.isTestConsultation = x;
        this.clientService.fetchedTestStatus = true;
      });
    }
  }

  private handleConsultationDataResponse(data: GetHubConsultationResponseItem): Observable<[any, any]> {
    this.clientService.consultationId = data.id;
    this.clientService.instanceId = data.instanceId;
    this.clientService.instanceVersion = data.contentVersion;
    this.clientService.consultationStatus = data.status;
    this.clientService.consultationDate = data.appointmentDate;
    this.clientService.consultationTime = data.appointmentTime;
    this.clientService.consultationName = data.name;

    if (!!data.id) {
      this.noteService.getAllNotes();
      this.documentService.fetchDocumentList();
    }

    if (environment.platform === 'vp') {
      this.queryService.getConsultationByConsultant().subscribe(x => {
        this.clientService.appointmentUUID = x.customers
          .flatMap(x => x.consultations)
          .find(x => x.id === this.clientService.consultationId)?.appointmentId;
      });
    }

    this.handleTestStatus();

    if (this.isDoneStatus) {
      this.router.navigate([`${RoutingPathMain.Error}`], { state: { reason: 'doneConsultation' } });
      return forkJoin([of(null), of(null)]);
    }

    this.clientService.setSolutionBasketCount();

    if (this.clientService.consultationStatus === ConsultationStatusEnum.archived) {
      this.footerStartButtonDisabled = true;
    }

    if (
      this.isMainStatus &&
      this.contextService.currentMode === 'main' &&
      !this.directEntry &&
      this.configService.startingPointMainConsultation
    ) {
      this.router.navigate([`${RoutingPathMain.TopicSelection}`]);
      return forkJoin([of(null), of(null)]);
    }

    if (this.isPrepStatus && this.directEntry && this.contextService.currentMode === 'main') {
      this.router.navigate([`${RoutingPathPrep.Agenda}/${this.clientService.bankHubId}`]);
      return forkJoin([of(null), of(null)]);
    } else if (this.isMainStatus && this.directEntry && this.contextService.currentMode === 'prep') {
      this.router.navigate([`${RoutingPathMain.Agenda}/${this.clientService.bankHubId}`]);
      return forkJoin([of(null), of(null)]);
    }

    const r1 = this.queryService.getCustomerById(data.customerId).pipe(catchError(() => of(null)));
    const r2 = this.queryService.getTopicDataByConsultationId(data.id).pipe(catchError(() => of(null)));

    return forkJoin([r1, r2]);
  }

  private async handleCustomerAndTopicData(
    customerData: CustomerResponseItem,
    topicData: TopicSelectionResponse
  ): Promise<void> {
    if (!customerData && !topicData) {
      return;
    }

    if (!this.configService.appConfig) {
      await this.configService.getAppConfigurationData(true);
    }

    const topicImage = this.configService.getSettingsValue('topicSelectionImageName');
    const titleText = this.configService.getSettingsValue('titleText');
    const bankName = this.configService.getBankInfoValue('bank-name');

    if (topicImage?.includes('/')) {
      this.topicSelectImagePath = await this.mediaService.getMediaContent(topicImage);
    } else if (topicImage) {
      this.topicSelectImagePath = `${this.assetPath}/images/${topicImage}`;
    } else {
      this.topicSelectImagePath = `${this.assetPath}/images/${this.defaultTopicImage}`;
    }

    this.topicNoteId = topicData?.noteId || '-1';
    this.noteService.currentContextNoteId = this.topicNoteId;

    if (this.openDrawOverlay) {
      this.actionService.setAction({
        target: 'overlay-main',
        action: 'drawing',
      });
    }

    this.clientService.hasVatDiscount = customerData.vatDiscount || false;
    this.clientService.customerId = customerData.id;
    this.clientService.customerNumber = customerData.customerNumber;
    this.clientService.customerName = customerData.corporationName;
    this.clientService.customerLogo =
      !customerData.logo || customerData.logo.includes('null') ? this.customerLogo : customerData.logo;

    if (topicData) {
      if (topicData.selected.length === 0 && this.directEntry && !this.contextService.skipSelection) {
        const route = this.isPrepStatus ? RoutingPathPrep : RoutingPathMain;
        this.router.navigate([route.TopicSelection]);
      }

      this.handleTopicDataResponse(topicData);

      this.panelService.updatePanelConfig();
    }

    if (titleText) {
      this.titleText = titleText.replace(/{{bank-name}}/g, bankName).replace(/(<br)( *)(\/>)/, '\n');
    } else {
      this.titleText = 'Herzlich willkommen!\n Ein Beratungsmodul der ' + bankName;
    }

    this.calcTopicImageSize(10);
    this.loading = false;

    this.footerRightButtonsFactory();
  }

  private handleTopicDataResponse(data: TopicSelectionResponse) {
    this.footerStartButtonDisabled =
      data.selected.length === 0 || this.clientService.consultationStatus === ConsultationStatusEnum.archived;

    this.topicService.selectedTopicsData = data.selected;

    if (this.clientService.customerName) {
      this.customerName = this.clientService.customerName;
    }
    if (this.clientService.customerNumber) {
      this.customerNumber = this.clientService.customerNumber;
    }
    if (this.clientService.customerLogo && !this.clientService.customerLogo.includes('null')) {
      this.customerLogo = this.clientService.customerLogo;
    }

    for (const topicItem of data.selected) {
      this.positionTopicMap.set(topicItem.position, topicItem);
      this.subIconsTopicMap.set(
        topicItem.id,
        topicItem.subtopics.map(x => {
          return { name: x.iconName, status: x.status };
        })
      );
    }

    for (const topicItem of data.available) {
      this.positionTopicMap.set(topicItem.position, topicItem);
      this.subIconsTopicMap.set(
        topicItem.id,
        topicItem.subtopics.map(x => {
          return { name: x.iconName, status: x.status };
        })
      );
    }

    this.getTopicPositions();
    this.chg.detectChanges();
  }

  private calcTopicImageSize(delay = 50, maxTries = 10) {
    if (maxTries <= 0 || !this.image) {
      //TODO: REVERT
      return;
    }
    const img = this.image.nativeElement;
    const pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
    timer(delay)
      .pipe(take(1))
      .subscribe(x => {
        if (img.width === 0 || img.height === 0) {
          this.calcTopicImageSize(50, maxTries - 1);
          return;
        }

        this.imgSize = this.getRenderedSize(
          true,
          img.width,
          img.height,
          img.naturalWidth,
          img.naturalHeight,
          parseInt(pos[0], 10)
        );

        if (Number.isNaN(this.imgSize?.width) || Number.isNaN(this.imgSize?.height)) {
          this.calcTopicImageSize(50, maxTries - 1);
          return;
        }

        this.chg.detectChanges();
      });
  }

  private getRenderedSize(contains, cWidth, cHeight, width, height, pos): ImageSizeObj {
    const oRatio = width / height;
    const cRatio = cWidth / cHeight;
    const hRatio = cHeight / height;
    const wRatio = cWidth / width;
    return function () {
      if (contains ? oRatio > cRatio : oRatio < cRatio) {
        this.width = cWidth;
        this.height = cWidth / oRatio;
      } else {
        this.width = cHeight * oRatio;
        this.height = cHeight;
      }
      this.originalWidth = width;
      this.originalHeight = height;
      this.top = (cHeight - this.height) / 2;
      this.left = (cWidth - this.width) * (pos / 100);
      this.right = this.width + this.left;
      this.percent = hRatio < wRatio ? hRatio : wRatio;
      return this;
    }.call({});
  }

  private getTopicPositions(): void {
    this.positionMap.clear();
    const topicPosData = this.configService.getSettingsValue('topicSelectionPositions');

    for (const pos in TopicPositionEnum) {
      if (this.positionTopicMap.has(TopicPositionEnum[pos])) {
        let positionObject = {} as TopicPosition;
        const positionConfig = JSON.parse(topicPosData)?.find(x => x.position === TopicPositionEnum[pos]);

        switch (TopicPositionEnum[pos]) {
          case TopicPositionEnum.logo:
            positionObject.top = positionConfig?.top / 100 || 0.041;
            positionObject.left = positionConfig?.left / 100 || 0.5;
            break;
          case TopicPositionEnum.bank:
            positionObject.top = positionConfig?.top / 100 || 0.041;
            positionObject.left = positionConfig?.left / 100 || 0.1;
            break;
          case TopicPositionEnum.topLeft:
            positionObject.top = positionConfig?.top / 100 || 0.413;
            positionObject.left = positionConfig?.left / 100 || 0.1;
            break;
          case TopicPositionEnum.topCenter:
            positionObject.top = positionConfig?.top / 100 || 0.413;
            positionObject.left = positionConfig?.left / 100 || 0.497;
            break;
          case TopicPositionEnum.topRight:
            positionObject.top = positionConfig?.top / 100 || 0.413;
            positionObject.left = positionConfig?.left / 100 || 0.9;
            break;
          case TopicPositionEnum.center:
            positionObject.top = positionConfig?.top / 100 || 0.681;
            positionObject.left = positionConfig?.left / 100 || 0.497;
            break;
          case TopicPositionEnum.centerLeft:
            positionObject.top = positionConfig?.top / 100 || 0.681;
            positionObject.left = positionConfig?.left / 100 || 0.1;
            break;
          case TopicPositionEnum.centerRight:
            positionObject.top = positionConfig?.top / 100 || 0.681;
            positionObject.left = positionConfig?.left / 100 || 0.9;
            break;
          case TopicPositionEnum.downLeft:
            positionObject.top = positionConfig?.top / 100 || 0.947;
            positionObject.left = positionConfig?.left / 100 || 0.1;
            break;
          case TopicPositionEnum.downCenter:
            positionObject.top = positionConfig?.top / 100 || 0.947;
            positionObject.left = positionConfig?.left / 100 || 0.497;
            break;
          case TopicPositionEnum.downRight:
            positionObject.top = positionConfig?.top / 100 || 0.947;
            positionObject.left = positionConfig?.left / 100 || 0.9;
            break;
        }
        this.positionMap.set(TopicPositionEnum[pos], positionObject);
      }
    }

    return null;
  }

  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get isScreenshotting(): boolean {
    return this.noteService.screenshotting;
  }

  set isScreenshotting(screenshotting: boolean) {
    this.noteService.screenshotting = screenshotting;
  }

  get isPrepStatus(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.created ||
      this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone
    );
  }

  get isDoneStatus(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.checkoutDone ||
      this.clientService.consultationStatus === ConsultationStatusEnum.archived
    );
  }

  get isMainStatus(): boolean {
    return !this.isPrepStatus && !this.isDoneStatus;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get topicsData() {
    return this.topicService.selectedTopicsData;
  }

  get quickstartMode(): boolean {
    return this.configService.quickstartMode;
  }

  get startingPointMainConsultation(): boolean {
    return (
      this.configService.startingPointMainConsultation &&
      this.clientService.consultationStatus === ConsultationStatusEnum.mainConsultation
    );
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
