import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NewCustomerForm } from '@domain/app/forms.domain';
import { CustomerDataFieldGroupPutItem, CustomerRequestItem, CustomerResponseItem } from '@domain/hub/customer.domain';
import { RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { Base64ConvertService } from '@services/base64convert-service/base64convert-service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { ImportService } from '@services/import-service/import.service';
import { QueryService } from '@services/query-service/query.service';
import moment from 'moment';
import 'moment/locale/de';
import { Subscription, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export interface ProfileDataFieldSendObj {
  groupId: number;
  dataFieldValue: string;
}

const fileUploadConfig = { acceptedFiles: '.jpg,.jpeg,.png', sizeLimit: 150000, fileLimit: 1 };
@Component({
  selector: 'overlay-customer-new',
  templateUrl: './overlay-customer-new.component.html',
  styleUrls: ['./overlay-customer-new.component.scss'],
})
export class OverlayCustomerNewComponent implements OnInit, OnDestroy {
  private _actionSub: Subscription;
  private _importRunningSub: Subscription;
  private _refreshForDataFieldsSub: Subscription;
  private _importErrorSub: Subscription;

  // Do they need to be unsubscribed?
  private _debounceSubTextNumber: Subscription;
  private _debounceSubTextName: Subscription;
  private _debounceSubTextArea: Subscription;
  private newInputNumber = new EventEmitter<boolean>();
  private newInputName = new EventEmitter<boolean>();

  importErrors;

  newCustomerForm: FormGroup<NewCustomerForm>;
  submitted = false;

  readonly moment = moment;

  fileUploadConfig = fileUploadConfig;
  datafieldGroups;
  duplicateNumber = false;
  duplicateName = false;

  private customerNumberList;
  private corporationNameList;

  public logoFile: File;
  public logoString: string;

  public customerId: number;
  public editMode = false;
  public importMode = false;
  public importIsRunning = false;

  dataFieldChanges = new Map<number, ProfileDataFieldSendObj>();

  @ViewChild('uploadComponent', { static: false }) uploadComponent;
  showFileUploadForm = true;

  constructor(
    private router: Router,
    private actionService: ActionService,
    public clientService: ClientService,
    private contextService: ContextService,
    private formBuilder: FormBuilder,
    private importService: ImportService,
    public queryService: QueryService,
    private base64Service: Base64ConvertService,
    private readonly formValidationService: FormValidationService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;
      if (stateObj && stateObj.edit) {
        this.editMode = stateObj.edit;
        if (stateObj.import) {
          this.importMode = true;
        }
      }
    }
  }

  ngOnInit(): void {
    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'customer-new') {
      }
    });

    this.customerId = this.clientService.customerId;
    if (this.editMode && this.customerId !== 0) {
      this.prepareData();
    } else {
      this.formDataFactory();
      this.newCustomerForm?.reset();
    }

    this._importRunningSub = this.importService.importIsOngoing.subscribe(importStatus => {
      this.importIsRunning = importStatus;
    });

    this._refreshForDataFieldsSub = this.importService.refreshRequest.subscribe(() => {
      if (this.customerId !== 0) {
        this.prepareData();
      }
    });

    this._importErrorSub = this.importService.importHasError.subscribe(importError => {
      this.importErrors = importError;
      if (importError) {
        this.importMode = false;
      }
    });

    this.queryService.getAllCustomers().subscribe(data => {
      this.customerNumberList = data.map(number => number.customerNumber);
      this.corporationNameList = data.map(name => name.corporationName);
    });

    this._debounceSubTextNumber = this.newInputNumber
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe(number => {
        this.duplicateNumber = number;
      });

    this._debounceSubTextName = this.newInputName.pipe(debounceTime(800), distinctUntilChanged()).subscribe(name => {
      this.duplicateName = name;
    });
  }

  ngOnDestroy(): void {
    this._actionSub?.unsubscribe();
    this._importRunningSub?.unsubscribe();
    this._refreshForDataFieldsSub?.unsubscribe();
    this._importErrorSub?.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  prepareData() {
    this.queryService.getCustomerById(this.customerId).subscribe(async data => {
      this.dataFieldChanges.clear();
      this.customerId = data.id;
      this.datafieldGroups = data.dataFieldGroups;
      if (data.logo) {
        try {
          this.logoFile = await this.base64Service.convertToFile(data.logo);
          this.logoString = data.logo;
          this.uploadComponent.documents = [this.logoFile];
        } catch (err) {}
      }
      this.formDataFactory(data);
    });
  }

  onCancel() {
    this.doAction('overlay-main', 'close');
  }

  onImportCustomer() {
    this.doAction('overlay-main', 'customer-import', {
      customerNumber: this.mainControl.account.value,
      isNewUser: !this.editMode,
    });
  }

  onAddCustomer() {
    this.submitted = true;
    if (!this.newCustomerForm.valid) {
      return;
    }

    const customer = {} as CustomerRequestItem;
    customer.corporationName = this.mainControl.corporationName.value;
    customer.customerNumber = this.mainControl.account.value;
    customer.website = this.mainControl.website.value || '';
    customer.logo = this.logoString;
    customer.vatDiscount = this.mainControl.discount.value;
    if (this.datafieldGroups) {
      customer.dataFieldGroups = this.createDataFieldGroupSendObj();
    }

    if (!this.editMode) {
      this.queryService.postCustomer(customer).subscribe(data => {
        this.handleUpdate();
      });
    } else if (this.editMode) {
      this.queryService.putCustomerById(this.customerId, customer).subscribe(data => {
        this.handleUpdate();
      });
    }
  }

  get mainControl() {
    return this.newCustomerForm.controls;
  }

  private formDataFactory(customer: CustomerResponseItem = null) {
    this.newCustomerForm = this.formBuilder.group({
      corporationName: new FormControl<string | null>(customer?.corporationName, Validators.required),
      account: new FormControl<string | null>(customer?.customerNumber, Validators.required),
      website: new FormControl<string | null>(customer?.website),
      discount: new FormControl<boolean | null>(customer?.vatDiscount),
    });
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  private handleUpdate() {
    this.doAction('overlay-main', 'close');
    const timerSub = timer(50).subscribe(() => {
      if (this.contextService.currentMainContext === RoutingPathMain.ClientConsultations) {
        this.doAction('client-consultations', 'update');
      } else if (this.contextService.currentMainContext === RoutingPathMain.ClientList) {
        this.doAction('client-list', 'update');
      }
      timerSub.unsubscribe();
    });
  }

  setNewDataFieldValue(id, datafieldId, newValue) {
    this.dataFieldChanges.set(datafieldId, { groupId: id, dataFieldValue: newValue });
  }

  createDataFieldGroupSendObj() {
    const sendObj: CustomerDataFieldGroupPutItem[] = this.datafieldGroups.map((group, index) => ({
      id: group.id,
      dataFieldGroupId: group.dataFieldGroupId,
      main: group.main,
      ordinal: index,
      dataFields: group.dataFields.map(field => ({ dataFieldId: field.dataFieldId, value: field.value })),
    }));

    sendObj.forEach(element => {
      element.dataFields.forEach(datafield => {
        if (
          this.dataFieldChanges.has(datafield.dataFieldId) &&
          element.id === this.dataFieldChanges.get(datafield.dataFieldId).groupId
        ) {
          datafield.value = this.dataFieldChanges.get(datafield.dataFieldId).dataFieldValue;
        }
      });
    });
    return sendObj;
  }

  async setFile(inputFile: FileList) {
    if (inputFile?.length > 0 && inputFile[0]) {
      if (inputFile[0] !== this.logoFile) {
        this.logoFile = inputFile[0];
        try {
          const string = await this.base64Service.convertToBase64(inputFile[0]);
          this.logoString = string;
        } catch (err) {}
      }
    } else {
      this.resetData();
    }
  }

  resetData() {
    this.logoString = '';
    this.logoFile = undefined;
    this.showFileUploadForm = false;
    setTimeout(() => {
      this.showFileUploadForm = true;
    }, 100);
  }

  numberIsFound(event) {
    if (this.customerNumberList.find(customerNumber => customerNumber === event)) {
      this.newInputNumber.emit(true);
    } else {
      this.newInputNumber.emit(false);
    }
  }

  nameIsFound(event) {
    if (this.corporationNameList.find(corporationName => corporationName === event)) {
      this.newInputName.emit(true);
    } else {
      this.newInputName.emit(false);
    }
  }
}
