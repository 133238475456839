import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Notes } from '@domain/notes';
import { NoteContextEnum, NoteTypeEnum } from '@enums';
import { LoadingService } from '@services/loading-service/loading.service';
import { NoteIdentifier, ViewProperties } from '@services/note-service/note';
import { NoteService } from '@services/note-service/note.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'notes-menu',
  templateUrl: './notes-menu.component.html',
  styleUrls: ['./notes-menu.component.scss'],
})
export class NotesMenuComponent implements OnDestroy {
  @Output() isCustomNote = new EventEmitter<boolean | undefined>();
  @Output() selectedNoteChange = new EventEmitter<{ noteId: NoteIdentifier; isCustomNote: boolean } | undefined>();

  private destroySubs = new Subject<void>();

  public allNotes: Notes.GetAllNotesResponse | undefined = undefined;
  public deletedNote = undefined;
  public selectedNote: string;
  public unsavedChanges: boolean = false;
  public loading: boolean = false;
  public noteContextEnum = NoteContextEnum;
  public currentOpacity = 0.6;

  readonly noteTypeEnum = NoteTypeEnum;

  selectedEditNote;
  topicOverviewNote: Notes.NoteByPageReference | undefined;

  constructor(
    private readonly noteService: NoteService,
    private readonly loadingService: LoadingService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.changeDetector.detectChanges();
    });
    this.noteService.currentNote.pipe(takeUntil(this.destroySubs)).subscribe(x => {
      this.selectedNote = x?.id;
    });
    this.noteService.currentNotes.pipe(takeUntil(this.destroySubs)).subscribe(x => {
      this.allNotes = x;
    });
    this.noteService.getAllNotes();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public selectNote(noteId: NoteIdentifier, isCustomNote: boolean = false, type: NoteTypeEnum = null) {
    if (this.selectedNote !== noteId) {
      this.noteService.requestNoteChange.next(noteId);
    }

    this.noteService.presetNoteType = type;

    isCustomNote ? this.changeOpacity(100) : this.changeOpacity(0);
  }

  public filterPageReference() {
    this.topicOverviewNote = this.allNotes.notesByPageReference.filter(
      x => x.pageReference === NoteContextEnum.topicSelection
    )[0];
    return this.topicOverviewNote.pageReference;
  }

  get presetNoteType() {
    return this.noteService.presetNoteType;
  }

  public changeOpacity(value: number) {
    this.currentOpacity = Number((value / 100).toFixed(2));
    document.documentElement.style.setProperty('--canvas-background', `rgba(255, 255, 255, ${this.currentOpacity})`);
  }

  public getViewProperties(note: any): ViewProperties | undefined {
    return note?.viewProperties ? JSON.parse(note.viewProperties) : undefined;
  }
}
