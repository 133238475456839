<aside #overlay class="position-relative d-flex h-100 w-100 kf-bg" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  @if (displayMenuContent) {
    <div class="d-flex flex-column mx-auto mt-4 overlay-search-container">
      @if (dataFetched) {
        <ng-container>
          <h2>Suche</h2>
          <div class="mb-4">
            <mat-form-field>
              <mat-label>Suche nach Lösungen</mat-label>
              <input #searchInput type="search" [(ngModel)]="searchQuery" placeholder="Lösung" matInput />
              @if (searchQuery.length > 1) {
                <button
                  matSuffix
                  disableRipple
                  mat-icon-button
                  aria-label="Sucheingaben löschen"
                  (click)="searchQuery = ''"
                >
                  <kf-icon name="ic_clear_24"></kf-icon>
                </button>
              } @else {
                <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
                  <kf-icon name="ic_search_24"></kf-icon>
                </button>
              }
            </mat-form-field>
          </div>
          <section class="d-flex flex-scroll search-result-lists">
            <search-question-world-column tabindex="-1"></search-question-world-column>
            @if (!isInPrep) {
              <search-world-column type="product" tabindex="-1"></search-world-column>
              <search-world-column type="task" tabindex="-1"></search-world-column>
              <search-world-column type="transition" tabindex="-1"></search-world-column>
              <search-world-column
                type="content-element"
                tabindex="-1"
                (contentElementAddedToAgenda)="(searchQuery)"
              ></search-world-column>
            }
          </section>
        </ng-container>
      } @else {
        <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90">
        </mat-progress-spinner>
      }
    </div>
  }

  <button
    class="kf-popup-close"
    mat-icon-button
    (click)="onClose()"
    aria-label="Dialog schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>
