<article class="position-relative">
  <mat-checkbox
    class="position-absolute mr-3 select-all-control"
    color="grey"
    [checked]="numberOfSelectedSubtopics === numberOfAvailableSubtopics"
    [indeterminate]="numberOfSelectedSubtopics > 0 && numberOfSelectedSubtopics < numberOfAvailableSubtopics"
    (change)="onSelectAll(topicItem)"
  >
    @if (!loading.has(topicItem.id)) {
      <p class="select-all-button">
        <span>Alle</span>
        <span class="sr-only">Unterthemen aus: {{ topicItem.title }}</span>
        <span>{{ shouldSelectAll(topicItem) ? 'auswählen' : 'abwählen' }}</span>
      </p>
    }
  </mat-checkbox>

  <mat-expansion-panel
    class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
    [expanded]="isTopicExpanded"
    (opened)="onTopicExpanded(topicItem.id, true)"
    (closed)="onTopicExpanded(topicItem.id, false)"
  >
    <mat-expansion-panel-header expandedHeight="3rem" collapsedHeight="3rem">
      @if (topicItem) {
        <div class="expansion-panel-header-container">
          <div class="mr-2">
            @if (!getMediaUrl(topicItem.icon?.id)) {
              <div class="topic-icon" aria-hidden="true"></div>
            }
            <bgzv-image
              class="topic-icon"
              aria-hidden="true"
              [alt]=""
              [src]="getMediaUrl(topicItem.icon?.id)"
            ></bgzv-image>
          </div>
          <strong
            class="m-0 font-weight-bold"
            [attr.data-testcafe]="'titleElement-' + getTestcafeId(topicItem.title) + '-' + topicItem.id"
          >
            {{ topicItem.title }}
          </strong>
          <div
            class="item-topic-header-expand-container"
            [class]="numberOfSelectedSubtopics === 0 ? 'justify-content-end' : 'justify-content-between'"
          >
            @if (numberOfSelectedSubtopics > 0) {
              <div class="tag-info-text tag-info tag-neutral ml-2">
                {{ numberOfSelectedSubtopics }}<span class="sr-only">Unterthemen gewählt</span>
              </div>
            }
          </div>
        </div>
      }
    </mat-expansion-panel-header>

    @if (topicItem.subtopics) {
      <ul class="list-unstyled mb-0">
        @for (subtopic of topicItem.subtopics; track subtopic; let last = $last) {
          <li>
            @if (!loading.has(subtopic.id) && !loading.has(topicItem.id)) {
              <mat-checkbox
                class="mx-2"
                [class.icon-select-grayed]="grayed"
                [attr.data-testcafe]="getTestcafeId(subtopic.title) + '-' + subtopic.id"
                [checked]="subtopic.selected"
                [color]="'grey'"
                (change)="subtopic.selected ? onSubtopicDeselected(subtopic) : onSubtopicSelected(subtopic)"
                ><span class="subtopic-title">{{ subtopic.title }}</span>
              </mat-checkbox>
            }
            @if (!last) {
              <mat-divider class="mt-2"></mat-divider>
            }
          </li>
        }
      </ul>
    }
  </mat-expansion-panel>
</article>
