import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { VariantCalculator } from '@domain/variant-calculator';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { LoadingService } from '@services/loading-service/loading.service';
import {
  ProductVariantCalculator,
  VariantCalculatorProductDetails,
} from '@services/product-variant-calculator/product-variant-calculator';
import { ProductVariantCalculatorService } from '@services/product-variant-calculator/product-variant-calculator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'overlay-compare-accounts',
  templateUrl: './overlay-compare-accounts.component.html',
  styleUrls: ['./overlay-compare-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayCompareAccountsComponent implements OnInit, OnDestroy {
  @ViewChild('productsGalleryScrollContainer') productsGalleryScrollContainer: ElementRef<HTMLDivElement>;

  private accountType: VariantCalculator.CalculatorName = 'ACPK';
  private destroySubs = new Subject<void>();

  public variantCalculator: ProductVariantCalculator | undefined;
  public recommendation: VariantCalculatorProductDetails['productId'] | undefined;
  public prices: { [productId: string]: number } | undefined;
  public productsInCart: VariantCalculatorProductDetails['productId'][] | undefined;
  public galleryOffset = 0;

  public isLoading = false;
  public subtopicId = '-1';

  readonly galleryItems = 3;

  constructor(
    private readonly actionService: ActionService,
    private readonly clientService: ClientService,
    private readonly productVariantCalculatorService: ProductVariantCalculatorService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private readonly router: Router
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const state = this.router.getCurrentNavigation().extras.state;
      if (state && state?.accountType) {
        this.accountType = state.accountType;
      }
      if (state && state?.subtopicId) {
        this.subtopicId = state.subtopicId;
      }
    }
  }

  ngOnInit(): void {
    this.productVariantCalculatorService
      .fetch(this.clientService.consultationId, this.accountType)
      .pipe(takeUntil(this.destroySubs))
      .subscribe(calculator => {
        this.variantCalculator = calculator;
        this.changeDetectorRef.detectChanges();

        calculator.recommendation.pipe(takeUntil(this.destroySubs)).subscribe(recommendation => {
          this.recommendation = recommendation;
          this.scrollToRecommendedVariant();
          this.changeDetectorRef.detectChanges();
        });

        calculator.prices.pipe(takeUntil(this.destroySubs)).subscribe(prices => {
          this.prices = prices;
          this.changeDetectorRef.detectChanges();
        });

        calculator.productsInCart.pipe(takeUntil(this.destroySubs)).subscribe(productsInCart => {
          this.productsInCart = productsInCart;
          this.changeDetectorRef.detectChanges();
        });
      });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.isLoading = loading;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  close() {
    this.actionService.setAction({ target: 'consultation', action: 'reload-recommendation' });
    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {});
  }

  get galleryMayMoveLeft() {
    return this.galleryOffset > 0;
  }

  get galleryMayMoveRight() {
    return this.variantCalculator.products.length - this.galleryItems - this.galleryOffset > 0;
  }

  onGalleryLeftClick() {
    if (this.galleryMayMoveLeft) {
      this.galleryOffset -= 1;
      this.refreshGalleryScrollPos();
      this.changeDetectorRef.detectChanges();
    }
  }

  onGalleryRightClick() {
    if (this.galleryMayMoveRight) {
      this.galleryOffset += 1;
      this.refreshGalleryScrollPos();
      this.changeDetectorRef.detectChanges();
    }
  }

  scrollToRecommendedVariant() {
    const indexOfRecommendation = this.variantCalculator.products.findIndex(
      value => value.productId === this.recommendation
    );
    const firstVisibleIndex = this.galleryOffset;
    const lastVisibleIndex = this.galleryOffset + this.galleryItems - 1;
    const maxGalleryOffset = this.variantCalculator.products.length - this.galleryItems + 1;
    if (indexOfRecommendation < firstVisibleIndex || indexOfRecommendation > lastVisibleIndex) {
      this.galleryOffset = Math.min(indexOfRecommendation, maxGalleryOffset);
      this.refreshGalleryScrollPos();
      this.changeDetectorRef.detectChanges();
    }
  }

  private refreshGalleryScrollPos() {
    const sampleProductVariantCard = this.productsGalleryScrollContainer.nativeElement.firstElementChild;

    const sampleProductVariantCardMarginLeft = parseInt(
      window.getComputedStyle(sampleProductVariantCard).marginLeft,
      10
    );
    const sampleProductVariantCardMarginRight = parseInt(
      window.getComputedStyle(sampleProductVariantCard).marginRight,
      10
    );
    const sampleProductVariantCardWidth = sampleProductVariantCard.getBoundingClientRect().width;

    this.productsGalleryScrollContainer?.nativeElement?.scrollTo({
      left:
        (sampleProductVariantCardWidth + sampleProductVariantCardMarginLeft + sampleProductVariantCardMarginRight) *
        this.galleryOffset,
      behavior: 'smooth',
    });
  }
}
