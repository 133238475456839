import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { CompositionChildResponse } from '@domain/app/composition.domain';
import { MediaItem } from '@domain/app/media.domain';
import { MainProductRecommendationItem } from '@domain/app/product.domain';
import {
  DeselectTransitionItem,
  SelectTransitionItem,
  TransitionRecommendationItem,
} from '@domain/app/transition.domain';
import { RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { QueryService } from '@services/query-service/query.service';
import { formatContact } from '@utils/helpers/formatContact';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'item-composition-transition',
  templateUrl: './item-composition-transition.component.html',
  styleUrls: ['./item-composition-transition.component.scss', '../item-cart/item-cart.component.scss'],
})
export class ItemCompositionTransitionComponent implements OnInit, OnDestroy {
  @Input() mainData: MainProductRecommendationItem;
  @Input() transitionData: TransitionRecommendationItem;
  @Input() compositionId: string;
  @Input() compositionMedia: MediaItem;
  @Input() isIndividualItem?: boolean = false;
  @Input() showMainDetails?: boolean = false;
  @Input() showIndividualGroupHeading?: boolean = false;
  @Output() showDetails = new EventEmitter<any>();
  @Output() showTransition = new EventEmitter<any>();
  @Output() transitionAssigned = new EventEmitter<boolean>();

  private destroySubs = new Subject<void>();

  public loading = false;

  public formatContact = formatContact;

  readonly moment = moment;

  constructor(
    private clientService: ClientService,
    private contextService: ContextService,
    private queryService: QueryService,
    private actionService: ActionService,
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'item-transition') {
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
    if (this.transitionData.description) {
      this.transitionData.description = this.transitionData?.description?.replace(/(<([^>]+)>)/gi, '').trim() || '';
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  get isIndividualTransition(): boolean {
    return this.compositionId === '-1';
  }

  public showProductDetails(productId): void {
    this.showDetails.emit(productId);
  }

  public onBadgeClicked(type, id, event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    if (type === 'product') {
      this.showProductDetails(id);
    }
  }

  public selectTransitionText(transitionId: string, box: MatCheckbox): void {
    box.checked = !box.checked;
    this.selectTransition(transitionId, { checked: box.checked });
  }

  public selectTransition(transitionId: string, checkbox: MatCheckboxChange | { checked: boolean }): void {
    if (checkbox.checked) {
      const sendObj = {} as SelectTransitionItem;
      sendObj.compositionId = this.compositionId;
      sendObj.transitionId = transitionId;
      if (this.isIndividualTransition) {
        this.queryService
          .putSelectIndividualTransition(this.clientService.consultationId, sendObj.transitionId)
          .subscribe(data => {
            this.notifyMainComponents();
            this.transitionAssigned.emit(true);
          });
      } else {
        this.queryService.putSelectTransition(this.clientService.consultationId, sendObj).subscribe(data => {
          this.notifyMainComponents();
          this.transitionAssigned.emit(true);
        });
      }
    } else {
      const sendObj = {} as DeselectTransitionItem;
      sendObj.compositionId = this.compositionId;
      sendObj.transitionId = transitionId;
      sendObj.target = 'RECOMMENDED';

      if (this.isIndividualTransition) {
        this.queryService
          .putDeselectIndividualTransition(this.clientService.consultationId, sendObj.transitionId)
          .subscribe(data => {
            this.notifyMainComponents();
            this.transitionAssigned.emit(false);
          });
      } else {
        this.queryService.putDeselectTransition(this.clientService.consultationId, sendObj).subscribe(data => {
          this.notifyMainComponents();
          this.transitionAssigned.emit(false);
        });
        this.doAction('overlay-cart', 'show-all-recommended', {
          allRecommendedChecked: false,
        });
      }
    }
  }

  public openTransitionAssignment(transitionId: string, skipSelection: boolean, selected: boolean): void {
    const sendObj: CompositionChildResponse = {
      elementId: transitionId,
      skipSelection: skipSelection,
      selected: selected,
      mainData: this.mainData,
    };
    this.showTransition.emit(sendObj);
  }

  public isRecommended(data: TransitionRecommendationItem): boolean {
    return data?.recommended === true || false;
  }

  public truncateText(text: string, length: number) {
    const regex = new RegExp('&..(ml;|lig;)', 'g');
    let offset = 0;

    if (text?.length <= length) {
      return text;
    }

    regex.test(text);
    while (regex.lastIndex < length + offset * 6 && regex.lastIndex != 0) {
      regex.test(text);
      offset++;
    }

    return text?.substr(0, length + offset * 5) + '\u2026';
  }

  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }

    this.actionService.setAction(data);
  }

  private notifyMainComponents() {
    if (this.contextService.currentMainContext === RoutingPathMain.Consultation) {
      this.doAction('consultation', 'reload-recommendation');
    }
    if (this.contextService.currentMainContext === RoutingPathMain.TopicSummary) {
      this.doAction('cart-item', 'reload-summary');
    }
    if (this.isIndividualTransition) {
      this.doAction('cart-item', 'reload-cart');
    }
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
