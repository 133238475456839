import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';
import { CustomerContactResponseItem } from '@domain/app/contact.domain';
import { AssignContentElementItem, ContentElementItem } from '@domain/app/content-element.domain';
import { PanelStateEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContactData, ContentElementService } from '@services/content-element-service/content-element.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  standalone: true,
  selector: 'side-content-element-assign',
  templateUrl: './side-content-element-assign.component.html',
  styleUrl: './side-content-element-assign.component.scss',
  imports: [MaterialModule, KfThemeModule, ReactiveFormsModule],
})
export class SideContentElementAssignComponent implements OnInit, OnDestroy {
  @Input() contentElementData: ContentElementItem;
  @Input() assignmentFormGroup: FormGroup;

  @Output() changePanelState = new EventEmitter<PanelStateEnum>();
  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();

  public readonly panelStateEnum = PanelStateEnum;
  public appointmentDate: Date | undefined;
  public invalidDate: boolean;

  private destroySubs = new Subject<void>();

  constructor(
    private contentElementService: ContentElementService,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService
  ) {}

  ngOnInit(): void {
    this.contentElementService.contactAdded.pipe(takeUntil(this.destroySubs)).subscribe(contact => {
      if (contact) {
        this.assignmentFormGroup.get('contact').setValue(this.contactList.find(x => x.label === contact?.label));
      }
    });

    this.contentElementService.appointmentDate.pipe(takeUntil(this.destroySubs)).subscribe(x => {
      if (x?.valueOf()) {
        this.appointmentDate = x;
      } else {
        this.appointmentDate = null;
      }
      if (this.appointmentDate <= new Date()) {
        this.invalidDate = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public buttonClick(state: PanelStateEnum): void {
    this.changePanelState.emit(state);
  }

  public getDateString(): string {
    return this.appointmentDate.toLocaleString('default', { day: '2-digit', month: 'long', year: 'numeric' });
  }

  public deleteAppointmentDate(): void {
    this.contentElementService.appointmentDate.next(null);
  }

  public assignContentElement(): void {
    let sendObj: AssignContentElementItem = {
      themaId: this.contentElementData.id,
      customerContact: this.getCustomerContact(this.assignmentFormGroup.value.contact),
      appointmentDate: this.getAppointmentDateIsoFormat(this.appointmentDate),
      notifyViaEmail: this.assignmentFormGroup.get('mail').value,
      individualNote: this.assignmentFormGroup.get('note').value,
      priority: this.contentElementData.priority,
      itemReference: this.contentElementData.referenceId,
    };

    this.queryService.putAssignThema(this.clientService.consultationId, sendObj).subscribe(x => {
      this.closePanel.emit();
    });
  }

  private getCustomerContact(contactData: ContactData): CustomerContactResponseItem {
    return this.contentElementService.customerContacts.find(x => x.id === contactData.id);
  }

  private getAppointmentDateIsoFormat(date: Date): string {
    if (!this.appointmentDate) {
      return null;
    } else {
      const year = date.toLocaleString('default', { year: 'numeric' });
      const month = date.toLocaleString('default', { month: '2-digit' });
      const day = date.toLocaleString('default', { day: '2-digit' });
      return year + '-' + month + '-' + day;
    }
  }

  get contactList(): ContactData[] {
    return this.contentElementService.contactList;
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
