<aside class="d-flex flex-column app-height align-items-center kf-bg overflow-hidden transition-container-width">
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100">
    <header class="d-flex w-100 justify-content-between side-header">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="d-flex align-items-center">
          <button
            *ngIf="state !== transitionState.default || inputValues.sideOpen"
            [attr.data-testcafe]="'sideTransitionIndiv-button-back'"
            (click)="handleBack()"
            class="mr-2"
            aria-label="Ansicht schließen"
            kfTooltip="Ansicht schließen"
            kfTooltipPosition="before"
            kfTooltipShow="hover"
            mat-icon-button
          >
            <kf-icon name="ic_chevron_links_24"></kf-icon>
          </button>

          <div class="d-flex flex-column">
            <ng-container *ngIf="!transitionData?.name">
              <h2 class="text-truncate mb-0 side-heading">Individuelles Expertengespräch</h2>
            </ng-container>
            <ng-container *ngIf="transitionData?.name">
              <h2
                class="text-truncate mb-0 side-heading"
                [kfTooltip]="transitionData?.name.length > 50 ? transitionData.name : null"
                kfTooltipPosition="below"
                kfTooltipShow="hover"
              >
                {{ transitionData?.name }}
              </h2>
            </ng-container>

            <ng-container *ngIf="state === transitionState.contact">
              <p class="text-truncate mb-0">Neuer Ansprechpartner des Unternehmens</p>
            </ng-container>
            <ng-container *ngIf="state === transitionState.datepick">
              <p class="text-truncate mb-0">Terminvorschlag</p>
            </ng-container>
          </div>
        </div>
      </div>
    </header>

    <ng-container *ngIf="transitionData || inputValues.transitionId === '-1'; else loading">
      <ng-container *ngIf="state === transitionState.default">
        <ng-template *ngTemplateOutlet="default"></ng-template>
      </ng-container>
      <ng-container *ngIf="state === transitionState.datepick">
        <ng-template *ngTemplateOutlet="datepick"></ng-template>
      </ng-container>
      <ng-container *ngIf="state === transitionState.contact">
        <ng-template *ngTemplateOutlet="contact"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <button
    *ngIf="state === transitionState.default && !inputValues.sideOpen"
    [attr.data-testcafe]="'sideTransitionIndiv-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="handleClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<!-- ---------------------------------------------- -->
<!-- -------------- DEFAULT TEMPLATE -------------- -->
<!-- ---------------------------------------------- -->

<ng-template #default>
  <div class="d-flex flex-column side-container" [formGroup]="transitionForm">
    <div *ngIf="transitionSendObj" class="d-flex flex-column">
      <div class="mt-4">
        <mat-form-field class="notranslate">
          <mat-label>Titel</mat-label>
          <input
            ngDefaultControl
            formControlName="name"
            matInput
            type="text"
            [(ngModel)]="transitionSendObj.name"
            (change)="onTransitionName($event)"
          />
          <mat-error>
            <kf-input-error
              *ngIf="transitionFormControls.name.invalid"
              [message]="getFieldErrorMessage(transitionFormControls.name, 'name')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- ------ EXPERT SELECTION ------ -->
    <div>
      <mat-form-field required>
        <mat-label>Experte</mat-label>
        <mat-select
          #select
          kfSelectMdc
          (selectionChange)="onExpertSelected($event)"
          placeholder="Experten auswählen"
          [formControl]="transitionFormControls.expertContact"
        >
          <mat-option *ngFor="let option of expertList" [value]="option.value">{{ option.label }}</mat-option>
        </mat-select>
        <mat-error>
          <kf-input-error
            *ngIf="transitionFormControls.expertContact.invalid"
            [message]="getFieldErrorMessage(transitionFormControls.expertContact, 'expertContact')"
          >
          </kf-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- ------ CONTACT SELECTION ------ -->
    <div>
      <div class="d-flex align-items-center mb-3">
        <ng-container *ngIf="contactsList.length === 0">
          <button
            [attr.data-testcafe]="'sideTransitionIndiv-button-consultant'"
            mat-button
            color="primary"
            (click)="state = transitionState.contact"
          >
            <kf-icon name="ic_pluszeichen_24"></kf-icon>
            <span>Ansprechpartner</span>
          </button>
        </ng-container>

        <ng-container *ngIf="contactsList.length > 0">
          <div class="d-flex flex-column w-100">
            <mat-form-field required>
              <mat-label>Ansprechpartner</mat-label>
              <mat-select
                #select
                kfSelectMdc
                (selectionChange)="onContactSelected($event)"
                placeholder="Ansprechpartner auswählen"
                [formControl]="transitionFormControls.customerContact"
              >
                <mat-option *ngFor="let option of contactsList" [value]="option.value">{{ option.label }}</mat-option>
              </mat-select>
              <mat-error>
                <kf-input-error
                  *ngIf="transitionFormControls.customerContact.invalid"
                  [message]="getFieldErrorMessage(transitionFormControls.customerContact, 'customerContact')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>

            <div class="d-flex justify-content-end">
              <button
                [attr.data-testcafe]="'sideTransitionIndiv-button-addConsultant'"
                mat-stroked-button
                color="primary"
                (click)="state = transitionState.contact"
              >
                <kf-icon name="ic_pluszeichen_24"></kf-icon>
                <span>Ansprechpartner</span>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- ------ COMMENT SELECTION ------ -->
    <mat-form-field class="notranslate" [formGroup]="transitionForm">
      <mat-label>Notiz</mat-label>
      <div class="kf-field-char-counter" aria-hidden="true">
        {{ transitionSendObj.individualNote?.length || 0 }}/4000
      </div>
      <textarea
        #textArea
        ngDefaultControl
        [(ngModel)]="transitionSendObj.individualNote"
        formControlName="individualNote"
        matInput
        maxLength="4000"
        cdkTextareaAutosize
        (change)="onTransitionCommentAdded(textArea.value)"
      ></textarea>
    </mat-form-field>

    <!-- ------ APPOINTMENT SELECTION ------ -->
    <div>
      <div class="d-flex align-items-center">
        <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
        <div class="font-weight-bold">
          {{ appointmentArray.length === 1 ? 'Terminvorschlag' : 'Terminvorschläge' }}
        </div>
      </div>
      <div class="d-flex flex-column mt-2 mb-2">
        <ng-container *ngFor="let appointment of appointmentArray; let i = index; let first = first">
          <div class="w-100 d-flex justify-content-between" [class.mt-3]="!first">
            <div class="m-auto">
              <div class="tag-info tag-neutral">
                <div class="tag-info-text">
                  {{ appointment.appointmentString }}
                </div>
              </div>
            </div>
            <div class="button-row-inline">
              <div class="button-row-content">
                <button
                  [attr.data-testcafe]="'sideTransitionIndiv-button-closeAppointment'"
                  (click)="onDatepickDeleted(i)"
                  aria-label="Terminvorschlag löschen"
                  kfTooltip="Terminvorschlag löschen"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                  mat-icon-button
                >
                  <kf-icon name="ic_loeschen_24"></kf-icon>
                </button>
                <button
                  [attr.data-testcafe]="'sideTransitionIndiv-button-appointment'"
                  mat-icon-button
                  (click)="onDatepickUpdate(i)"
                  aria-label="Terminvorschlag ändern"
                  kfTooltip="Terminvorschlag ändern"
                  kfTooltipPosition="after"
                  kfTooltipShow="hover"
                >
                  <kf-icon name="ic_edit_24"></kf-icon>
                </button>
              </div>
            </div>
          </div>
          @if (isInvalidDate(appointment)) {
            <mat-error class="mt-2"
              ><kf-input-error message="Termin muss in der Zukunft liegen"></kf-input-error
            ></mat-error>
          }
        </ng-container>
      </div>
      <ng-container>
        <div class="d-flex justify-content-end mb-5">
          <button
            [class.mt-2]="appointmentArray.length > 0"
            [attr.data-testcafe]="'sideTransitionIndiv-button-date'"
            mat-stroked-button
            color="primary"
            (click)="onDatepickSelected(appointmentArray.length || 0)"
          >
            <kf-icon name="ic_pluszeichen_24"></kf-icon>
            <span>Termin</span>
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- ------ CONFIRMATION SELECTION ------ -->
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTransitionIndiv-button-abort'"
          mat-stroked-button
          color="primary"
          (click)="handleClose()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          mat-flat-button
          color="primary"
          [disabled]="buttonDisabled"
          [attr.data-testcafe]="testcafeButtonTransition"
          (click)="onFinalizeTransition()"
        >
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- -------------------------------------------- -->
<!-- -------------- DATE TEMPLATES -------------- -->
<!-- -------------------------------------------- -->

<ng-template #datepick>
  <div class="d-flex flex-column side-container">
    <div class="d-flex flex-column h-100 flex-center">
      <div class="appointment-details border">
        <mat-calendar
          [selected]="tempAppointment?.timeDateString"
          [minDate]="minimumDate"
          [startAt]="tempAppointment?.timeDateString"
          (selectedChange)="onDateSelect($event)"
          color="primary"
        ></mat-calendar>

        <div class="d-flex align-items-center mx-auto pt-3 border-top">
          <div class="d-flex align-items-center mx-3" (click)="openDatepicker(startTime, $event)">
            <mat-form-field class="notranslate">
              <mat-label>Von</mat-label>
              <input
                [attr.data-testcafe]="'datepicker-input-startTime'"
                [format]="24"
                [ngxTimepicker]="startTime"
                [value]="tempAppointment?.timeStartString || ''"
                matInput
                placeholder="SS:MM"
                readonly
                type="text"
              />
            </mat-form-field>

            <ngx-material-timepicker
              #startTime
              [theme]="bg2Theme"
              [minutesGap]="5"
              [format]="24"
              [confirmBtnTmpl]="timeConfirmButton"
              [cancelBtnTmpl]="timeCancelButton"
              (timeSet)="onStartTimeSelect($event)"
            ></ngx-material-timepicker>
          </div>

          <div class="d-flex align-items-center mx-3" (click)="openDatepicker(endTime, $event)">
            <mat-form-field class="notranslate">
              <mat-label>Bis</mat-label>
              <input
                [attr.data-testcafe]="'datepicker-input-endTime'"
                [disabled]="!startTime.time"
                [format]="24"
                [min]="getEndTimeMin(startTime.time)"
                [ngxTimepicker]="endTime"
                [value]="tempAppointment?.timeEndString || ''"
                matInput
                placeholder="SS:MM"
                readonly
                type="text"
              />
            </mat-form-field>

            <ngx-material-timepicker
              #endTime
              [theme]="bg2Theme"
              [minutesGap]="5"
              [format]="24"
              [confirmBtnTmpl]="timeConfirmButton"
              [cancelBtnTmpl]="timeCancelButton"
              (timeSet)="onEndTimeSelect($event)"
            ></ngx-material-timepicker>
          </div>
        </div>

        <ng-template #timeCancelButton>
          <button class="time-button" [attr.data-testcafe]="'sideTransitionIndiv-button-abort'">Abbrechen</button>
        </ng-template>
        <ng-template #timeConfirmButton>
          <button class="time-button" [attr.data-testcafe]="'sideTransitionIndiv-button-confirm'">OK</button>
        </ng-template>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTransitionIndiv-button-abort'"
          mat-stroked-button
          color="primary"
          (click)="state = transitionState.default"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTransitionIndiv-button-suggestAppointment'"
          [disabled]="notValidAppointment"
          mat-flat-button
          color="primary"
          (click)="onAppointmentDateConfirmed()"
        >
          <span>Termin vorschlagen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- --------------------------------------------- -->
<!-- ------------- CONTACT TEMPLATES ------------- -->
<!-- --------------------------------------------- -->

<ng-template #contact>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="contactForm" class="w-100 d-flex flex-column">
        <mat-form-field class="notranslate" class="w-100">
          <mat-label>Anrede</mat-label>
          <mat-select
            #select
            ngDefaultControl
            kfSelectMdc
            placeholder="Anrede auswählen"
            [formControl]="contactFormControls.formOfAddress"
          >
            <mat-option *ngFor="let option of contactsFormOfAddress" [value]="option.value">{{
              option.label
            }}</mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="contactFormControls.formOfAddress.invalid"
              [message]="getFieldErrorMessage(contactFormControls.formOfAddress, 'formOfAddress')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <div class="w-50 mr-3">
            <mat-form-field class="notranslate">
              <mat-label>Vorname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.firstName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.firstName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.firstName, 'firstName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-50 ml-3">
            <mat-form-field class="notranslate">
              <mat-label>Nachname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.lastName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.lastName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.lastName, 'lastName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-form-field class="notranslate">
            <mat-label>E-Mail-Adresse</mat-label>
            <input
              ngDefaultControl
              [formControl]="contactFormControls.email"
              matInput
              type="email"
              placeholder="Gültige E-Mail-Adresse wie z.B. name.vorname@domain.de"
            />
            <mat-error>
              <kf-input-error
                *ngIf="contactFormControls.email.invalid"
                [message]="getFieldErrorMessage(contactFormControls.email, 'email')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row-inline">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideTransitionIndiv-button-abort'"
          mat-stroked-button
          color="primary"
          (click)="handleBack()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTransitionIndiv-button-addConsultant'"
          [disabled]="!contactForm.valid"
          mat-flat-button
          color="primary"
          (click)="onContactAdded()"
        >
          <span>Ansprechpartner hinzufügen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
