import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AnswerNumberInputComponent } from '@components/answers/answer-number-input/answer-number-input.component';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import {
  DataFieldFormValueChange,
  ItemDatafieldFormComponent,
} from '@components/item-datafield-form/item-datafield-form.component';
import { CheckoutDataFieldCompositionItem, CheckoutDataFieldUpdateRequest } from '@domain/app/checkout.domain';
import { MediaItem } from '@domain/app/media.domain';
import { DeselectProductItem, ProductResponse, SelectProductItem } from '@domain/app/product.domain';
import { DataFieldElementTypeEnum, DataFieldStatusEnum, MediaTypeEnum, PaymentInterval, RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { ContractService } from '@services/contract-service/contract.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color } from '@utils/helpers/color';
import { getBenefitIcon } from '@utils/helpers/mediaType';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

interface ProductDetailInput {
  compositionId: string;
  productId: string;
  hideButton: boolean;
  keepNavOpen?: boolean;
  showDataFields?: boolean;
  useBackToList?: boolean;
  sideOpen?: boolean;
}

enum ProductStateEnum {
  default = 'default',
  customer = 'customer',
}

interface DataFieldValueSetParams {
  dataFieldGroupId?: string;
  dataField?: any;
  newDataFieldValue?: any;
  dataFieldReference?: any;
}

@Component({
  selector: 'side-product-details',
  templateUrl: './side-product-details.component.html',
  styleUrls: ['./side-product-details.component.scss'],
})
export class SideProductDetailsComponent implements OnInit, OnDestroy {
  @Input() inputValues: ProductDetailInput;
  @Input() compositionName: string = null;
  @Output() closed = new EventEmitter(null);

  @ViewChild('productNum') productNum: AnswerNumberInputComponent;
  @ViewChild('formItem') formComponent: ItemDatafieldFormComponent;

  private destroySubs = new Subject<void>();
  private _dialogSub: Subscription;
  private _dialogRef = null;

  public requestInProgress = false;
  public isLoading = false;

  public keyStroke = new BehaviorSubject<DataFieldValueSetParams>({});

  context: string;
  areFormsValid = false;

  public productData: ProductResponse;
  public dataFieldData: CheckoutDataFieldCompositionItem;
  public DataFieldStatusEnum = DataFieldStatusEnum;
  public dataFieldElementTypeEnum = DataFieldElementTypeEnum;
  public mainRouteEnum = RoutingPathMain;
  public costTypeEnum = PaymentInterval;
  public productState = ProductStateEnum;
  public state = ProductStateEnum.default;

  public currentQuantity;
  public productRemoved = false;

  public documentCount;
  public documents;
  public image: { url: string; altText: string } = { url: '', altText: '' };

  formGroups = [];

  readonly color = color;
  readonly getBenefitIcon = getBenefitIcon;

  constructor(
    private clientService: ClientService,
    private contextService: ContextService,
    private actionService: ActionService,
    private queryService: QueryService,
    private mediaService: MediaService,
    private dialogService: DialogService,
    private rightSidenavService: RightSidenavService,
    private changeDetector: ChangeDetectorRef,
    private loadingService: LoadingService,
    private documentService: DocumentService,
    private contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.documentCount = 0;
    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (context !== null && context !== undefined) {
        this.context = context;
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(isLoading => {
      this.isLoading = isLoading;
      this.changeDetector.detectChanges();
    });

    if (this.inputValues.showDataFields) {
      this.state = ProductStateEnum.customer;
    }

    if (this.inputValues.keepNavOpen) {
      this.queryProductData();
    } else if (this.inputValues.sideOpen) {
      this.queryProductData();
    } else {
      this.rightSidenavService.instance._animationStarted.pipe(takeUntil(this.destroySubs)).subscribe(x => {
        if (x.fromState === 'void' && x.toState === 'open') {
          this.queryProductData();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
    this._dialogSub?.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public onCustomerDataSelected() {
    this.state = ProductStateEnum.customer;
    this.queryDataFieldData();
  }

  public onDataCancelled() {
    if (this.inputValues.sideOpen) {
      this.doAction('recommendation-extra', 'details-closed');
    } else {
      this.handleClose();
    }
  }

  public handleClose() {
    this.closed.emit();
    this.rightSidenavService.close();
  }

  public handleDataFormValueChange(event: DataFieldFormValueChange) {
    // send this to the backend, and request new data;
    const sendObj = {
      dataFieldValueId: event.item.dataFieldValueId,
      value: event.changedValue || '',
    } as CheckoutDataFieldUpdateRequest;

    this.requestInProgress = true;
    this.queryService
      .putCheckoutDataFieldData(this.clientService.consultationId, sendObj, event.item.elementType)
      .subscribe(x => {
        if (x.hiddenChanged) {
          this.queryDataFieldData();
        } else {
          this.requestInProgress = false;
        }
        this.contractService.putRequested.next(x);
        this.doAction('cart-item', 'reload-cart');
      });
  }

  public async showDocument(document: MediaItem, event: Event): Promise<void> {
    event.preventDefault();
    this.documentService.showDocument(document);
  }

  public setProductQuantity(value) {
    this.productNum.currentNumber = value;
    this.currentQuantity = value;

    if (this.productData.selected) {
      this.emitProductQuantity();
    }
  }

  public getTaxes(vat) {
    let tax = '';
    if (vat?.includes('OPTIONAL')) {
      tax = this.clientService.hasVatDiscount
        ? 'zzgl. 19% MwSt.'
        : 'Umsatzsteuerbefreite Bankdienstleistung nach §4 Nr. 8 UStG';
    } else if (vat?.includes('PERCENT') && !vat?.includes('OPTIONAL')) {
      tax = 'zzgl. ' + vat.substring(vat.indexOf('_') + 1, vat.length) + '% MwSt.';
    } else {
      tax = 'Einsparungsrate';
    }
    return tax;
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  emitProductQuantity() {
    this.requestInProgress = true;
    if (this.currentQuantity !== 0 || !this.productData.quantityVisibility) {
      const sendObj = {} as SelectProductItem;

      sendObj.compositionId = this.productData.compositionId;
      sendObj.productId = this.productData.id;
      this.productRemoved = false;
      if (!this.productData.quantityVisibility) {
        sendObj.quantity = 1;
      } else {
        sendObj.quantity = this.currentQuantity;
      }
      this.queryService
        .putSelectProduct(this.clientService.consultationId, sendObj)
        .pipe(finalize(() => (this.requestInProgress = false)))
        .subscribe(data => {
          this.productData.selected = true;
          this.notifyMainComponents();
          this.queryProductData();
        });
    } else {
      const sendObj = {} as DeselectProductItem;
      sendObj.compositionId = this.productData.compositionId;
      sendObj.productId = this.productData.id;
      sendObj.target = 'RECOMMENDED';
      this.productRemoved = true;
      this.queryService
        .putDeselectProduct(this.clientService.consultationId, sendObj)
        .pipe(finalize(() => (this.requestInProgress = false)))
        .subscribe(data => {
          this.productData.selected = false;
          this.notifyMainComponents();
          this.queryProductData();
        });
    }
  }

  resetQuantity() {
    this.requestInProgress = true;
    const sendObjDeselect = {} as DeselectProductItem;
    sendObjDeselect.compositionId = this.productData.compositionId;
    sendObjDeselect.productId = this.productData.id;
    sendObjDeselect.target = 'RECOMMENDED';
    this.queryService
      .putDeselectProduct(this.clientService.consultationId, sendObjDeselect)
      .pipe(finalize(() => (this.requestInProgress = false)))
      .subscribe(data => {
        this.productData.selected = false;
        this.notifyMainComponents();
      });
  }

  public async toggleContextMode(): Promise<void> {
    const data = {
      copyText: `Möchten Sie das Produkt "${this.productData?.name}" aus dem Lösungskorb entfernen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this._dialogRef = this.dialogService.openDialogConfirm(data);
    this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.resetQuantity();

        setTimeout(() => {
          this.onDataCancelled();
        }, 350);
      }
      this._dialogRef = null;
    });
  }

  private notifyMainComponents() {
    this.clientService.setSolutionBasketCount();
    if (
      this.contextService.currentMainContext.startsWith(this.mainRouteEnum.Consultation) ||
      this.contextService.currentMainContext.startsWith(this.mainRouteEnum.Agenda)
    ) {
      this.doAction('consultation', 'reload-recommendation');
      this.doAction('cart-item', 'reload-cart');
    }
    if (this.contextService.currentMainContext.startsWith(this.mainRouteEnum.TopicSummary)) {
      this.doAction('cart-item', 'reload-summary');
    }
  }

  private queryProductData() {
    this.queryService
      .getProductDetailsById(
        this.clientService.consultationId,
        this.inputValues.compositionId,
        this.inputValues.productId
      )
      .subscribe(async data => {
        this.productData = data;
        this.documents = this.productData.medias.filter(x => x.type !== MediaTypeEnum.image);
        this.documentCount = this.documents.length;

        const imageObject = this.productData.medias.find(x => x.type === MediaTypeEnum.image);
        if (!!imageObject) {
          this.image.altText = 'icon';
          this.image.url = await this.mediaService.getMediaContent(imageObject.url);
        }

        if (!this.productData.selected) {
          this.currentQuantity = this.productRemoved ? 0 : this.productData.quantityDefaultValue;
        } else {
          this.currentQuantity = this.productData.quantity;
        }

        this.queryDataFieldData();
      });
  }

  private queryDataFieldData(): void {
    this.queryService
      .getCheckoutDataFieldData(
        this.clientService.consultationId,
        DataFieldElementTypeEnum.products,
        this.productData.compositionId,
        this.inputValues.productId === '-1' ? '0' : this.inputValues.productId
      )
      .pipe(finalize(() => (this.requestInProgress = false)))
      .subscribe(data => {
        this.dataFieldData = data;
      });
  }

  get productInfo() {
    return (
      (this.productState.default && this.inputValues.useBackToList) ||
      this.inputValues.sideOpen ||
      (this.productState.customer && !this.inputValues.showDataFields)
    );
  }

  get productDetails() {
    return (
      (this.productState.default && !this.inputValues.sideOpen) ||
      (this.productState.customer && this.inputValues.showDataFields && !this.inputValues.sideOpen)
    );
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }

  get hasDataFieldData(): boolean {
    return !!this.dataFieldData?.dataFieldGroups && this.dataFieldData.dataFieldStatus !== DataFieldStatusEnum.none;
  }
}
