import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActionService } from '@services/action-service/action.service';
import {
  ContentElementsItem,
  ItemType,
  ProductWorldItem,
  SearchService,
} from '@services/search-service/search.service';
import { TopicService } from '@services/topic-service/topic-service';
import { color } from '@utils/helpers/color';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'search-world-column',
  templateUrl: './search-world-column.component.html',
  styleUrls: ['./search-world-column.component.scss'],
})
export class SearchWorldColumnComponent implements OnInit, OnDestroy {
  @Input() public type: ItemType = 'product';
  @Output() public contentElementAddedToAgenda: EventEmitter<any> = new EventEmitter<any>();

  private _productWorldData: ProductWorldItem[];
  private _contentElementsData: ContentElementsItem[];
  private destroySubs = new Subject<void>();

  readonly color = color;

  constructor(
    private readonly actionService: ActionService,
    private readonly searchService: SearchService,
    private readonly topicService: TopicService
  ) {}

  ngOnInit(): void {
    if (this.type === 'content-element') {
      this.searchService.contentElementsData.pipe(takeUntil(this.destroySubs)).subscribe(data => {
        this._contentElementsData = data.filter(
          x => !this.topicService.subtopicData.selected.find(subtopic => subtopic.id === x.itemReference)
        );
      });
    } else {
      this.searchService.productWorldData.pipe(takeUntil(this.destroySubs)).subscribe(data => {
        this._productWorldData = data;
      });
    }

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action.target === 'search-world-column') {
        if (action.action === 'refresh') {
          this.searchService.searchQuery = this.searchService.searchQueryValue;
          this.contentElementAddedToAgenda.emit();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public handleClick(element: ProductWorldItem | ContentElementsItem): void {
    const getOptions = () => {
      const { id, type } = element;
      switch (type) {
        case 'content-element':
          return { contentElementId: id };
        case 'product':
          return { compositionId: element.compositionId, productId: id };
        case 'task':
          return { compositionId: element.compositionId, taskId: id, compositionTitle: element.compositionName };
        case 'transition':
          return { compositionId: element.compositionId, transitionId: id };
      }
    };

    this.actionService
      .setAction({
        target: 'main',
        action: element.type,
        options: getOptions(),
      })
      .then();
  }

  public getName(item: ProductWorldItem | ContentElementsItem): string {
    return item.mainName === item.name ? item.name : `${item.mainName} (${item.name})`;
  }

  get data(): (ProductWorldItem | ContentElementsItem)[] {
    if (this.type === 'content-element') {
      return this._contentElementsData;
    }
    return this._productWorldData.filter(x => x.type === this.type);
  }

  get searchQuery(): string {
    return this.searchService.searchQueryValue;
  }

  get title(): string {
    switch (this.type) {
      case 'product':
        return 'Produkte';
      case 'task':
        return 'Maßnamen';
      case 'transition':
        return 'Expertengespräche';
      case 'content-element':
        return 'Themenempfehlungen';
    }
  }
}
