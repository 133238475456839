import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GetMessagesResponseItem, PostMessagesRequestItem } from '@domain/app/messages.domain';
import { Profile } from '@domain/app/profile';
import { GivenAnswerEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import moment from 'moment';
import { Observable, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'side-chat',
  templateUrl: './side-chat.component.html',
  styleUrls: ['./side-chat.component.scss'],
})
export class SideChatComponent implements OnInit {
  public messageData: GetMessagesResponseItem[] = [];
  public loading: boolean = false;
  public loadingMessages: boolean = false;
  public messageSending: boolean = false;
  public isAtBottom: boolean = true;
  public givenAnswerEnum = GivenAnswerEnum;
  public message: string = '';
  public customerData: Profile.GetProfileResponse;

  public error: boolean = false;

  readonly moment = moment;

  public currentMessages: GetMessagesResponseItem[] = [];

  @ViewChild('chatFrame') chatFrame: ElementRef;

  constructor(
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private rightSidenavService: RightSidenavService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    forkJoin({ messageData: this.messageRequest, customerData: this.customerRequest })
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(response => {
        this.customerData = response.customerData;
        this.messageData = response.messageData.sort(this.compare);
        this.currentMessages = this.fillCurrentMessages(0);
      });
  }

  public getMessageData(): void {
    this.loadingMessages = true;
    this.messageRequest
      .pipe(
        finalize(() => {
          this.loadingMessages = false;
          setTimeout(() => this.scrollToBottom(), 200);
        })
      )
      .subscribe(data => {
        this.messageData = data.sort(this.compare);
        this.currentMessages = this.fillCurrentMessages(0);

        this.error = false;
      });
  }

  public sendMessage() {
    if (this.isStringEmptyOrSpaces(this.message)) {
      return;
    }

    this.messageSending = true;
    const sendData: PostMessagesRequestItem = { sender: GivenAnswerEnum.consultant, text: this.message };
    this.queryService
      .postMessage(this.clientService.consultationId, sendData)
      .pipe(
        finalize(() => {
          this.messageSending = false;
        })
      )
      .subscribe(
        resp => {
          const newMessage = resp;

          this.message = '';
          this.messageData = [...this.messageData, newMessage];
          this.currentMessages = [...this.currentMessages, newMessage];
          setTimeout(() => this.scrollToBottom(), 50);
        },
        error => {
          this.error = true;
          this.scrollToBottom();
        }
      );
  }

  public onMoreClick() {
    this.currentMessages = [...this.fillCurrentMessages(this.currentMessages.length)];
    this.isAtBottom = false;
  }

  public timestamp(createdAt: string) {
    return moment(new Date(createdAt)).format('DD.MM.yyyy, HH:mm') + ' Uhr';
  }

  public onClose(): void {
    this.rightSidenavService.close(true);
  }

  public scrollToBottom(): void {
    this.chatFrame?.nativeElement?.scroll({
      top: this.chatFrame?.nativeElement?.scrollHeight,
      behavior: 'smooth',
    });
  }

  public isConsultant(messageSender: string): boolean {
    return messageSender === this.givenAnswerEnum.consultant;
  }

  public onChatScroll(manual = false): void {
    const obj = this.chatFrame?.nativeElement;
    this.isAtBottom = obj ? obj.scrollHeight - obj.offsetHeight - obj.scrollTop < 1 : true;
  }

  public getTestcafeId(createdAt = '01.01.1970, 00:01', message = '') {
    const time = moment(new Date(createdAt)).format('DD.MM.yyyy, HH:mm');
    return `sideChat-div-message-${time?.replace(/ /g, '')}-${message?.replace(/ /g, '').slice(0, 12)}`;
  }

  public isStringEmptyOrSpaces(str) {
    return str === null || str.match(/^\s*$/) !== null;
  }

  get moreToShow() {
    return this.messageData.length > this.currentMessages.length;
  }

  private fillCurrentMessages(index: number) {
    const arr = [...this.messageData];
    if (arr.length - index <= 0 || arr.length - index - 5 <= 0) {
      return this.messageData;
    }
    return arr.splice(arr.length - index - 5, arr.length - 1);
  }

  private compare(a: GetMessagesResponseItem, b: GetMessagesResponseItem) {
    const newer = moment(a.createdAt).isAfter(b.createdAt);
    const res = newer ? +1 : newer === false ? -1 : 0;

    return res !== 0 ? res : a.createdAt > b.createdAt ? +1 : a.createdAt < b.createdAt ? -1 : 0;
  }

  private get messageRequest(): Observable<GetMessagesResponseItem[]> {
    return this.queryService.getMessages(this.clientService.consultationId);
  }

  private get customerRequest(): Observable<Profile.GetProfileResponse> {
    return this.queryService.getProfile(this.clientService.consultationId, false);
  }

  get useMargin(): boolean {
    return this.contextService.isFocusMode || !this.contextService.isManagedFooter;
  }
}
